import styles from './search.module.scss';
import { useTranslation } from 'react-i18next';
import {  ModulesContext } from '@flexo/providers';
import { useContext } from 'react';
import {
  CommunitiesAutocomplete,
  CommunityAutocomplete,
  MemberAutocomplete,
  SitesAutocomplete,
} from './search.utils';
import { useLocation } from 'react-router-dom';


const ENV = (import.meta as any).env;

/* eslint-disable-next-line */
function getSearchComponent(props: any) {
  switch (props?.context) {
    case 'communities':
      return <CommunitiesAutocomplete />;
      break;
    case 'community':
    case 'community-energy':
    case 'community-economics':
      return <CommunityAutocomplete />;
      break;
    case 'site-energy':
    case 'site-economics':
    case 'site':
      return <MemberAutocomplete />;
      break;
    case 'member':
    case 'member-energy':
    case 'member-economics':
      return <MemberAutocomplete />;
      break;
    case 'household':
    case '':

      if (props?.context === '' && ENV.VITE_APP_NAME !== 'admin') {
        return <SitesAutocomplete />;
      } else if (props?.context === 'household') {
        return <SitesAutocomplete />;
      }
      return <></>;
      
    default:
      return <></>;
      break;
  }
}

export function Search() {
  const location = useLocation();
  const pathSegment = location.pathname.split('/')[1]; 

  return (
    <div className={styles.SearchWrapper}>
      <div className={styles.Search}>
        {getSearchComponent({
          context: pathSegment,
        })}
      </div>
    </div>
  );
}

export default Search;
