


import { useTranslation } from "react-i18next"
import styles from './../alert.module.scss'
import { IconWrapper } from "@flexo/general";
import { useContext } from "react";
import { AlertContext, AlertTypes, ModulesContext, ModulesTypes } from "@flexo/providers";
import { useDispatch } from "react-redux";
import { MODULES } from "@flexo/reducers";

export default function AlertWrongParams() {

  const dispatch = useDispatch();
  const { setAlertStore } = useContext( AlertContext)
  const { t } = useTranslation();
  const alertContent = t('alerts.wrong_params', { returnObjects: true }) as any;

  function navigateToPAsswordReset() {

    setAlertStore({ type: AlertTypes.ResetAlert })

    // setModulesStore({
    //   type: ModulesTypes.ActivateForgotPasswordModule,
    // })

    dispatch(MODULES.ActivateForgotPasswordModule())

  }

  return (
    <div id="alert-wrong-params" className={ styles.Alert__Wrong__Params}>
      <div className={styles.Alert__Title} >
      <div>
            <IconWrapper iconName="alert" />
            {alertContent.title}
          </div>
      </div>
      <div className={styles.Alert__Content} >
        {alertContent.description} 
      </div>
    </div>
  )
}