import {  formatDate, IconWrapper } from '@flexo/general'
import styles from './right-drawer-content.module.scss'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RIGHT_DRAWER } from '@flexo/reducers';
import { formatWeek, formatDay, formatMonth, formatYear, checkItIsToday } from '../../hive-calendar/helpers'

export default function SiteTotalEstimatedBenefitsDrawerContent() {

  const { t } = useTranslation();
  const dispatch = useDispatch()
  const metadata = useSelector((state: any) => state.metadata);
  const kpi = useSelector((state: any) => state.kpi);
  const calendar = useSelector((state: any) => state.calendar);

  const siteData = kpi?.site?.['1day']?.[0]?.kpi || [];
  const { hiveCalendar: { selectedDate, selectedTimeSet } } = calendar;

  function getKpi(kpiName: string) {
    const kpi = siteData.find((kpi) => kpi.name === kpiName) || null;
    if (!kpi) return '';
    return `${kpi.unit} ${kpi.value}`;
  }



  function closeDrawer() {
    dispatch( RIGHT_DRAWER.HideDrawer() )
  }

  const getDate = () => {
    
    let result = '';
    switch (selectedTimeSet) {
      case 'day':
        if (
          checkItIsToday(selectedDate)
        )
          result = t('widgets.calendar.today');
        else result = formatDay(selectedDate);
        break;

      case 'week':
        result = selectedDate[0]
          ? `${formatWeek(selectedDate)}`
          : 'Select Week';
        break;

      case 'month':
        result = formatMonth(selectedDate);
        break;

      case 'year':
        result = formatYear(selectedDate);
        break;

      default:
        if (
          formatDay(selectedDate) ===
          formatDay([new Date(), new Date()] as any)
        )
          result = t('widgets.calendar.yesterday');
        else result = formatDay(selectedDate);
        break;
    }
    return result;
  };


  function getSite() {
    const _id = metadata?.selectedEntityId;
    const _sites = ((metadata?.entities as any)?.site || [])?.filter((site: any) => site?.siteId === _id);
    return _sites[0]
  }

  return (
    <div id="SiteTotalEstimatedBenefitsDrawerContent" className={styles.RightDrawerContent__Wrapper}>
      <div className={styles.RightDrawerContent__Title}>
        <p className={ styles.RightDrawerContent__Paragraph}>
          { t( 'drawers.siteTotalEstimatedBenefitsDrawerContent.title' ) }
        </p>
        <div onClick={() => closeDrawer()}>
          <IconWrapper iconName="cross"  />
        </div>
      </div>
      <div className={styles.RightDrawerContent__Subtitle}>
        <p className={styles.RightDrawerContent__Paragraph}>
          {(metadata?.selectedEntity as any)?.pod} <label>{ getDate() }</label>
        </p>
      </div>
      <div className={styles.RightDrawerContent__Body}>
        <p className={styles.RightDrawerContent__Body__Value}>
          { getKpi('total_benefits')}
        </p>
        <p className={styles.RightDrawerContent__Body__Description}>
          { t( 'drawers.siteTotalEstimatedBenefitsDrawerContent.description' ) }
        </p>
        <p className={styles.RightDrawerContent__Body__List__Title}>
          { getKpi('revenues') } { t( 'drawers.siteTotalEstimatedBenefitsDrawerContent.list_title' ) }
        </p>
        <ul className={styles.RightDrawerContent__Body__List}>
          <li className={styles.RightDrawerContent__Body__List__Item}>
            <p className={ styles.RightDrawerContent__Paragraph}>{t('drawers.siteTotalEstimatedBenefitsDrawerContent.list_item_1')}</p>
            <p className={styles.RightDrawerContent__Paragraph}>
            { getKpi('charge_reduction') }
            </p>
          </li>
          <li className={styles.RightDrawerContent__Body__List__Item}>
            <p className={ styles.RightDrawerContent__Paragraph}>{t('drawers.siteTotalEstimatedBenefitsDrawerContent.list_item_2')}</p>
            <p className={styles.RightDrawerContent__Paragraph}>
            { getKpi('public_incentive') }
            </p>
          </li>
          {

          (getSite()?.type !== 'consumer' && getSite()?.siteType?.[0]?.siteType !== 'consumer') &&
          <li className={styles.RightDrawerContent__Body__List__Item}>
            <p className={styles.RightDrawerContent__Paragraph}>{t('drawers.siteTotalEstimatedBenefitsDrawerContent.list_item_3')}</p>
            <p className={styles.RightDrawerContent__Paragraph}>
              {getKpi('direct_compensation')}
            </p>
          </li>
          }
        </ul>

        {
        (getKpi('savings') !== '' && getKpi('savings') !== '€ null') &&
        <p className={styles.RightDrawerContent__Body__List__Title} style={{ marginTop: '20px' }}>
          {getKpi('savings')} {t('drawers.siteTotalEstimatedBenefitsDrawerContent.savings_title')}
        </p>
      }
      </div>
    </div>
  )

}