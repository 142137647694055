/*eslint no-sequences: "off"*/
/*eslint no-empty-pattern: "off"*/

import { createSlice } from "@reduxjs/toolkit";
import { ILeftDrawer } from './interfaces';
import { initState } from "./init.state";

export const LeftDrawerSLice = createSlice({
  name: "LeftDrawer",
  initialState: initState,
  reducers: {
    SetDrawer: (state: ILeftDrawer, action: any) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    ResetDrawer: () => {
      return initState
    },
    HideDrawer: (state: ILeftDrawer, action: any) => {
      return initState
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  SetDrawer,
  ResetDrawer,
  HideDrawer,
} = LeftDrawerSLice.actions;

export default LeftDrawerSLice.reducer;
