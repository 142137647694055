/*eslint no-sequences: "off"*/
/*eslint no-empty-pattern: "off"*/

import { createSlice } from '@reduxjs/toolkit';
import { Ikpi } from './interfaces';
import { initState } from './init.state';

import { SetKpiDataCommunityAsync } from './async-thunks';

export const KPIslice = createSlice({
  name: 'kpi',
  initialState: initState,
  reducers: {
    SetKpiDataCommunity: (state: Ikpi, action: any) => {
      return {
        ...state,
        community: action.payload,
      };
    },
    SetKpiDataCommunityRanking: (state: Ikpi, action: any) => {
      return {
        ...state,
        communityRanking: {
          ...(state?.communityRanking || {}),
          ...action.payload,
        },
      };
    },
    SetKpiDataMemberRanking: (state: Ikpi, action: any) => {
      return {
        ...state,
        memberRanking: {
          ...(state?.memberRanking || {}),
          ...action.payload,
        },
      };
    },
    SetKpiDataSite: (state: Ikpi, action: any) => {
      return {
        ...state,
        site: action.payload,
        isLoading: false,
      };
    },
    SetKpiDataMember: (state: Ikpi, action: any) => {
      return {
        ...state,
        member: action.payload,
      };
    },
    ResetKpiApiStore: () => {
      return initState;
    },
    SetKpiDataCustom: (state: Ikpi, action: any) => {
      // Aggiungi la logica per questa funzione personalizzata, se necessario
      return {
        ...state,
        customData: action.payload, // Esempio, sostituire con il tuo campo reale
      };
    },
    SetLoading: (state: Ikpi, action: any) => {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(SetKpiDataCommunityAsync.pending, (_state, _action) => ({
      ..._state,
      SetKpiDataCommunityAsyncStatus: 'PENDING',
    }));
    builder.addCase(SetKpiDataCommunityAsync.rejected, (_state, _action) => ({
      ..._state,
      SetKpiDataCommunityAsyncStatus: 'ERROR',
    }));
    builder.addCase(SetKpiDataCommunityAsync.fulfilled, (_state, action) => ({
      ..._state,
      community: action.payload,
      SetKpiDataCommunityAsyncStatus: 'SUCCESS',
      isLoading: false,
    }));
  },
});

// Action creators are generated for each case reducer function
export const {
  SetKpiDataSite,
  SetKpiDataCommunity,
  SetKpiDataCommunityRanking,
  SetKpiDataMemberRanking,
  SetKpiDataCustom,
  ResetKpiApiStore,
  SetKpiDataMember,
  SetLoading,
} = KPIslice.actions;

export { SetKpiDataCommunityAsync };

export default KPIslice.reducer;
