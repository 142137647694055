import { useContext, useEffect } from 'react';


import {  AlertContext, AlertTypes } from '@flexo/providers';
import { FirebaseService, httpService } from '@flexo/general';
import { useDispatch, useSelector } from 'react-redux';
import { METADATA, USER } from '@flexo/reducers';
import { useLocation, useParams } from 'react-router-dom';
;

const ENV = (import.meta as any).env;
const firebaseService = new FirebaseService();

export const MembersResolver = () => {
  
  const dispatch = useDispatch();

  const location = useLocation();
  const pathSegment = location.pathname.split('/')[1]; 
  const params = useParams()
  const metadata = useSelector((state: any) => state.metadata);

  const {setAlertStore} = useContext(AlertContext);

  async function init() {


    const idToken = await firebaseService.getIdToken();


    if (!idToken) {
      setAlertStore({
        type: AlertTypes.SetAlert,
        payload: {
          value: {
            type: 'ALERT_SESSION_EXPIRED'
          },
        },
      })

      // setUserStore({
      //   type: UserTypes.SetUserLogOut,
      //   payload: {
      //     value: true,
      //   }
      // })

      dispatch(USER.SetLogout())

      return;
    }

    const COMMUNITY_ID = pathSegment === 'community' ? params.id : null;

    if (!COMMUNITY_ID) {
      return;
    }

    const [members] = await Promise.all([
      httpService
        .get({
          url: `${ENV.VITE_APP_BASE_URL}/hive/api/catalog/v1_3/community/${COMMUNITY_ID}/members`,
          // /hive/api/catalog/v1_3/community/{communityId}/members
          idToken,
        })
        .then((res: any) => {
          if (res.status === 200) {
            return res.data;
          } else {
            // console.warn({
            //   status: res.status,
            //   message: res.statusText,
            // });
            return null;
          }
        })
        .catch((err: any) => {
          // console.warn(err);

          setAlertStore({
            type: AlertTypes.SetAlert,
            payload: {
              value: {
                type: 'ALERT_UNEXPECTED_ERROR'
              },
            }
          })

          return null;
        }),
    ]);

    try {

      dispatch(METADATA.SetMembers(members))

      dispatch(METADATA.SetFilteredMembers(members))
      
      
    } catch (err: any) {
      // console.warn(err);
    }



    // try {

      
    // globalSetFiltersStore({
    //   type: FiltersStoreTypes.SetFilteredMembers,
    //   payload: {
    //     value: members
    //   }
    // })
    // } catch (err: any) {
    //   console.warn(err);
    // }

  }

  useEffect(() => {
      init();
  }, [params.id]);



  return <></>
};
