import { useTranslation } from 'react-i18next';
import styles from './../alert.module.scss';
import { IconWrapper } from '@flexo/general';
import { useContext } from 'react';
import {
  AlertContext,
  AlertTypes,
  ModulesContext,
  ModulesTypes,
} from '@flexo/providers';

import { MODULES } from '@flexo/reducers';
import { useDispatch } from 'react-redux';

export default function AlertWrongLogin() {

  const dispatch = useDispatch();
  const { setAlertStore } = useContext(AlertContext);
  const { t } = useTranslation();
  const alertContent = t('alerts.wrong_login', { returnObjects: true }) as any;

  function navigateToPAsswordReset() {
    setAlertStore({ type: AlertTypes.ResetAlert });

    // setModulesStore({
    //   type: ModulesTypes.ActivateForgotPasswordModule,
    // });

    dispatch(MODULES.ActivateForgotPasswordModule());

  }

  return (
    <div id="alert-wrong-size" className={styles.Alert__Wrong__Login}>

      <div className={styles.Alert__Title}>
        <div>
          <IconWrapper iconName="alert" />
          {alertContent.title}
        </div>
        <div
          onClick={() =>
            setAlertStore({
              type: AlertTypes.ResetAlert,
              payload: null as any,
            })
          }
        >
          <IconWrapper
            className={styles.Alert__Title__Close}
            iconName="cross"

          />
        </div>

      </div>
      <div className={styles.Alert__Content}>
        {alertContent.description}{' '}
        <span
          className={styles.Alert__Content__Link}
          onClick={() => navigateToPAsswordReset()}
        >
          {alertContent.link}
        </span>
      </div>
    </div>
  );
}
