import React, { useContext, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import {  AlertContext, AlertTypes, } from '@flexo/providers';
import { FirebaseService, httpService } from '@flexo/general';import { useDispatch } from 'react-redux';
import { METADATA, USER } from '@flexo/reducers';
;

const ENV = (import.meta as any).env;

const firebaseService = new FirebaseService();


export const CommunitiesResolver = React.memo((props: any) => {

  const dispatch = useDispatch();
  const { t } = useTranslation()

  const { setAlertStore } = useContext(AlertContext);

  async function init (){
    const idToken = await firebaseService.getIdToken();

    if (!idToken) {
      setAlertStore({
        type: AlertTypes.SetAlert,
        payload: {
          value: {
            type: 'ALERT_SESSION_EXPIRED'
          },
        },
      })

      dispatch( USER.SetLogout())

      return;
    }

    const [communities] = await Promise.all([
      httpService.get({
        url: `${ENV.VITE_APP_BASE_URL}/hive/api/catalog/v1_3/organization/${ENV.VITE_APP_ORG_ID}/communities`,
        idToken,
      })
      .then( ( res: any ) => {
        if ( res.status === 200 ){
          return res.data.communities;
        } else {
                    
          setAlertStore({
            type: AlertTypes.SetAlert,
            payload: {
              value: {
                type: 'ALERT_UNEXPECTED_ERROR'
              },
            }
          })

          return null
        }
      } )
      .catch((err: any) => {

        setAlertStore({
          type: AlertTypes.SetAlert,
          payload: {
            value: {
              type: 'ALERT_UNEXPECTED_ERROR'
            },
          }
        })

        return null;
      } ),
    ])

    dispatch( METADATA.SetCommunities( communities ) as any )

  }


  useEffect(() => {
      init();
  }, []);
  
  return <></>

});