//Sets the selected site in the metadata api store and navigates to the site context
import { useEffect } from 'react';

import { METADATA } from '@flexo/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export const SiteMetaResolver = () => {

  const dispatch = useDispatch();
  const metadata = useSelector((state: any) => state.metadata);
  const params = useParams();

  function setSelectedSite() {

    dispatch(METADATA.SetSelectedEntity({
      selectedEntity: ((metadata?.entities as any)?.site)?.[0],
      id: ((metadata?.entities as any)?.site)?.[0]?.siteId,
      entityType: 'household',
    } as any))
  }
 

    useEffect(() => {
      setSelectedSite();
    }, [metadata?.entities]);

  return <></>;

  

};
