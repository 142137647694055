import { useContext } from 'react';
import { IconWrapper } from '@flexo/general';
import { FooterProps } from '@flexo/molecules';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styles from './footer.module.scss';
import { useTranslation } from 'react-i18next';
import {
  LoadingContext,
  ThemeContext,
  ModulesContext,
} from '@flexo/providers';
import BarLoader from 'react-spinners/BarLoader';

import { useSelector } from 'react-redux';
import { navigateToCommunity, navigateToSite } from '@flexo/atoms';

export function Footer({
  sections,
  showLoadingBar,
  shadow,
  roundCorners,
}: FooterProps) {
  
  const { Footer__Label, Footer__Label__Active } = styles;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const pathSegment = location.pathname.split('/')[1]; 

  const metadata = useSelector((state: any) => state.metadata);
  const { loadingStore } = useContext(LoadingContext);
  const { ThemeStore } = useContext(ThemeContext);

  const { modulesStore } = useContext(ModulesContext);

  const activeModule = modulesStore.modules.find(
    (module) =>
      module.visible &&
      (module.name === 'household' || module.name === 'community')
  );
  const isActiveSection = activeModule ? activeModule.name : null;

  function getCommunitySite() {
    let communitySite = (metadata?.selectedEntity?.sites || [])?.[0] || null;

    if (!communitySite) {
      communitySite = (metadata?.entities as any)?.site?.find(
        (site: any) => site.communityId === params.id
      )?.siteId || null;
    }

    if (!communitySite) {
      communitySite = (metadata?.entities as any)?.site?.[0]?.siteId || null;
    }

    return communitySite

  }


  // Handle section change
  const handleSectionChange = (section: string) => {
    if (section === 'community') {
      navigateToCommunity( 'community', pathSegment === 'community' ? params.id : (metadata.entities as any).community[0].communityId )
    } else {
      navigateToSite( 'household', getCommunitySite() )
    }
  };

  return (
    <div
      className={`${styles.Footer} sfPro ${shadow ? styles.shadow : ''} 
      ${roundCorners ? styles.roundCorners : ''}`}
    >
      {/* Display loading bar if necessary */}
      {loadingStore.loading.visible && showLoadingBar && (
        <BarLoader
          color={`${ThemeStore.colors.primary}80`}
          style={{ left: 0, width: '100%', position: 'absolute', top: 0 }}
        />
      )}

      {/* Map through sections and display icons */}
      {sections?.map((section) => (
        <div
          key={section}
          className={`${Footer__Label} ${
            isActiveSection === section ? Footer__Label__Active : ''
          }`}
          onClick={() => handleSectionChange(section)}
        >
          <IconWrapper
            iconName={`${section}${
              isActiveSection === section ? '_active' : ''
            }`}
          />
          <div
            className={isActiveSection === section ? 'color-primary-500' : ''}
          >
            {t(`widgets.footer.sections.${section}`)}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Footer;
