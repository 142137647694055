import { createAsyncThunk } from "@reduxjs/toolkit";
import storage from "../database";
import { initState } from "./init.state";

export const SetUserAsync = createAsyncThunk(
  "user/SetUserAsync",
  async function ( data: any) {
    await storage.set('user', data);
    return {
      ...data,
    };
  })

export const ResetUserAsync = createAsyncThunk(
  "user/ResetUserAsync",
  async function () {
    await storage.remove( 'user' );
    return initState;
  })