import { useDispatch, useSelector } from "react-redux";
import * as luxon from 'luxon';


import styles from './hive-calendar.module.scss';
import { CALENDAR } from "@flexo/reducers";
import { useTranslation } from "react-i18next";
import { getStartDate } from "./helpers";

export function HiveCalendarContentMonth( props: any ) {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const monthNames: any = t('calendar.monthNamesShort', { returnObjects: true} )

  const { setShowCalendar } = props;

  const calendar = useSelector((state: any) => state.calendar);
  const hiveCalendar = calendar.hiveCalendar;

  const {
    calendarDate,
    selectedDate,
    calendarTimeSet,
    selectedTimeSet,
    calendarNavigationActive,
    navigationStart,
  } = hiveCalendar;

  const _selectedDate = calendarDate || selectedDate;
  const timeSet = calendarTimeSet || selectedTimeSet;
  const startDate = getStartDate()
  
  function checkMonthRange(index: number) {


    if (calendarNavigationActive === true) { return false; }
    if (selectedTimeSet !== 'month') { return false; }
    
    const monthYear = _selectedDate[0].getFullYear();

    const checkedMonthDate = new Date(monthYear, index, 1);

    return timeSet === 'month' && (luxon.DateTime.fromJSDate(checkedMonthDate).startOf('month').toMillis() === luxon.DateTime.fromJSDate(selectedDate[0]).startOf('month').toMillis());

  }

  function checkMonthAvailable(index: number) {
    const monthYear = _selectedDate[0].getFullYear();
    const checkedMonthDate = new Date(monthYear, index, 1);
    const checkedMonthDateMillis = luxon.DateTime.fromJSDate(checkedMonthDate).startOf('month').toMillis();

    if (
      checkedMonthDateMillis <= luxon.DateTime.fromJSDate(new Date()).startOf('month').toMillis() &&
      ( startDate ? luxon.DateTime.fromJSDate(new Date(startDate)).startOf('month').toMillis() <= checkedMonthDateMillis  : true )
    ) {
      return true;
    }

    return false;

  }

  function setMonth(monthIndex: number) {

    const _today = luxon.DateTime.fromJSDate(new Date()).startOf('month');
    const monthStart = luxon.DateTime.fromJSDate(new Date(_selectedDate[0].getFullYear(), monthIndex, 1)).startOf('month');
    const monthEnd = luxon.DateTime.fromJSDate(new Date(_selectedDate[0].getFullYear(), monthIndex, 1)).plus({ months: 1 }).startOf('month');
    
    if (monthStart.toMillis() > _today.toMillis()) { return }

    if (calendarNavigationActive) {

      
      if (navigationStart === 'month') {

        dispatch(CALENDAR.setHiveDate({
          date: [monthStart.toJSDate(), monthEnd.toJSDate()],
          timeSet: 'month',
        } as any))
        setShowCalendar(false);
      }


      dispatch(CALENDAR.setHiveCalendarNavigationInactive());
      dispatch( CALENDAR.setHiveCalendarDate([monthStart.toJSDate(), monthEnd.toJSDate()] as any))
      dispatch( CALENDAR.setHiveCalendarTimeSet(navigationStart || timeSet as any))

    } else {

      dispatch(CALENDAR.setHiveDate({
        date: [monthStart.toJSDate(), monthEnd.toJSDate()],
        timeSet: 'month',
      } as any))
      setShowCalendar(false);
    }

  }

  
  return <div className={styles.HiveCalendar__Calendar__Content__Month}> 
    
    {
      Array.from({ length: 3 })
        .map((_, index) => {
          return <div key={`HiveCalendar__Calendar__Content__Month__Line__${index}`}
            className={`
              ${styles.HiveCalendar__Calendar__Content__Month__Line}
            `}
          >
            {
              Array.from({ length: 4 })
                .map((_, i) => {
                  return <div key={`HiveCalendar__Calendar__Content__Month_Line__Month__${index}__${i}`}
                    className={`
                      ${styles.HiveCalendar__Calendar__Content__Month__Line__Month} 
                      ${checkMonthRange((4 * index) + i) ? styles.HiveCalendar__Calendar__Content__Month__Line__Month__Selected : ''}
                      ${ checkMonthAvailable( (4 * index) + i ) ? '' : styles.HiveCalendar__Calendar__Content__Month__Line__Month__Disabled }
                  `}
                    onClick={ () => checkMonthAvailable((4 * index) + i) ? setMonth( (4 * index) + i ) : null}
                  >
                    { monthNames[(4 * index) + i] }
                  </div>
                })
            }
          </div>
        })
    }

  </div>

}