import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AreaClosed, Line, Bar } from '@visx/shape';
import { curveMonotoneX } from '@visx/curve';
import { GridRows, GridColumns } from '@visx/grid';
import { scaleTime, scaleLinear, scaleOrdinal } from '@visx/scale';
import { withTooltip, defaultStyles } from '@visx/tooltip';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';
import { LinearGradient } from '@visx/gradient';
import { max, extent, bisector } from 'd3-array';
import { AxisBottom, AxisLeft } from '@visx/axis';
import styles from '../stackbar-graph/stackbar-graph.module.scss';
import { GraphHeader } from '@flexo/atoms';
import {
  CalendarContext,
  CommunityViewContext,
  SiteViewContext,
  ThemeContext,
} from '@flexo/providers';
import { formatDate } from '../helpers';
import { IconWrapper } from '@flexo/general';
import './area-chart.module.scss';
import { useTranslation } from 'react-i18next';
import { localPoint } from '@visx/event';
import { Haptics } from '@capacitor/haptics';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';

interface IGraphData {
  date: string;
  'widgets.areaChart-self-consumption.from_network': string;
  'widgets.areaChart-self-consumption.to_network': string;
  'widgets.areaChart-self-consumption.self_consumption': string;
}

type TooltipData = IGraphData;
const ENV = (import.meta as any).env;

export const background = '#3b6978';
export const background2 = '#204051';
export const accentColor = '#edffea';
export const accentColorDark = '#75daad';

// util

// accessors
const getDate = (d: IGraphData) => new Date(d.date);
const getFromNetworkValue = (d: IGraphData): number =>
  Number(d['widgets.areaChart-self-consumption.from_network']);
const getToNetworkValue = (d: IGraphData): number =>
  Number(d['widgets.areaChart-self-consumption.to_network']);
const getSharedValue = (d: IGraphData): number =>
  Number(d['widgets.areaChart-self-consumption.self_consumption']);
const bisectDate = bisector<IGraphData, Date>((d) => new Date(d.date)).left;
export type AreaProps = {
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  colorVariables: any;
  title: string | null;
  buttons: string[] | null;
  graphData: any;
  dataInTimeSpan: boolean;
};

const colors = { primary: '#F9DA74', secondary: '#FBE9AE' };

export const AreaChart = withTooltip<AreaProps, TooltipData>(
  ({
    height = 500,
    margin = { top: 10, right: 10, bottom: 50, left: 60 },
    showTooltip,
    hideTooltip,
    tooltipData,
    tooltipTop = 0,
    tooltipLeft = 0,
    colorVariables = colors,
    graphData,
    title = null,
    buttons = null,
    dataInTimeSpan = true,
  }: AreaProps & WithTooltipProvidedProps<TooltipData>) => {

    const { t } = useTranslation();
    const calendar = useSelector((state: any) => state.calendar);


    const [toggle, setToggle]: any = useState(false);
    const [selectedBar, setSelectedBar] = useState<string | null>(null);
    const [allowToggle, setAllowToggle]: any = useState(false);
    const [visibleButtons, setVisibleButtons]: any = useState([
      true,
      true,
      true,
    ]);
    const [isVibrating, setIsVibrating] = useState(false);
    
    const { currentlySelectedDateType, hiveCalendar: { selectedDate, selectedTimeSet } } = calendar;
    const [chartWidth, setChartWidth] = useState(window.innerWidth - 24);
    const { siteViewStore } = useContext(SiteViewContext);

    const definedFunction = (d, i) =>
      d['widgets.areaChart-self-consumption.from_network'] !== null &&
      d['widgets.areaChart-self-consumption.to_network'] !== null &&
      d['widgets.areaChart-self-consumption.self_consumption'] !== null;

    let dataShape = graphData;

    if (selectedTimeSet === 'week') {
      dataShape = graphData.reduce(
        (prev, next) => [
          ...prev,
          next,
          {
            ...next,
            date: next.date.replace('00:00:00', '01:01:01'),
          },
        ],
        []
      );
    } else if (selectedTimeSet === 'month') {
      const _shapedArray: Array<any> = [];
      graphData.forEach((item, _i) => {
        if (!definedFunction(item, _i)) {
          _shapedArray.push({
            ...graphData[_i - 1],
            date: graphData[_i - 1].date.replace('00:00:00', '23:59:59'),
          });
        }

        _shapedArray.push(item);
      });

      dataShape = _shapedArray;
    }

    const [data, setData]: any = useState(dataShape);

    const ref: any = useRef(null);

    const { ThemeStore } = useContext(ThemeContext);
    const keys: any = () =>
      graphData.length > 0
        ? (Object.keys(graphData).filter(
            (d) => d !== 'date' && d !== 'power_peak_value'
          ) as [])
        : [];

    // bounds
    const innerWidth = chartWidth - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    // scales
    const colorScale = scaleOrdinal({
      domain: keys(),
      range: [
        ThemeStore.colors.consumption,
        ThemeStore.colors.production,
        ThemeStore.colors['self-consumption'],
      ],
    });

    const maxValue = Math.max(
      max(data, getFromNetworkValue) || 0,
      max(data, getToNetworkValue) || 0,
      max(data, getSharedValue) || 0
    );

    const dateScale = scaleTime({
      range: [margin.left, innerWidth + margin.left],
      domain: extent(data, getDate) as [Date, Date],
    });

    const fromGridScale = scaleLinear({
      range: [innerHeight + margin.top, margin.top],
      domain: [0, max(data, getFromNetworkValue) || 0],
      nice: true,
    });

    const leftAxisScale = scaleLinear({
      range: [innerHeight + margin.top, margin.top + 30],
      domain: [0, maxValue || 0],
      nice: true,
    });

    const handleTooltip = useCallback(
      (
        event:
          | React.TouchEvent<SVGRectElement>
          | React.MouseEvent<SVGRectElement>
      ) => {
        event.stopPropagation();

        const { x } = localPoint(event) || { x: 0 };
        const x0 = dateScale.invert(x);
        const index = bisectDate(data, x0, 1);
        const lastNullIndex = findFirstNullFollowedByNullIndex(data);

        if (lastNullIndex && index > lastNullIndex) {
          hideTooltip();
          return;
        }

        const d0 = data[index - 1];
        const d1 = data[index];

        let interpolatedData: any;

        if (d0 && d1) {
          // Convert string dates to Date objects
          const d0Date = new Date(d0.date);
          const d1Date = new Date(d1.date);

          // Calculate how far the cursor is between d0 and d1 (as a percentage)
          const t =
            (x0.getTime() - d0Date.getTime()) /
            (d1Date.getTime() - d0Date.getTime());

          // Interpolate date
          const interpolatedDate = new Date(
            d0Date.getTime() + t * (d1Date.getTime() - d0Date.getTime())
          );

          // Interpolate cumulative data
          const interpolateValue = (key: string) =>
            d0[key] + t * (d1[key] - d0[key]);

          interpolatedData = {
            date: interpolatedDate.toISOString(),
            'widgets.areaChart-self-consumption.self_consumption':
              interpolateValue(
                'widgets.areaChart-self-consumption.self_consumption'
              ),
            'widgets.areaChart-self-consumption.from_network': interpolateValue(
              'widgets.areaChart-self-consumption.from_network'
            ),
            'widgets.areaChart-self-consumption.to_network': interpolateValue(
              'widgets.areaChart-self-consumption.to_network'
            ),
          };
        } else {
          interpolatedData = d0 || d1;
        }

        const lastValidIndexDate =
          lastNullIndex && DateTime.fromISO(data[lastNullIndex - 1].date);

        if (lastValidIndexDate) {
          if (selectedTimeSet === 'year') {
            if (
              lastValidIndexDate.plus({ days: 2 }) <
              DateTime.fromISO(interpolatedData.date)
            ) {
              hideTooltip();
              return;
            }
          }
          //TODO lastValidIndexDate set time to 01:00:00 and compare it with interpolatedData.date at 2:00:00
          if (selectedTimeSet === 'week') {
            // Set the time of lastValidIndexDate to 01:00:00
            const adjustedLastValidIndexDate = lastValidIndexDate;

            // Set the time of interpolatedData.date to 02:00:00
            const adjustedInterpolatedDate = DateTime.fromISO(
              interpolatedData.date
            );

            // Compare the adjusted dates
            if (adjustedLastValidIndexDate <= adjustedInterpolatedDate) {
              hideTooltip();
              return;
            }
          }
        }
        showTooltip({
          tooltipData: interpolatedData,
          tooltipLeft: Math.max(
            margin.left,
            Math.min(x, innerWidth + margin.left + 20)
          ),
          tooltipTop: Math.max(margin.top, Math.min(innerHeight + margin.top)),
        });
      },
      [showTooltip, dateScale, data, selectedTimeSet]
    );

    function toggleData(key: string, index: number) {
      setToggle(true);

      if (selectedBar !== null) {
        setSelectedBar(null);
        setVisibleButtons([true, true, true]); // Resetting to all buttons visible
        setData(graphData);
        setTimeout(() => setToggle(false), 500);
        return;
      }

      const newButtonState = !visibleButtons[index]; // The state the button will have if toggled

      // Check if trying to disable the button
      if (!newButtonState) {
        // Check how many buttons are currently enabled.
        // If only 1 button (including current) is enabled, then prevent disabling it.
        if (
          visibleButtons.filter((value: boolean) => value === true).length <= 1
        ) {
          setTimeout(() => setToggle(false), 500);
          return;
        }
      }

      let filteredData = dataShape;

      if (newButtonState === false) {
        // Same as if (!newButtonState)
        filteredData = data.map((timestamp: any) => ({
          ...timestamp,
          [key]: 0,
        }));
      } else {
        filteredData = [
          ...data.map((datum: any, datum_i: number) => ({
            ...datum,
            [key]: graphData[datum_i][key],
          })),
        ];
      }

      setVisibleButtons((prev: Array<boolean>) =>
        prev.map((value: boolean, _i: number) =>
          _i === index ? newButtonState : value
        )
      );

      setData(filteredData);
      setTimeout(() => setToggle(false), 500);
    }

    useEffect(() => {
      const updateWidth = () => {
        if (ref.current) {
          const newWidth = ref.current.offsetWidth;
          setChartWidth(newWidth);
        }
      };

      // Attach event listener
      window.addEventListener('resize', updateWidth);

      // Cleanup
      return () => {
        window.removeEventListener('resize', updateWidth);
      };
    }, [ref]);

    if (chartWidth < 10) return null;

    const formatTooltipData = () => {
      if (!tooltipData) return null;
      const selected = data.find((d: any) => {
        const dateFromData = DateTime.fromISO(d.date);
        const dateFromTooltip = DateTime.fromISO(tooltipData.date);
        switch (currentlySelectedDateType) {
          case 'day':
            return (
              dateFromData.hasSame(dateFromTooltip, 'day') &&
              dateFromData.hasSame(dateFromTooltip, 'hour')
            );
          case 'month':
          case 'week':
            return (
              dateFromData.hasSame(dateFromTooltip, 'month') &&
              dateFromData.hasSame(dateFromTooltip, 'day')
            );
          case 'year':
            return (
              dateFromData.hasSame(dateFromTooltip, 'year') &&
              dateFromData.hasSame(dateFromTooltip, 'month')
            );
          default:
            return dateFromData.hasSame(dateFromTooltip, 'day');
        }
      });
      const formattedData = {
        date: selected?.date,
        'widgets.areaChart-self-consumption.from_network':
          selected?.['widgets.areaChart-self-consumption.from_network'],
        'widgets.areaChart-self-consumption.to_network':
          selected?.['widgets.areaChart-self-consumption.to_network'],
        'widgets.areaChart-self-consumption.self_consumption':
          selected?.['widgets.areaChart-self-consumption.self_consumption'],
      };
      return formattedData;
    };

    const allValuesAreZero = () => {
      return data.every((item) => {
        // Get all keys except 'date'
        const keys = Object.keys(item).filter((key) => key !== 'date');

        // Check if every non-date value is 0
        return keys.every((key) => Number(item[key]) === 0);
      });
    };

    function findFirstNullFollowedByNullIndex(data) {
      for (let i = 0; i < data.length; i++) {
        const keys = Object.keys(data[i]).filter((key) => key !== 'date');
        const isCurrentNull = keys.every((key) => data[i][key] === null);
        const isFollowingAllNull = data
          .slice(i + 1)
          .every((item) => keys.every((key) => item[key] === null));

        if (isCurrentNull && isFollowingAllNull) {
          return i;
        }
      }
      return undefined;
    }
    return (
      <div
        className={styles.AreaChart}
        style={{ position: 'relative', height: '400px' }}
        ref={ref}
      >
        <GraphHeader
          visibleButtons={visibleButtons}
          colorScale={colorScale}
          buttons={[
            'widgets.areaChart-self-consumption.from_network',
            'widgets.areaChart-self-consumption.to_network',
            'widgets.areaChart-self-consumption.self_consumption',
          ]}
          toggleData={toggleData}
          title={'widgets.areaChart.title'}
          unit={'kWh'}
          selectedBar={formatTooltipData()}
        />
        {siteViewStore.loading && (
          <div
            style={{ width: chartWidth - 24, height }}
            className={styles.Data__Unavailable}
          >
            <IconWrapper iconName="product_icons" />
            <text
              x="50%"
              y="50%"
              alignmentBaseline="middle"
              textAnchor="middle"
              className="detail"
              style={{ marginTop: '1em' }}
            >
              {t('loading')}
            </text>{' '}
          </div>
        )}
        {!siteViewStore.loading &&
        (data.length === 0 || dataInTimeSpan === false) ? (
          <div
            style={{ width: chartWidth - 24, height }}
            className={styles.Data__Unavailable}
          >
            <IconWrapper iconName="product_icons" />
            <text
              x="50%"
              y="50%"
              alignmentBaseline="middle"
              textAnchor="middle"
              className="detail"
              style={{ marginTop: '1em' }}
            >
              {t('errors.no_data')}
            </text>{' '}
          </div>
        ) : (
          !siteViewStore.loading && (
            <svg
              width={chartWidth}
              height={height}
              style={{
                marginLeft: '-10',
                paddingTop: '.5em',
              }}
            >
              <rect
                x={0}
                y={0}
                width={chartWidth}
                height={height}
                fill="transparent"
                rx={14}
              />
              <AxisLeft
                top={margin.top - 12}
                scale={leftAxisScale}
                left={margin.left}
                numTicks={3}
                hideTicks={true}
                strokeWidth={0}
                label={'kWh'}
                labelClassName={`${styles['rotate']} ${styles['rotate__360']}`}
                tickLabelProps={() => ({
                  fontSize: 12,
                  textAnchor: 'end',
                  fill: '#838B93',
                  fontWeight: 300,
                })}
                labelProps={{
                  x: -30,
                  y: 10,
                  fill: '#838B93',
                  fontSize: 12,
                  fontWeight: 600,
                }}
                tickFormat={(val: any) =>
                  new Intl.NumberFormat(ENV?.VITE_APP_LOCALE || 'it-CH', {
                    ...(val > 99
                      ? { minimumFractionDigits: 0, maximumFractionDigits: 0 }
                      : { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                  }).format(val)
                }
              />
              {!allValuesAreZero() && (
                <AreaClosed<IGraphData>
                  data={data}
                  x={(d) => dateScale(getDate(d))}
                  y={(d) =>
                    leftAxisScale(
                      Number(
                        d['widgets.areaChart-self-consumption.from_network']
                      )
                    )
                  }
                  fill={
                    tooltipData
                      ? ThemeStore.colors.greyscale + 60
                      : ThemeStore.colors.consumption + 50
                  }
                  curve={curveMonotoneX}
                  defined={definedFunction}
                  yScale={leftAxisScale}
                />
              )}
              {!allValuesAreZero() && (
                <AreaClosed<IGraphData>
                  data={data}
                  curve={curveMonotoneX}
                  x={(d) => dateScale(getDate(d))}
                  y={(d) =>
                    leftAxisScale(
                      Number(d['widgets.areaChart-self-consumption.to_network'])
                    )
                  }
                  fill={
                    tooltipData
                      ? ThemeStore.colors.greyscale + 40
                      : ThemeStore.colors.production + 50
                  }
                  yScale={leftAxisScale}
                  defined={definedFunction}
                />
              )}
              {!allValuesAreZero() && (
                <AreaClosed<IGraphData>
                  data={data}
                  x={(d) => dateScale(getDate(d))}
                  y={(d) =>
                    leftAxisScale(
                      Number(
                        d['widgets.areaChart-self-consumption.self_consumption']
                      )
                    )
                  }
                  strokeWidth={0}
                  fill={
                    tooltipData
                      ? ThemeStore.colors.greyscale + 80
                      : ThemeStore.colors['self-consumption'] + 90
                  }
                  curve={curveMonotoneX}
                  yScale={leftAxisScale}
                  defined={definedFunction}
                />
              )}
              <Bar
                x={margin.left}
                y={margin.top}
                width={innerWidth}
                height={innerHeight}
                fill="transparent"
                rx={14}
                onClick={handleTooltip}
                onTouchStart={(event: any) => (
                  Haptics.vibrate({ duration: 10 }), handleTooltip(event)
                )}
                onTouchMove={(event: any) => handleTooltip(event)}
                onMouseLeave={hideTooltip}
                onTouchEnd={() => (
                  hideTooltip(),
                  setSelectedBar(null),
                  Haptics.vibrate({ duration: 10 })
                )}
              />
              <AxisBottom
                numTicks={data.length < 5 ? data.length : 5}
                left={0}
                top={height - margin.bottom}
                scale={dateScale}
                tickClassName={'color__light-grey'}
                tickFormat={(date: any) =>
                  formatDate(date.toISOString(), currentlySelectedDateType)
                }
                axisClassName="bottom-axis"
                strokeWidth={0}
                hideTicks={true}
                tickLabelProps={() => ({
                  fontSize: 12,
                  textAnchor: 'middle',
                  fill: '#838B93',
                  fontWeight: 300,
                })}
              />

              {tooltipData && (
                <g>
                  <Line
                    from={{ x: tooltipLeft, y: margin.top }}
                    to={{ x: tooltipLeft, y: innerHeight + margin.top }}
                    stroke={'white'}
                    strokeWidth={2}
                    pointerEvents="none"
                  />

                  {Number(
                    tooltipData['widgets.areaChart-self-consumption.to_network']
                  ) !== 0 && (
                    <circle
                      cx={tooltipLeft}
                      r={4}
                      fill={ThemeStore.colors.production}
                      stroke={ThemeStore.colors.production}
                      strokeWidth={2}
                      pointerEvents="none"
                      cy={leftAxisScale(
                        Number(
                          tooltipData[
                            'widgets.areaChart-self-consumption.to_network'
                          ]
                        )
                      )}
                    />
                  )}
                  {Number(
                    tooltipData[
                      'widgets.areaChart-self-consumption.from_network'
                    ]
                  ) !== 0 && (
                    <circle
                      cx={tooltipLeft}
                      r={4}
                      fill={ThemeStore.colors.consumption}
                      stroke={ThemeStore.colors.consumption}
                      strokeWidth={2}
                      pointerEvents="none"
                      cy={leftAxisScale(
                        Number(
                          tooltipData[
                            'widgets.areaChart-self-consumption.from_network'
                          ]
                        )
                      )}
                    />
                  )}

                  {Number(
                    tooltipData[
                      'widgets.areaChart-self-consumption.self_consumption'
                    ]
                  ) !== 0 &&
                    tooltipData[
                      'widgets.areaChart-self-consumption.self_consumption'
                    ] !== null && (
                      <circle
                        cx={tooltipLeft}
                        r={4}
                        fill={ThemeStore.colors['self-consumption']}
                        strokeWidth={2}
                        pointerEvents="none"
                        cy={leftAxisScale(
                          Number(
                            tooltipData[
                              'widgets.areaChart-self-consumption.self_consumption'
                            ]
                          )
                        )}
                      />
                    )}
                </g>
              )}
            </svg>
          )
        )}
      </div>
    );
  }
);
export default AreaChart;
