//save as site: {
// 1day:{}
//1hour:{}
// }
//event driven development
import { useContext, useEffect } from 'react';

import { AlertContext, AlertTypes } from '@flexo/providers';
import {
  FirebaseService,
  formatDate,
  getTimeGranularity,
  getTimeGranularityOverview,
  httpService,
} from '@flexo/general';
import { KPI, USER } from '@flexo/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const ENV = (import.meta as any).env;

const firebaseService = new FirebaseService();

export const KpiSiteResolver = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const metadata = useSelector((state: any) => state.metadata);
  const calendar = useSelector((state: any) => state.calendar);

  const { setAlertStore } = useContext(AlertContext);

  const { hiveCalendar } = calendar;
  const { selectedDate, selectedTimeSet} = hiveCalendar

  async function init() {
    const idToken = await firebaseService.getIdToken();

    if (!idToken) {
      setAlertStore({
        type: AlertTypes.SetAlert,
        payload: {
          value: {
            type: 'ALERT_SESSION_EXPIRED',
          },
        },
      });

      // setUserStore({
      //   type: UserTypes.SetUserLogOut,
      //   payload: {
      //     value: true,
      //   },
      // });

      dispatch(USER.SetLogout());

      return;
    }

    const SITE_ID = metadata?.selectedEntity?.siteId || params?.id || null;

    dispatch(KPI.SetLoading(true as any as any));

    if (!SITE_ID) {
      return;
    }

    const [kpiSiteDataOverview, kpiSiteDataDetail] = await Promise.all([
      httpService
        .get({
          url: `${ENV.VITE_APP_BASE_URL}/hive/api/kpi/v1_2/site/${SITE_ID}`,
          params: {
            dateFrom: formatDate(selectedDate[0]),
            dateTo: formatDate(selectedDate[1]),
            //add null: null to test the error
            timeGranularity: getTimeGranularityOverview(
              selectedTimeSet
            ),
            locale: ENV?.VITE_APP_LOCALE || 'it-CH',
          },
          idToken,
        })
        .then((res: any) => {
          if (res.status === 200) {
            return res.data;
          } else {
            // console.warn({
            //   status: res.status,
            //   message: res.statusText,
            // });
            return null;
          }
        })
        .catch((err: any) => {
          // console.warn(err);

          // setAlertStore({
          //   type: AlertTypes.SetAlert,
          //   payload: {
          //     value: {
          //       type: 'ALERT_UNEXPECTED_ERROR',
          //     },
          //   },
          // });

          return null;
        }),

      httpService
        .get({
          url: `${ENV.VITE_APP_BASE_URL}/hive/api/kpi/v1_2/site/${SITE_ID}`,
          params: {
            dateFrom: formatDate(selectedDate[0]),
            dateTo: formatDate(selectedDate[1]),
            timeGranularity: getTimeGranularity(
              selectedTimeSet,
              null
            ),
            locale: ENV?.VITE_APP_LOCALE || '=it-IT',
          },
          idToken,
        })
        .then((res: any) => {
          if (res.status === 200) {
            return res.data;
          } else {
            // console.warn({
            //   status: res.status,
            //   message: res.statusText,
            // });
            return null;
          }
        })
        .catch((err: any) => {
          // setAlertStore({
          //   type: AlertTypes.SetAlert,
          //   payload: {
          //     value: {
          //       type: 'ALERT_UNEXPECTED_ERROR',
          //     },
          //   },
          // });

          return null;
        }),
    ]);

    dispatch(KPI.SetLoading(false as any as any));

    if (kpiSiteDataOverview && kpiSiteDataDetail) {
      dispatch(
        KPI.SetKpiDataSite({
          '1day': kpiSiteDataOverview,
          '1hour': kpiSiteDataDetail,
        } as any)
      );
    }
  }

  useEffect(() => {
    init();
  }, [params.id, metadata?.selectedEntity, selectedDate, selectedTimeSet]);

  return <></>;
};
