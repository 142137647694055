
import { FirebaseService } from '@flexo/general';

const ENV = (import.meta as any).env;
const firebaseService = new FirebaseService();

export const setUrl = (newUrl) => {
  // Get the current URL
  const currentUrl = new URL(window.location.href);

  // Extract the current parameters from the query string
  const currentParams = new URLSearchParams(currentUrl.search);

  // Extract the new parameters from the newUrl
  const newUrlObject = new URL(newUrl, window.location.origin);
  const newParams = new URLSearchParams(newUrlObject.search);

  // Merge new parameters into current ones (replaces or adds)
  newParams.forEach((value, key) => {
    currentParams.set(key, value); // Replaces or adds new parameters
  });

  // Update both the path and the search params
  currentUrl.pathname = newUrlObject.pathname; // Set the new path
  currentUrl.search = currentParams.toString(); // Update the query string with merged parameters
  // Update the URL without reloading the page
  window.history.pushState({}, '', currentUrl.toString());
};

export function setParams(paramsObj) {
  // Ottieni l'URL corrente
  const url = new URL(window.location as any);

  // Usa URLSearchParams per gestire i parametri
  const params = new URLSearchParams(url.search);

  // Imposta o aggiorna i parametri dall'oggetto fornito
  for (const key in paramsObj) {
    // eslint-disable-next-line no-prototype-builtins
    if (paramsObj.hasOwnProperty(key)) {
      params.set(key, paramsObj[key]);
    }
  }

  // Aggiorna la query string nell'oggetto URL
  url.search = params.toString();

  // Modifica l'URL corrente senza ricaricare la pagina
  // window.history.pushState({}, '', url);
}

export const getParams = (name: string) => {
  const url = new URL(window.location as any);
  return url?.searchParams?.get(name) || undefined;
};

export const getQueryParams = () => {
  const url = new URL(window.location as any);
  const [_root, module = null, id = null] = url.pathname.split('/');
  return [url, _root, module, id];
};

export const doesModuleNeedAuth = (MODULES: any) => {
  const [url, _root, _module = null, id = null] = getQueryParams();

  const searchedModule =
    MODULES.find((module: any) => module.name === _module) || null;

  if (searchedModule === null) return true;
  if (searchedModule?.needsAuth === false) return false;
  if (searchedModule?.needsAuth === true) return true;

  return true;
};

export function debounce<T extends (...args: any[]) => any>(
  func: any,
  wait: number
): (...args: Parameters<T>) => void {
  let timeout: NodeJS.Timeout | null;

  return function (...args: Parameters<T>) {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      func(...args);
    }, wait);
  };
}

// eslint-disable-next-line no-inner-declarations
export function _getQueryParams() {
  const params = new URLSearchParams(window.location.search);
  const queryParams = {};

  for (const [key, value] of params.entries()) {
    queryParams[key] = value;
  }

  return queryParams;
}

try {
  // setParams(getQueryParams());
} catch (e) {
  // console.error(e);
}

