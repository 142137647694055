/*eslint no-sequences: "off"*/
/*eslint no-empty-pattern: "off"*/

import { createSlice } from "@reduxjs/toolkit";
import { IUser } from './interfaces';
import { initState } from "./init.state";

import { SetUserAsync, ResetUserAsync } from './async.thunks';

export const UserSlice = createSlice({
  name: "user",
  initialState: initState,
  reducers: {
    SetUser: (state: IUser, action: any) => {

      return {
        ...state,
        ...action.payload,
      };
    },
    SetLogout: () => {
      return {
        ...initState,
        logOut: true
        }
      
    },
    ResetUser: () => {
      return initState;
    }
  },
  extraReducers: (builder: any) => {
    builder.addCase( SetUserAsync.pending, (_state, _action) => ( { ...initState, SetUserAsyncStatus: "PENDING" } ) );
    builder.addCase( SetUserAsync.rejected, (_state, _action) => ( { ...initState, SetUserAsyncStatus: "ERROR" } ) );
    builder.addCase(SetUserAsync.fulfilled, (_state, action) => ( {...action.payload, SetUserAsyncStatus: "SUCCESS" } ) );
    
    builder.addCase( ResetUserAsync.rejected, (_state, _action) => ( { ..._state, asyncStatus: "ERROR" } ) );
    builder.addCase( ResetUserAsync.fulfilled, (_state, action) => ( action.payload) );
  }
});

// Action creators are generated for each case reducer function
export const {
  SetUser,
  SetLogout,
  ResetUser,
} = UserSlice.actions;

export {
    SetUserAsync,
    ResetUserAsync,
}

export default UserSlice.reducer;
