import { useContext, useEffect } from 'react';

import {
  AlertContext,
  AlertTypes,
} from '@flexo/providers';
import { FirebaseService, httpService } from '@flexo/general';
import { useDispatch, useSelector } from 'react-redux';
import { METADATA, USER } from '@flexo/reducers';


const ENV = (import.meta as any).env;


const firebaseService = new FirebaseService();

export const CommunitiesOverviewResolver = () => {

  const dispatch = useDispatch();
  
  const { setAlertStore } = useContext(AlertContext);

  async function init() {
    const idToken = await firebaseService.getIdToken();

    if (!idToken) {
      setAlertStore({
        type: AlertTypes.SetAlert,
        payload: {
          value: {
            type: 'ALERT_SESSION_EXPIRED'
          },
        },
      })

      
      
      // setUserStore({
      //   type: UserTypes.SetUserLogOut,
      //   payload: {
      //     value: true,
      //   }
      // })

      dispatch( USER.SetLogout())

      return;
    }


    const [overview] = await Promise.all([
      httpService
        .get({
          url: `${ENV.VITE_APP_BASE_URL}/hive/api/catalog/v1_3/organization/${ENV.VITE_APP_ORG_ID}/overview`,
          idToken,
        })
        .then((res: any) => {
          if (res.status === 200) {
            return res.data;
          } else {

            setAlertStore({
              type: AlertTypes.SetAlert,
              payload: {
                value: {
                  type: 'ALERT_UNEXPECTED_ERROR'
                },
              }
            })

            return null;
          }
        })
        .catch((err: any) => {

          setAlertStore({
            type: AlertTypes.SetAlert,
            payload: {
              value: {
                type: 'ALERT_UNEXPECTED_ERROR'
              },
            }
          })

          return null;
        }),
    ]);

    dispatch( METADATA.SetCommunitiesOverview(overview))

  }

  useEffect(() => {
      init();
  }, []);

  return <></>;
};
