import { IModule } from './lib/modules-reducer/interfaces';

const ENV = (import.meta as any).env;


// Define validation functions for each property type
const VALIDATORS: Record<string, (obj: any) => boolean> = {
  footer: (obj: any) => typeof obj === 'boolean',
  pullToRefresh: (obj: any) => typeof obj === 'boolean',
  header: (obj: any) => typeof obj === 'boolean',
  apis: (obj: any) =>
    Array.isArray(obj) && obj.every((item: any) => typeof item === 'string'),
  metaResolvers: (obj: any) =>
    Array.isArray(obj) && obj.every((item: any) => typeof item === 'string'),
  visible: (obj: any) => typeof obj === 'boolean',
  active: (obj: any) => typeof obj === 'boolean',
  widgets: (obj: any) =>
    Array.isArray(obj) &&
    obj.every(
      (item: any) => typeof item === 'object' && 'id' in item && 'type' in item
    ),
  layouts: (obj: any) =>
    typeof obj === 'object' &&
    obj !== null &&
    ['lg', 'md', 'sm', 'xs', 'xxs'].every(
      (key) => key in obj || key in defaultValues.layouts
    ),
};

// Define default values for properties
const defaultValues: Record<string, any> = {
  footer: false,
  header: false,
  pullToRefresh: false,
  active: false,
  visible: false,
  layouts: { lg: [], md: [], sm: [], xs: [], xxs: [] },
  widgets: [],
  apis: [],
  metaResolvers: [],
};

// Function to get the default value for a property
export function getStartingDefault(module: any, propertyName: string) {
  const defaultValue =
    module?.[propertyName]?.default ?? defaultValues[propertyName] ?? null;
  return defaultValue;
}

// Function to merge arrays, ensuring specific configurations override defaults
function mergeArrays(defaultArray: any[], specificArray: any[]) {
  // Start with all items from the specific array
  const mergedArray = [...specificArray];
  // Use a Set to track `id` values present in the specific array
  const seenIds = new Set(specificArray.map(item => item.id));

  // Add items from the default array only if their `id` is not already in the Set
  defaultArray.forEach((item) => {
    if (!seenIds.has(item.id)) {
      mergedArray.push(item);
    }
  });

  return mergedArray;
}

// Function to merge objects, handling arrays specifically
function mergeConfigs(defaultConfig: any, specificConfig: any) {
  const mergedConfig = { ...defaultConfig };
  Object.keys(specificConfig).forEach((key) => {
    if (Array.isArray(specificConfig[key])) {
      // Merge arrays with specific items taking precedence
      mergedConfig[key] = mergeArrays(defaultConfig[key] || [], specificConfig[key]);
    } else if (typeof specificConfig[key] === 'object' && specificConfig[key] !== null) {
      // Recursively merge nested objects
      mergedConfig[key] = mergeConfigs(defaultConfig[key] || {}, specificConfig[key]);
    } else {
      // Override scalar values directly
      mergedConfig[key] = specificConfig[key];
    }
  });
  return mergedConfig;
}

// Function to retrieve nested data based on user group, site type, and user type
export function retrieveNestedData(
  module: any,
  propertyName: string,
  userGroup: string | null,
  siteType: string | null,
  userType: string | null
) {
  const property = module?.[propertyName];
  if (!property) {
    return getStartingDefault(module, propertyName);
  }

  // Start with the default configuration
  let mergedProperty = property.default || getStartingDefault(module, propertyName);

  // Merge with specific configurations in order of precedence
  if (userGroup && siteType && userType) {
    const specificProperty = property[userGroup]?.[siteType]?.[userType];
    if (Array.isArray(specificProperty)) {
      mergedProperty = mergeArrays(mergedProperty, specificProperty);
    } else {
      mergedProperty = { ...mergedProperty, ...specificProperty };
    }
  } else if (userGroup && siteType) {
    const specificProperty = property[userGroup]?.[siteType];

    if (Array.isArray(specificProperty)) {

      mergedProperty = mergeArrays(mergedProperty, specificProperty);
    } else {
      mergedProperty = { ...mergedProperty, ...specificProperty };
    }
  } else if (userGroup) {
    const specificProperty = property[userGroup];
    if (Array.isArray(specificProperty)) {
      mergedProperty = mergeArrays(mergedProperty, specificProperty);
    } else {
      mergedProperty = { ...mergedProperty, ...specificProperty };
    }
  }
  return mergedProperty;
}

// Function to validate a property using the appropriate validator
function validateProperty(propertyName: string, value: any, module: any) {
  const isValid = VALIDATORS[propertyName](value);
  const validatedValue = isValid
    ? value
    : getStartingDefault(module, propertyName);
  return validatedValue;
}

// Function to filter and process modules based on the provided user context
export function moduleFilter(
  MODULES: any,
  userGroup: string | null,
  siteType: string | null,
  userType: string | null
) {
  const filteredModules = MODULES.map((module: any) => {
    const properties = [
      'footer',
      'header',
      'pullToRefresh',
      'layouts',
      'widgets',
      'apis',
      'metaResolvers',
      'visible',
      'active',
    ];
    const filteredModule: any = { name: module.name };

    properties.forEach((property) => {
      const nestedData = retrieveNestedData(
        module,
        property,
        userGroup,
        siteType,
        userType
      );
      filteredModule[property] = validateProperty(property, nestedData, module);
    });

    return filteredModule;
  });

  return filteredModules;
}


export const setUrl = (newUrl) => {
  // Get the current URL
  const currentUrl = new URL(window.location.href);

  // Extract the current parameters from the query string
  const currentParams = new URLSearchParams(currentUrl.search);

  // Extract the new parameters from the newUrl
  const newUrlObject = new URL(newUrl, window.location.origin);
  const newParams = new URLSearchParams(newUrlObject.search);

  // Merge new parameters into current ones (replaces or adds)
  newParams.forEach((value, key) => {
    currentParams.set(key, value); // Replaces or adds new parameters
  });

  // Update both the path and the search params
  currentUrl.pathname = newUrlObject.pathname; // Set the new path
  currentUrl.search = currentParams.toString(); // Update the query string with merged parameters
  // Update the URL without reloading the page
  window.history.pushState({}, '', currentUrl.toString());
};
