import { useContext, useEffect } from 'react';

import {
  AlertTypes,
  AlertContext
} from '@flexo/providers';

import { FirebaseService, httpService } from '@flexo/general';
import { Community, EntitiesData, Member, StructuredData } from "@flexo/providers";
import { useDispatch } from 'react-redux';
import { METADATA, MODULES, store, USER } from '@flexo/reducers';
;

const ENV = (import.meta as any).env;


// TODO: check why structuredData is not ok
export function structureData(data: EntitiesData): any {
  const communityMap = new Map<string, Community|any>();
  const memberMap = new Map<string, Member|any>();
  const assignedMemberIds = new Set<string>();
  const assignedSiteIds = new Set<string>();
  const assignedEvIds = new Set<string>();
  const assignedWallboxIds = new Set<string>();


  if (!data || Object.keys(data)?.length === 0) {
    return;
  }

  // Create community map
  (data.communities || data.community ).forEach((community) => {
    communityMap.set(community.communityId, { ...community, members: [] as any });
  });

  // Create member map
  (data.members|| data.member).forEach((member) => {
    memberMap.set(member.memberId, { ...member, sites: [], evs: [], wallboxes: [] });

    // Associate members with communities directly if they have a communityId
    if (member?.communityId && communityMap.has(member?.communityId)) {
      const community = communityMap.get(member?.communityId);
      community?.members.push(memberMap.get(member?.memberId));
      assignedMemberIds?.add(member?.memberId);
    }
  });

  // Associate sites with members and track assigned site IDs
  (data.sites|| data.site).forEach((site: any) => {
    if (memberMap.has(site.memberId)) {
      const member = memberMap.get(site.memberId);
      member.sites.push(site);
      assignedSiteIds.add(site.siteExternalId);

      // Associate members with communities via sites if not already associated
      if (!member.communityId && communityMap.has(site.communityId)) {
        const community = communityMap.get(site.communityId);
        if (!community.members.some((m) => m.memberId === member.memberId)) {
          community.members.push(member);
          assignedMemberIds.add(member.memberId);
        }
      }
    }
  });

  // Associate EVs with members and track assigned EV IDs
  data?.ev.forEach((ev) => {
    if (memberMap.has(ev.memberId)) {
      const member = memberMap.get(ev.memberId);
      member.evs.push(ev);
      assignedEvIds.add(ev.evId);

      // Associate members with communities via EVs if not already associated
      if (!member.communityId && communityMap.has(ev.communityId)) {
        const community = communityMap.get(ev.communityId);
        if (!community.members.some((m) => m.memberId === member.memberId)) {
          community.members.push(member);
          assignedMemberIds.add(member.memberId);
        }
      }
    }
  });

  // Associate Wallboxes with members and track assigned Wallbox IDs
  data?.wallbox.forEach((wallbox) => {
    if (memberMap.has(wallbox.memberId)) {
      const member = memberMap.get(wallbox.memberId);
      member.wallboxes.push(wallbox);
      assignedWallboxIds.add(wallbox.wallboxId);

      // Associate members with communities via Wallboxes if not already associated
      if (!member.communityId && communityMap.has(wallbox.communityId)) {
        const community = communityMap.get(wallbox.communityId);
        if (!community.members.some((m) => m.memberId === member.memberId)) {
          community.members.push(member);
          assignedMemberIds.add(member.memberId);
        }
      }
    }
  });

  // Structure the data
  const structuredData: StructuredData = {
    communities: [],
    unassignedMembers: [],
    unassignedSites: [],
    unassignedEVs: [],
    unassignedWallboxes: [],
  };

  communityMap.forEach((community) => {
    structuredData.communities.push(community);
  });

  memberMap.forEach((member) => {
    if (!assignedMemberIds.has(member.memberId)) {
      structuredData.unassignedMembers.push(member);
    }
  });

  data?.sites?.forEach((site) => {
    if (!assignedSiteIds.has(site.siteExternalId)) {
      structuredData.unassignedSites.push(site);
    }
  });

  data?.ev?.forEach((ev) => {
    if (!assignedEvIds.has(ev.evId)) {
      structuredData.unassignedEVs.push(ev);
    }
  });

  data?.wallbox?.forEach((wallbox) => {
    if (!assignedWallboxIds.has(wallbox.wallboxId)) {
      structuredData.unassignedWallboxes.push(wallbox);
    }
  });

  return structuredData;
}

const firebaseService = new FirebaseService();

export const EntitiesResolver = () => {
  const dispatch = useDispatch();
  const { setAlertStore } = useContext(AlertContext);

  async function init() {

    const idToken = await firebaseService.getIdToken();

    if (!idToken) {
      setAlertStore({
        type: AlertTypes.SetAlert,
        payload: {
          value: {
            type: 'ALERT_SESSION_EXPIRED'
          },
        },
      })

      // setUserStore({
      //   type: UserTypes.SetUserLogOut,
      //   payload: {
      //     value: true,
      //   }
      // })

      dispatch(USER.SetLogout())

      return;
    }

    const [entities] = await Promise.all([
      httpService
        .get({
          url: `${ENV.VITE_APP_BASE_URL}/hive/api/catalog/v1_3/entities`,
          idToken,
          mock: false,
        })
        .then((res: any) => {
          if (res.status === 200) {

            // TO DO: remove this atrocious work around

            return {
              ...res.data,
              sites: res.data.site,
              communities: res.data.community,
              members: res.data.member,
            };
          } else {
            // console.warn({
            //   status: res.status,
            //   message: res.statusText,
            // });

            setAlertStore({
              type: AlertTypes.SetAlert,
              payload: {
                value: {
                  type: 'ALERT_SESSION_EXPIRED'
                },
              },
            })

            return null;
          }
        })
        .catch((err: any) => {
                    
          setAlertStore({
            type: AlertTypes.SetAlert,
            payload: {
              value: {
                type: 'ALERT_UNEXPECTED_ERROR'
              },
            }
          })


          return null;
        }),
    ]);

    dispatch( METADATA.SetEntities( entities ) )

    dispatch(METADATA.SetSortedEntities(structureData(entities)));

  }


  useEffect(() => {
      init();
  }, []);

  return <></>;
};

export function navigateToHousehold(setDataStore: any) {
  const dispatch = store.dispatch;
  //TODO add ID of selectedSite in the common store, replicate community resolver
  dispatch( MODULES.ActivateHouseholdModule() as any )
}
