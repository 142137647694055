import { useContext, useState } from 'react';
import { Button, PageHeader, Textfield } from '@flexo/atoms';
import { FirebaseService, IconWrapper, httpService } from '@flexo/general';
import styles from './account-creation.module.scss';
import { AlertContext, AlertTypes, UserContext } from '@flexo/providers';
import { Player } from '@lottiefiles/react-lottie-player';
import { UserTypes } from '@flexo/providers';

import * as Sentry from '@sentry/react';

import { useTranslation } from 'react-i18next';

import { ModulesContext, ModulesTypes } from '@flexo/providers';
import { useDispatch, useSelector } from 'react-redux';
import { MODULES, USER } from '@flexo/reducers';

const ENV = (import.meta as any).env;
const firebaseService = new FirebaseService();

/* eslint-disable-next-line */

interface IAccountCreation {
  containedLabelTextfield?: boolean;
}
export function AccountCreationOOBcode({
  containedLabelTextfield,
}: IAccountCreation) {
  const {
    AccountCreation__Header,
    AccountCreation__Header__Title,
    AccountCreation__Header__Subtitle,
    AccountCreation__Inputs,
    AccountCreation__Footer,
    AccountCreation__Footer__Register,
    AccountCreation__Footer__Register__Button,
    AccountCreation__Inputs__PasswordConditions,
    AccountCreation__Footer__Submit,
  } = styles;

  const { t } = useTranslation();
  const user = useSelector((state: any) => state.user);
  const dispatch = useDispatch()

  
  const [authData, setAuthData] = useState({
    new_password: '',
    new_password_confirm: '',
  });
  const [AccountCreationClicked, setAccountCreationClicked] = useState(false);
  const [AccountCreationError, setAccountCreationError] = useState(false);
  const [networkStatus, setNetworkStatus] = useState(false);
  const [inputType, setInputType] = useState({
    new_password: 'password',
    new_password_confirm: 'password',
  });

  
  const { alertStore, setAlertStore } = useContext(AlertContext);

  const [storedEmail, setStoredEmail] = useState(user.email);

  const AccountCreationOOBcodeData = {
    title: t('pages.account_creation.title'),
    subtitle: t('pages.account_creation.subtitle'),
    inputs: [
      {
        name: 'email',
        placeholder: t('pages.account_creation.user_email_placeholder'),
        label: t('pages.account_creation.user_email'),
        type: 'text',
        disabled: true,
        value: user.email,
      },
      {
        name: 'new_password',
        placeholder: t('pages.account_creation.new_password_placeholder'),
        label: t('pages.account_creation.new_password'),
        type: 'password',
        icon: 'hide',
      },
      {
        name: 'new_password_confirm',
        placeholder: t(
          'pages.account_creation.new_password_confirm_placeholder'
        ),
        label: t('pages.account_creation.new_password_confirm'),
        type: 'password',
        icon: 'hide',
      },
    ],
    buttonLabel: t('pages.account_creation.create'),
  };

  const { title, subtitle, inputs, buttonLabel } = AccountCreationOOBcodeData;
  const ENV = (import.meta as any).env;

  const appName = ENV.VITE_APP_NAME;

  const passwordConditions = [
    {
      key: 'length>=8',
      label: t('password_conditions.length') as string,
    },
    {
      key: 'number',
      label: t('password_conditions.number') as string,
    },
    {
      key: 'uppercase_letter',
      label: t('password_conditions.uppercase') as string,
    },
    {
      key: 'lowercase_letter',
      label: t('password_conditions.lowercase') as string,
    },
    {
      key: 'special_character',
      label: t('password_conditions.special') as string,
    },
  ];

  const loader = '/loaderLottie.json';

  const handleInputChange = (event: any) => {
    if (event.target.name === 'email') {
      setStoredEmail(event.target.value);
      // setUserStore({
      //   type: UserTypes.SetUser,
      //   payload: {
      //     value: {
            
      //     },
      //   },
      // });

      dispatch(USER.SetUserAsync({
        ...user,
        email: event.target.value,
      }) as any )

    } else {
      setAuthData({ ...authData, [event.target.name]: event.target.value });
    }
  };

  const registerUser = async (event: any) => {
    event.preventDefault();
    setAccountCreationClicked(true);
    try {
      const response = await httpService.post({
        url: 'flexo/auth/register',
        data: {
          email: storedEmail,
          new_password: authData.new_password,
          temp_code: user.OOBcode,
          new_password_confirm: authData.new_password_confirm,
          privacy: true,
          tenant_id: ENV.VITE_APP_TENANT,
        },
      });

      return response;
    } catch (error: any) {
      if (error.message === 'ERR_NETWORK') {
        setAlertStore({
          type: AlertTypes.SetAlert,
          payload: {
            value: {
              type: 'ALERT_UNEXPECTED_ERROR',
            } as any,
          },
        });
      } else {
        setAlertStore({
          type: AlertTypes.SetAlert,
          payload: {
            value: {
              type: 'ALERT_WRONG_PARAMS',
            } as any,
          },
        });
      }

      // setLogInError(true);
      Sentry.captureEvent({
        // event_id: 'fetchData_no_data',
        message: `Performed request through firebase: error`,
        release: `${ENV.VITE_APP_VERSION}(${ENV.VITE_APP_BUILD})`,
        level: 'warning' as any,
        extra: {
          error,
        },
      });

      // console.error(error);
    } finally {
      // Sentry.captureEvent({
      //   // event_id: 'fetchData_no_data',
      //   message: `Performed request through firebase: error`,
      //   release: `${ENV.VITE_APP_VERSION}(${ENV.VITE_APP_BUILD})`,
      //   level: 'warning' as any,
      //   extra: {
      //     error,
      //   },
      // });

      // Reset AccountCreationClicked state after attempt.
      setAccountCreationClicked(false);
      await signIn();
    }
  };

  const checkCondition = (condition: string): string => {
    let iconName = 'empty-circle';

    switch (condition) {
      case 'length>=8':
        if (authData.new_password.length >= 8) iconName = 'tick-success';
        break;
      case 'number':
        if (authData.new_password.match(/\d/)) iconName = 'tick-success';
        break;

      case 'uppercase_letter':
        if (authData.new_password.match(/[A-Z]/)) iconName = 'tick-success';
        break;
      case 'lowercase_letter':
        if (authData.new_password.match(/[a-z]/)) iconName = 'tick-success';
        break;
      case 'special_character':
        if (authData.new_password.match(/[^a-zA-Z0-9]+/))
          iconName = 'tick-success';
        break;
      default:
        break;
    }
    return iconName;
  };

  const togglePasswordVisibility = (name: string) => {
    setInputType((prevState) => ({
      ...prevState,
      [name]: prevState[name] === 'password' ? 'text' : 'password',
    }));
  };

  const signIn = async () => {
    try {
      Sentry.captureEvent({
        // event_id: 'fetchData_no_data',
        message: `Performed request through firebase: start`,
        release: `${ENV.VITE_APP_VERSION}(${ENV.VITE_APP_BUILD})`,
        level: 'info' as any,
      });

      // First, get the user token.
      const response: any = await httpService.post({
        url: `https://gateway-dev.flexo.energy/flexo/auth/login`,
        data: {
          email: user.email,
          password: authData.new_password,
          tenant_id: ENV.VITE_APP_TENANT,
          org_id: ENV.VITE_APP_ORG_ID,
        },
      });

      // Use the token from the response to sign in.
      const firebaseResult = await firebaseService.signInWithToken(
        response?.data?.customSignInToken
      );

      // setUserStore({
      //   type: UserTypes.SetShowLogin,
      //   payload: {
      //     value: false,
      //   },
      // });

      dispatch( MODULES.ActivateHouseholdModule() as any)

      return firebaseResult;
    } catch (error: any) {
      if (error.message === 'ERR_NETWORK') {
        setAlertStore({
          type: AlertTypes.SetAlert,
          payload: {
            value: {
              type: 'ALERT_UNEXPECTED_ERROR',
            } as any,
          },
        });
      } else {
        setAlertStore({
          type: AlertTypes.SetAlert,
          payload: {
            value: {
              type: 'ALERT_WRONG_PARAMS',
            } as any,
          },
        });
      }

      // setLogInError(true);
      Sentry.captureEvent({
        // event_id: 'fetchData_no_data',
        message: `Performed request through firebase: error`,
        release: `${ENV.VITE_APP_VERSION}(${ENV.VITE_APP_BUILD})`,
        level: 'warning' as any,
        extra: {
          error,
        },
      });

      // console.error(error);
    } finally {
      // Reset logInClicked state after attempt.
      // setLogInClicked(false);
    }
  };

  return (
    <div className={styles.AccountCreation}>
      <div style={{ position: 'fixed' }}>
        <PageHeader
          title={title}
          subtitle={subtitle}
          back={t('pages.account_creation.get_back') || ''}
          backAction={() => {
            dispatch( MODULES.ActivateLoginModule() as any)
          }}
        />
      </div>
      <div className={styles.AccountCreation__Content}>
        <div className={AccountCreation__Inputs}>
          {inputs.map((input: any) => {
            return (
              <>
                <Textfield
                  onPaste={(e) => false}
                  onCopy={(e) => false}
                  name={input.name}
                  value={
                    input.name === 'email' ? storedEmail : authData[input.name]
                  }
                  placeholder={!containedLabelTextfield && input.placeholder}
                  label={input.label}
                  onChange={(event) => handleInputChange(event)}
                  type={inputType[input.name]}
                  description={input.description}
                  icon={
                    inputType[input.name] === 'text' &&
                    input.name.includes('password')
                      ? 'show'
                      : input.icon
                  }
                  onIconClick={togglePasswordVisibility}
                  containedLabel={containedLabelTextfield}
                  // disabled={input?.disabled || false}
                  autoCapitalize="off"
                  autoCorrect="off"
                  spellCheck="false"
                />

                {input.name === 'new_password' && (
                  <div>
                    {passwordConditions.map((condition: any) => (
                      <div
                        className={AccountCreation__Inputs__PasswordConditions}
                        key={condition.key}
                      >
                        <IconWrapper iconName={checkCondition(condition.key)} />
                        <span
                          className={`${styles.AccountCreation__Inputs__PasswordConditions} detail`}
                        >
                          {condition.label}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              </>
            );
          })}
        </div>
        <div className={AccountCreation__Footer}>
          <Button
            onClick={(event) => registerUser(event)}
            label={
              AccountCreationClicked && !AccountCreationError ? (
                <Player autoplay loop src={loader} style={{ height: 20 }} />
              ) : (
                buttonLabel
              )
            }
            variant="primary"
            color="secondary"
            disabled={
              user?.email === null ||
              user?.email === '' ||
              user?.OOBcode === null ||
              user?.OOBcode === '' ||
              authData.new_password === '' ||
              authData.new_password_confirm === '' ||
              authData.new_password !== authData.new_password_confirm ||
              (AccountCreationClicked && !AccountCreationError) ||
              networkStatus
            }
          />
        </div>
      </div>
    </div>
  );
}

export default AccountCreationOOBcode;
