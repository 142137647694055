import { useTranslation } from 'react-i18next';
import styles from './../alert.module.scss';
import { IconWrapper } from '@flexo/general';
import { useContext } from 'react';
import {
  AlertContext,
  AlertTypes,
  ModulesContext,
  ModulesTypes,
} from '@flexo/providers';

export default function AlertInvalidCredentials() {
  const { setAlertStore } = useContext(AlertContext);
  const { t } = useTranslation();
  const alertContent = t('alerts.invalid_credentials', { returnObjects: true }) as any;

  return (
    <div id="alert-invalid-credentials" className={styles.Alert__Invalid__Credentials}>

      <div className={styles.Alert__Title}>
        <div>
          <IconWrapper iconName="alert" />
          {alertContent.title}
        </div>
        <div
          onClick={() =>
            setAlertStore({
              type: AlertTypes.ResetAlert,
              payload: null as any,
            })
          }
        >
          <IconWrapper
            className={styles.Alert__Title__Close}
            iconName="cross"

          />
        </div>

      </div>
      <div className={styles.Alert__Content}>
        {alertContent.description}
      </div>
    </div>
  );
}
