import styles from './table.module.scss';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { v4 as uuidv4 } from 'uuid';
import parse from 'html-react-parser';



import { useEffect, useRef, useState } from 'react';
import { TableFooter, TableSortLabel } from '@mui/material';
import { navigateToMember, navigateToSite } from '@flexo/atoms';
import { IconWrapper, capitalizeFirstLetter, formatHumanDate } from '@flexo/general';
import { useOnClickOutside } from '@flexo/hooks';
import { useSelector } from 'react-redux';

function getPreviousSiblingCoordinates(elementId: string): any {

  // Ottiene il fratello precedente
  const element: any = document.getElementById(elementId);

  // Ottiene il bounding rectangle del fratello precedente
  const rect = element.getBoundingClientRect();

}



export function MembersTable(props: any) {

  const metadata = useSelector((state: any) => state.metadata);
  const { t } = useTranslation();
  
  const futureColumns: any | null = t(`widgets.tables.FutureMemebersTable`, { returnObjects: true }) || null;
  const currentColumns: any | null = t(`widgets.tables.CurrentMemebersTable`, { returnObjects: true }) || null;
  const previousColumns: any | null = t(`widgets.tables.PreviousMemebersTable`, { returnObjects: true }) || null;

  const [futureRows, setFutureRows] = useState<Array<any>>([]);
  const [currentRows, setCurrentRows] = useState<Array<any>>([])
  const [previousRows, setPreviousRows] = useState<Array<any>>([]);

  const [orderFuture, setOrderFuture] = useState(['name', 'asc']);
  const [orderCurrent, setOrderCurrent] = useState(['name', 'asc']);
  const [orderPrevious, setOrderPrevious] = useState(['name', 'asc']);

  const [futureOpen, setFutureOpen] = useState<string | null>(null);
  const [currentOpen, setCurrentOpen] = useState<string | null>(null);
  const [previousOpen, setPreviousOpen] = useState<string | null>(null);

  const futureAnchorEl = useRef<any>(null);
  const currentAnchorEl = useRef<any>(null);
  const previousAnchorEl = useRef<any>(null);

  useOnClickOutside(futureAnchorEl, () => setFutureOpen(null));
  useOnClickOutside(currentAnchorEl, () => setCurrentOpen(null));
  useOnClickOutside(previousAnchorEl, () => setPreviousOpen(null));

  const handleClick = (event: any, id: string, context: string) => {

    if (id === futureOpen || id === currentOpen || id === previousOpen) {
      return;
    }

    switch (context) {
      case 'future':
        futureOpen === id ? setFutureOpen(null) : setFutureOpen(id);
        break;
      case 'current':
        currentOpen === id ? setCurrentOpen(null) : setCurrentOpen(id);
        break;
      case 'previous':
        previousOpen === id ? setPreviousOpen(null) : setPreviousOpen(id);
        break;
      default:
        break;
    }


  };

  const [loading, setLoading] = useState(false);

  function loadDataSources() {

    // setLoading(true);

    const sourceStore = metadata?.filteredMembers ? metadata?.filteredMembers : metadata?.members;

    const futureDataSource = [ ...(sourceStore?.future || [])].sort((a, b) => (a.name > b.name ? 1 : -1));
    const currentDataSource = [ ...(sourceStore?.current || [])].sort((a, b) => (a.name > b.name ? 1 : -1));
    const previousDataSource = [ ...(sourceStore?.previous || [])].sort((a, b) => (a.name > b.name ? 1 : -1));

    if (JSON.stringify(futureDataSource) !== JSON.stringify(futureRows)) {
      setFutureRows(futureDataSource);
    }
    if (JSON.stringify(currentDataSource) !== JSON.stringify(currentRows)) {
      setCurrentRows(currentDataSource);
    }
    if (JSON.stringify(previousDataSource) !== JSON.stringify(previousRows)) {
      setPreviousRows(previousDataSource);
    }

    setTimeout(() => {
      setLoading(false);
    }, 1);

  }

  useEffect(() => {
    loadDataSources()
  }, [
    metadata?.filteredMembers, metadata?.members
  ])

  useEffect(() => {
    loadDataSources()
  }, [])

  function sortFutureBy(columnId: string) {

    try {

      let _midArray = [...futureRows];
      let direction = 'asc';

      if (orderFuture[0] === columnId && orderFuture[1] === 'asc') {
        direction = 'desc';
      }

      switch (columnId) {
        case 'name':
          _midArray = _midArray.sort((a, b) => ((direction === 'asc' ? a.name > b.name : a.name < b.name) ? 1 : -1));
          break;
      }

      setOrderFuture([columnId, direction]);
      setFutureRows(_midArray);

    } catch (err: any) {
      // console.warn(err)
    }
  }

  function sortCurrentBy(columnId: string) {

    function getNames(a: any, b: any) {
      const a_name = `${a.memberFirstName} ${a.memberLastName}`;
      const b_name = `${b.memberFirstName} ${b.memberLastName}`;
      return [a_name, b_name];
    }

    try {

      let _midArray = [...currentRows];
      let direction = 'asc';

      if (orderCurrent[0] === columnId && orderCurrent[1] === 'asc') {
        direction = 'desc';
      }

      switch (columnId) {
        case 'name':

          _midArray = _midArray.sort((a, b) => {
            const [a_name, b_name] = getNames(a, b);
            return (direction === 'asc' ? a_name > b_name : a_name < b_name) ? 1 : -1
          });

          break;
      }

      setOrderCurrent([columnId, direction]);
      setCurrentRows(_midArray);

    } catch (err: any) {
      // console.warn(err)
    }
  }

  function sortPreviousBy(columnId: string) {

    try {

      let _midArray = [...previousRows];
      let direction = 'asc';

      if (orderPrevious[0] === columnId && orderPrevious[1] === 'asc') {
        direction = 'desc';
      }

      switch (columnId) {
        case 'name':
          _midArray = _midArray.sort((a, b) => ((direction === 'asc' ? a.name > b.name : a.name < b.name) ? 1 : -1));
          break;
      }

      setOrderPrevious([columnId, direction]);
      setPreviousRows(_midArray);

    } catch (err: any) {
      // console.warn(err)
    }
  }


  return (

    loading ? <div> Loading...</div>
      : <>
        {
          futureRows && futureRows?.length > 0 ?
            <div
              className={`${styles.Table} ${styles.Table__Members} mb-3`}
              key={`${uuidv4()}`}
            >
              {futureColumns && <div className={`${styles.Table__Title}`}>
                {futureColumns.title}
              </div>}

              <TableContainer key={uuidv4()} component={Paper} className={`${futureColumns.title ? 'mt-1' : ''}`}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {futureColumns && futureColumns.rows.map((column: any, column_i: number) => (
                        <TableCell
                          className={`
                      ${column.sortable ? styles.Table__Header__Cell__Sortable : styles.Table__Header__Cell__Static}
                      ${(column.sortable && orderFuture[0] === column.field) ? styles.Table__Header__Cell__Active : styles.Table__Header__Cell} `
                          }
                          key={`future_members_table_${column_i}_${uuidv4()}`}>
                          <TableSortLabel
                            active={column.sortable && orderFuture[0] === column.field}
                            direction={orderFuture[0] === column.field && orderFuture[1] === 'asc' ? 'asc' : 'desc'}
                            onClick={() => column.sortable ? sortFutureBy(column.field) : null}
                            className={`${styles.Table__Header__Cell} `}
                            IconComponent={() => (
                              (orderFuture[0] === column.field)
                                ? orderFuture[1] === 'asc'
                                  ? <IconWrapper iconName='chevron-up' className={`${styles.Table__TH__Icon}`} />
                                  : <IconWrapper iconName='chevron-down' className={`${styles.Table__TH__Icon}`} />
                                : <></>
                            )
                            }
                          >
                            <div > {column.title}</div>
                          </TableSortLabel>

                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody key={`${uuidv4()}`}>


                    {(futureRows || []).map((row) => (
                      <TableRow
                        key={`${Math.floor(Math.random() * 100)}__${uuidv4()}`}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        style={{ height: 100 }}
                        className={`${styles.Table__Body__Row__Clickable}`}
                        onClick={() => (window.getSelection()?.toString() || '')?.length < 4
                          ? navigateToMember( 'member', row?.memberId, )
                          : null
                        }
                      >
                        <TableCell
                          className={`${!futureColumns?.rows?.[0]?.sortable === true && styles.Table__Cell__Static}`}
                          key={`future_members_table_row_cell_0_${uuidv4()}`}
                          component="th"
                          scope="row"
                        >
                          <div className={`${styles.Table__Cell__Member__Name}`}>
                            <span >{capitalizeFirstLetter(`${row.memberFirstName} ${row.memberLastName}`)}</span>
                            <label className={styles.Table__Cell__Member__ID}>{(row?.memberExternalId || 'missing_member_id')} </label>
                          </div>
                        </TableCell>

                        <TableCell
                          className={`${!futureColumns?.rows?.[1]?.sortable === true && styles.Table__Cell__Static}`}
                          key={`future_members_table_row_cell_1_${uuidv4()}`}
                          component="th"
                          scope="row"
                        >
                          <div>
                            <label className={styles.Table__Cell__Member__Site__Label}>{(row?.sites?.[0]?.pod || 'missing_pod')} </label>

                            <div className={styles.Table__Cell__Member__Sites__Names}>
                              {capitalizeFirstLetter(row?.sites?.[0]?.siteName || 'missing name')}
                              <span
                                id={`future__popper__${(row?.sites?.[0]?.siteId || 'missing_id')}`}
                                className={styles.Popper__Label}
                                onMouseEnter={(event: any) => handleClick(event, (row?.sites?.[0]?.siteId || 'missing_id'), 'future')}
                              >
                                {' '}{row.sites.length > 1 && ` + ${row.sites.length - 1}`}
                              </span>
                              {
                                futureOpen === (row?.sites?.[0]?.siteId || 'missing_id') &&
                                <div
                                  ref={futureAnchorEl}
                                  className={`${styles.Popper__Wrapper}`}
                                  onMouseLeave={() => setFutureOpen(null)}
                                  key={`future__popper__${uuidv4()}`}
                                  style={{
                                    top: `calc( 100% - ${getPreviousSiblingCoordinates(`future__popper__${(row?.sites?.[0]?.siteId || 'missing_id')}`)?.top} )`,
                                    marginTop: -16,
                                    //left: getPreviousSiblingCoordinates(`future__popper__${(row?.sites?.[0]?.siteId || 'missing_id')}`)?.left - 16 || 0,
                                  }}
                                >
                                  {row.sites.map((memberSite: any) => {
                                    return (
                                      <div
                                        key={`future__popper__site__${uuidv4()}`}
                                        className={`${styles.Popper__Wrapper__Item}`}
                                        onClick={(ev: any) => (ev.preventDefault(), ev.stopPropagation(), navigateToSite('site-economics', memberSite?.siteId))}
                                      >
                                        <p className={`${styles.Popper__Name}`}>{capitalizeFirstLetter(memberSite.siteName)}</p>
                                        <p className={`${styles.Popper__Text}`}>{memberSite.pod}</p>
                                      </div>
                                    )
                                  })}
                                </div>
                              }
                            </div>
                          </div>

                        </TableCell>

                        <TableCell
                          className={`${!futureColumns?.rows?.[2]?.sortable === true && styles.Table__Cell__Static}`}
                          key={`future_members_table_row_cell_4_${uuidv4()}`}
                          component="th"
                          scope="row"
                          align='right'
                        >
                          <div>

                            <label style={{ alignSelf: "flex-end" }} >
                              {formatHumanDate(row?.sites?.[0]?.startDate || '')}
                            </label>
                          </div>
                        </TableCell>


                      </TableRow>
                    ))}

                  </TableBody>

                  <TableFooter >
                    <TableRow>
                      <TableCell
                        align="left"
                        className={`${(futureColumns.rows[0].sortable && styles.Table__Footer__Cell__Clickable)}`}

                      >

                        <div
                          onClick={() => futureColumns.rows[0].sortable ? sortFutureBy('name') : null}
                          className={`${(futureColumns.rows[0].sortable && orderFuture[0] === 'name') ? styles.Table__Footer__Cell__Active : styles.Table__Footer__Cell}`}
                        >
                          <span>
                            {parse(t(`widgets.tables.utils.${(futureRows?.length || 0) === 1 ? 'member' : 'members'}`, { members: (futureRows?.length || 0) }))}
                          </span>

                          {
                            (orderFuture[0] === 'name')
                              ? orderFuture[1] === 'asc'
                                ? <IconWrapper iconName='chevron-up' className={`${styles.Table__TH__Icon}`} />
                                : <IconWrapper iconName='chevron-down' className={`${styles.Table__TH__Icon}`} />
                              : <div style={{ height: 20, width: 19 }}></div>
                          }
                        </div>


                      </TableCell>
                      <TableCell>

                      </TableCell>
                      <TableCell>

                      </TableCell>
                    </TableRow>
                  </TableFooter>

                </Table>
              </TableContainer>

            </div>
            : <></>
          //   <div
          //   className={`${styles.Table} pb-3`}
          //   key={uuidv4()}
          // >
          //   <div className={`${styles.Table__Title}`}>
          //     {futureColumns.title}
          //   </div>
          //   { dataStore?.data?.members?.future?.lenght === 0 && <div> {futureColumns.no_data_text}</div>}
          // </div>
        }


        {
          currentRows && currentRows?.length > 0 ?
            <div
              className={`${styles.Table} ${styles.Table__Members} mb-3`}
              key={`${uuidv4()}`}
            >
              {currentColumns.title && <div className={`${styles.Table__Title}`}>
                {currentColumns.title}
              </div>}

              <TableContainer key={uuidv4()} component={Paper} className={`${currentColumns.title ? 'mt-1' : ''}`}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {currentColumns && currentColumns.rows.map((column: any, column_i: number, column_arr: any) => (
                        <TableCell
                          {...(
                            column_arr?.length - 1 === column_i
                              ? { align: 'right' }
                              : {}
                          )}
                          className={`
                        ${column.sortable ? styles.Table__Header__Cell__Sortable : styles.Table__Header__Cell__Static}
                  ${(column.sortable && orderCurrent[0] === column.field) ? styles.Table__Header__Cell__Active : styles.Table__Header__Cell}`
                          }
                          key={`current_members_table_${column_i}_${uuidv4()}`}
                        >
                          <TableSortLabel
                            active={column.sortable && orderCurrent[0] === column.field}
                            direction={orderCurrent[0] === column.field && orderCurrent[1] === 'asc' ? 'asc' : 'desc'}
                            onClick={() => column.sortable ? sortCurrentBy(column.field) : null}
                            className={`${styles.Table__Header__Cell}`}
                            IconComponent={() => (
                              (orderCurrent[0] === column.field)
                                ? orderCurrent[1] === 'asc'
                                  ? <IconWrapper iconName='chevron-up' className={`${styles.Table__TH__Icon}`} />
                                  : <IconWrapper iconName='chevron-down' className={`${styles.Table__TH__Icon}`} />
                                : <div style={{ height: 20, width: 19 }}></div>
                            )
                            }
                          >
                            <div > {column.title}</div>
                          </TableSortLabel>

                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody key={`${uuidv4()}`}>

                    {(currentRows || []).map((row) => (
                      <TableRow
                        key={`${Math.floor(Math.random() * 100)}__${uuidv4()}`}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        className={`${styles.Table__Body__Row__Clickable}`}
                        style={{ height: 100 }}
                        onClick={() => (window.getSelection()?.toString() || '')?.length < 4
                          ? navigateToMember( 'member', row?.memberId, )

                          : null
                        }
                      >
                        <TableCell
                          className={`${!futureColumns?.rows?.[0]?.sortable === true && styles.Table__Cell__Static}`}
                          key={`future_members_table_row_cell_0_${uuidv4()}`}
                          component="th"
                          scope="row"
                        >
                          <div className={`${styles.Table__Cell__Member__Name}`}>
                            <span >{capitalizeFirstLetter(`${row.memberFirstName} ${row.memberLastName}`)}</span>
                            <label className={styles.Table__Cell__Member__ID}>{(row?.memberExternalId || 'missing_member_id')} </label>
                          </div>
                        </TableCell>

                        <TableCell
                          className={`${!currentColumns?.rows?.[1]?.sortable === true && styles.Table__Cell__Static}`}
                          key={`current_members_table_row_cell_1_${uuidv4()}`}
                          component="th"
                          scope="row"
                        >
                          <div>
                            <label className={styles.Table__Cell__Member__Site__Label}>{(row?.sites?.[0]?.pod || 'missing_pod')} </label>

                            <div className={styles.Table__Cell__Member__Sites__Names}>
                              {capitalizeFirstLetter(row?.sites?.[0]?.siteName || 'missing name')}
                              <span
                                id={`current__popper__${(row?.sites?.[0]?.siteId || 'missing_id')}`}
                                className={styles.Popper__Label}
                                onMouseEnter={(event: any) => handleClick(event, (row?.sites?.[0]?.siteId || 'missing_id'), 'current')}
                              >
                                {' '}{row.sites.length > 1 && ` + ${row.sites.length - 1}`}
                              </span>
                              {
                                currentOpen === (row?.sites?.[0]?.siteId || 'missing_id') &&
                                <div
                                  ref={currentAnchorEl}
                                  className={`${styles.Popper__Wrapper}`}

                                  onMouseLeave={() => setCurrentOpen(null)}
                                  key={`current__popper__${uuidv4()}`}
                                  style={{
                                    top: `calc( 100% - ${getPreviousSiblingCoordinates(`current__popper__${(row?.sites?.[0]?.siteId || 'missing_id')}`)?.top} )`,
                                    marginTop: -16,
                                    //left: getPreviousSiblingCoordinates(`current__popper__${(row?.sites?.[0]?.siteId || 'missing_id')}`)?.left - 16 || 0,
                                  }}
                                >
                                  {row.sites.map((memberSite: any) => {
                                    return (
                                      <div
                                        key={`current__popper__site__${uuidv4()}`}
                                        className={`${styles.Popper__Wrapper__Item}`}
                                        onClick={(ev: any) => (ev.preventDefault(), ev.stopPropagation(), navigateToSite('site-economics', memberSite?.siteId))}
                                      >
                                        <p className={`${styles.Popper__Name}`}>{capitalizeFirstLetter(memberSite.siteName)}</p>
                                        <p className={`${styles.Popper__Text}`}>{memberSite.pod}</p>
                                      </div>
                                    )
                                  })}
                                </div>
                              }
                            </div>
                          </div>

                        </TableCell>

                        <TableCell
                          align='right'
                          className={`${!currentColumns?.rows?.[2]?.sortable === true && styles.Table__Cell__Static}`}
                          key={`current_members_table_row_cell_4_${uuidv4()}`}
                          component="th"
                          scope="row"
                        >
                          <div >
                            <label style={{ alignSelf: "flex-end" }} >
                              {formatHumanDate(row?.sites?.[0]?.startDate || '')}
                            </label>
                          </div>
                        </TableCell>


                      </TableRow>
                    ))}

                  </TableBody>

                  <TableFooter >
                    <TableRow>
                      <TableCell
                        align="left"
                        className={`${(currentColumns.rows[0].sortable && styles.Table__Footer__Cell__Clickable)}`}

                      >

                        <div
                          onClick={() => currentColumns.rows[0].sortable ? sortFutureBy('name') : null}
                          className={`${(currentColumns.rows[0].sortable && orderCurrent[0] === 'name') ? styles.Table__Footer__Cell__Active : styles.Table__Footer__Cell}`}
                        >
                          <span>
                            {parse(t(`widgets.tables.utils.${(currentRows?.length || 0) === 1 ? 'member' : 'members'}`, { members: (currentRows?.length || 0) }))}
                          </span>

                          {
                            (orderCurrent[0] === 'name')
                              ? orderCurrent[1] === 'asc'
                                ? <IconWrapper iconName='chevron-up' className={`${styles.Table__TH__Icon}`} />
                                : <IconWrapper iconName='chevron-down' className={`${styles.Table__TH__Icon}`} />
                              : <div style={{ height: 20, width: 19 }}></div>
                          }
                        </div>


                      </TableCell>
                      <TableCell>

                      </TableCell>
                      <TableCell align="left"></TableCell>
                    </TableRow>
                  </TableFooter>

                </Table>
              </TableContainer>

            </div>
            : <></>
          //   <div
          //   className={`${styles.Table} pb-3`}
          //   key={uuidv4()}
          // >
          //   <div className={`${styles.Table__Title}`}>
          //     {currentColumns.title}
          //   </div>
          //   { dataStore?.data?.members?.current?.lenght === 0 && <div> {currentColumns.no_data_text}</div>}
          // </div>
        }


        {
          previousRows && previousRows?.length > 0 ?
            <div
              className={`${styles.Table} ${styles.Table__Members} mb-3`}
              key={`${uuidv4()}`}
            >
              {previousColumns.title && <div className={`${styles.Table__Title}`}>
                {previousColumns.title}
              </div>}

              <TableContainer key={uuidv4()} component={Paper} className={`${previousColumns.title ? 'mt-1' : ''}`}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {previousColumns && previousColumns.rows.map((column: any, column_i: number) => (
                        <TableCell className={`
                      ${column.sortable ? styles.Table__Header__Cell__Sortable : styles.Table__Header__Cell__Static}
                    ${(column.sortable && orderPrevious[0] === column.field) ? styles.Table__Header__Cell__Active : styles.Table__Header__Cell}`
                        }
                          key={`previous_members_table_${column_i}_${uuidv4()}`}>
                          <TableSortLabel
                            active={column.sortable && orderPrevious[0] === column.field}
                            direction={orderPrevious[0] === column.field && orderPrevious[1] === 'asc' ? 'asc' : 'desc'}
                            onClick={() => column.sortable ? sortPreviousBy(column.field) : null}
                            className={`${styles.Table__Header__Cell} `}

                            IconComponent={() => (
                              (orderPrevious[0] === column.field)
                                ? orderPrevious[1] === 'asc'
                                  ? <IconWrapper iconName='chevron-up' className={`${styles.Table__TH__Icon}`} />
                                  : <IconWrapper iconName='chevron-down' className={`${styles.Table__TH__Icon}`} />
                                : <></>
                            )
                            }
                          >
                            <div > {column.title}</div>
                          </TableSortLabel>

                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody key={`${uuidv4()}`}>

                    {(previousRows || []).map((row) => (
                      <TableRow
                        key={`${Math.floor(Math.random() * 100)}__${uuidv4()}`}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        className={`${styles.Table__Body__Row__Clickable}`}
                        style={{ height: 100 }}
                        onClick={() => (window.getSelection()?.toString() || '')?.length < 4
                          ? navigateToMember( 'member', row?.memberId, )
                          : null
                        }
                      >
                        <TableCell
                          className={`${!previousColumns?.rows?.[0]?.sortable === true && styles.Table__Cell__Static}`}
                          key={`previous_members_table_row_cell_0_${uuidv4()}`}
                          component="th"
                          scope="row"
                        >
                          <div className={`${styles.Table__Cell__Member__Name}`}>
                            <span >{capitalizeFirstLetter(`${row.memberFirstName} ${row.memberLastName}`)}</span>
                            <label className={styles.Table__Cell__Member__ID}>{(row?.memberExternalId || 'missing_member_id')} </label>

                          </div>
                        </TableCell>

                        <TableCell
                          className={`${!previousColumns?.rows?.[1]?.sortable === true && styles.Table__Cell__Static}`}
                          key={`previous_members_table_row_cell_1_${uuidv4()}`}
                          component="th"
                          scope="row"
                        >
                          <div>
                            <label className={styles.Table__Cell__Member__Site__Label}>{(row?.sites?.[0]?.pod || 'missing_pod')} </label>

                            <div className={styles.Table__Cell__Member__Sites__Names}>
                              {capitalizeFirstLetter(row?.sites?.[0]?.siteName || 'missing name')}
                              <span
                                id={`previous__popper__${(row?.sites?.[0]?.siteId || 'missing_id')}`}
                                className={styles.Popper__Label}
                                onMouseEnter={(event: any) => handleClick(event, (row?.sites?.[0]?.siteId || 'missing_id'), 'previous')}
                              >
                                {' '}{row.sites.length > 1 && ` + ${row.sites.length - 1}`}
                              </span>
                              {
                                previousOpen === (row?.sites?.[0]?.siteId || 'missing_id') &&
                                <div
                                  ref={previousAnchorEl}
                                  className={`${styles.Popper__Wrapper}`}

                                  onMouseLeave={() => setPreviousOpen(null)}
                                  key={`previous__popper__${uuidv4()}`}
                                  style={{
                                    top: `calc( 100% - ${getPreviousSiblingCoordinates(`previous__popper__${(row?.sites?.[0]?.siteId || 'missing_id')}`)?.top} )`,
                                    marginTop: -16,
                                    //left: getPreviousSiblingCoordinates(`previous__popper__${(row?.sites?.[0]?.siteId || 'missing_id')}`)?.left - 16 || 0,
                                  }}
                                >
                                  {row.sites.map((memberSite: any) => {
                                    return (
                                      <div
                                        key={`previous__popper__site__${uuidv4()}`}
                                        className={`${styles.Popper__Wrapper__Item}`}
                                        onClick={(ev: any) => (ev.preventDefault(), ev.stopPropagation(), navigateToSite('site-economics', memberSite?.siteId))}
                                      >
                                        <p className={`${styles.Popper__Name}`}>{capitalizeFirstLetter(memberSite.siteName)}</p>
                                        <p className={`${styles.Popper__Text}`}>{memberSite.pod}</p>
                                      </div>
                                    )
                                  })}
                                </div>
                              }
                            </div>
                          </div>

                        </TableCell>

                        <TableCell
                          className={`${!previousColumns?.rows?.[2]?.sortable === true && styles.Table__Cell__Static}`}
                          key={`previous_members_table_row_cell_4_${uuidv4()}`}
                          component="th"
                          scope="row"
                          align='right'
                        >
                          <div>

                            <label style={{ alignSelf: "flex-end" }} >
                              {formatHumanDate(row?.sites?.[0]?.startDate || '')} - {formatHumanDate(row?.sites?.[0]?.endDate || '')}
                            </label>

                          </div>
                        </TableCell>


                      </TableRow>
                    ))}

                  </TableBody>



                  <TableFooter >
                    <TableRow>
                      <TableCell
                        align="left"
                        className={`${(previousColumns.rows[0].sortable && styles.Table__Footer__Cell__Clickable)}`}

                      >

                        <div
                          onClick={() => previousColumns.rows[0].sortable ? sortFutureBy('name') : null}
                          className={`${(previousColumns.rows[0].sortable && orderPrevious[0] === 'name') ? styles.Table__Footer__Cell__Active : styles.Table__Footer__Cell}`}
                        >
                          <span>
                            {parse(t(`widgets.tables.utils.${(previousRows?.length || 0) === 1 ? 'member' : 'members'}`, { members: (previousRows?.length || 0) }))}
                          </span>

                          {
                            (orderPrevious[0] === 'name')
                              ? orderPrevious[1] === 'asc'
                                ? <IconWrapper iconName='chevron-up' className={`${styles.Table__TH__Icon}`} />
                                : <IconWrapper iconName='chevron-down' className={`${styles.Table__TH__Icon}`} />
                              : <div style={{ height: 20, width: 19 }}></div>
                          }
                        </div>


                      </TableCell>
                      <TableCell>

                      </TableCell>
                      <TableCell align="left"></TableCell>
                    </TableRow>
                  </TableFooter>

                </Table>
              </TableContainer>

            </div>
            : <></>
          //   <div
          //   className={`${styles.Table} pb-3`}
          //   key={uuidv4()}
          //   >
          //     <div className={`${styles.Table__Title}`}>
          //     {previousColumns.title}
          //   </div>
          //   { dataStore?.data?.members?.previous?.lenght === 0 && <div> { previousColumns.no_data_text}</div>}
          // </div>
        }

      </>

  )



}

export default MembersTable;
