/*eslint no-sequences: "off"*/
/*eslint no-empty-pattern: "off"*/

import { createSlice } from "@reduxjs/toolkit";
import { IModules } from './interfaces';
import { initState } from "./init.state";
import { setUrl, moduleFilter } from '../../utils';

export const ModulesSlice = createSlice({
  name: "modules",
  initialState: initState,
  reducers: {
    ActivateModuleByName: (state: IModules, action: any) => {
      return {
        ...state,
        modules: state.modules.map((module) => ({
          ...module,
          visible: module.name === action.payload,
        })),
      };
    },
    SetActiveModule: (state: IModules, action: any) => {

      return {
        ...state,
        modules: state.modules.map((module) => ({
          ...module,
          visible: module.name === action.payload,
        })),
      };
    },
    ActivateLoginModule: (state: IModules ) => {
      // setUrl('/login');
      return {
        ...state,
        modules: state.modules.map((module) => ({
          ...module,
          visible: module.name === 'login',
        })),
      };
    },
    ActivateForgotPasswordModule: (state: IModules) => {
      // setUrl('/forgot-password');
      return {
        ...state,
        modules: state.modules.map((module) => ({
          ...module,
          visible: module.name === 'forgot-password',
        })),
      };
    },
    ActivateResetPasswordModule: (state: IModules, action: any) => {
      // setUrl(
        // `/password-reset?email=${action.payload.email}&recovery_code=${action.payload.recovery_code}`
      // );
      return {
        ...state,
        modules: state.modules.map((module) => ({
          ...module,
          visible: module.name === 'password-reset',
        })),
      };
    },
    ActivateRegisterModules: (state: IModules, action: any) => {
      // setUrl('/register');
      return {
        ...state,
        modules: state.modules.map((module) => ({
          ...module,
          visible: module.name === 'register',
        })),
      };
    },
    ActivateAccountCreationModules: (state: IModules, action: any) => {
      // setUrl('/account-creation');
      return {
        ...state,
        modules: state.modules.map((module) => ({
          ...module,
          visible: module.name === 'accountCreation',
        })),
      };
    },
    ActivateHouseholdModule: (state: IModules) => {
      // setUrl('household');
      return {
        ...state,
        modules: state.modules.map((module) => ({
          ...module,
          visible: module.name === 'household',
        })),
      };
    },
    ActivateHouseholdIdModule: (state: IModules, action: any) => {
      // setUrl(`household/${action.payload}`);
      return {
        ...state,
        modules: state.modules.map((module) => ({
          ...module,
          visible: module.name === 'household',
        })),
      };
    },
    ActivateCommunitiesModule: (state: IModules) => {
      // setUrl('communities');
      return {
        ...state,
        modules: state.modules.map((module) => ({
          ...module,
          visible: module.name === 'communities',
        })),
      };
    },
    ActivateMembersModule: (state: IModules, action: any) => {
      // setUrl('members');
      return {
        ...state,
        modules: state.modules.map((module) => ({
          ...module,
          visible: module.name === 'members',
        })),
      };
    },
    ActivateCommunityModule: (state: IModules, action: any) => {
      // setUrl(`community/${action.payload}`);
      return {
        ...state,
        modules: state.modules.map((module) => ({
          ...module,
          visible: module.name === 'community',
        })),
      };
    },
    ActivateMemberModule: (state: IModules, action: any) => {
      // setUrl(`member/${action.payload}`);
      return {
        ...state,
        modules: state.modules.map((module) => ({
          ...module,
          visible: module.name === 'member',
        })),
      };
    },
    NavigateToSiteContext: (state: IModules, action: any) => {
      // setUrl(`${action.payload.entity}/${action.payload.id}`);
      return {
        ...state,
        modules: state.modules.map((module) => ({
          ...module,
          visible: module.name === action.payload.entity,
        })),
      };
    },
    NavigateToCommunityContext: (state: IModules, action: any) => {
      // setUrl(`${action.payload.entity}/${action.payload.id}`);
      return {
        ...state,
        modules: state.modules.map((module) => ({
          ...module,
          visible: module.name === action.payload.entity,
        })),
      };
    },
    SetBreakPoint: (state: IModules, action: any) => {
      return {
        ...state,
        breakpoint: action.payload,
      };
    },
    ResetModules: (state: IModules, action: any) => {
      return {
        startState: state.startState,
        modules: state.startState,
        breakpoint: state.breakpoint,
      };
    },
    ActivateMemberEnergyModule: (state: IModules, action: any) => {
      return {
        startState: state.startState,
        oldState: state.modules,
        breakpoint: state.breakpoint,
        modules: state.modules.map((module) => ({
          ...module,
          visible: module.name === 'member-energy',
        }))
      }
    },
    ActivateMemberEconomicsModule: (state: IModules, action: any) => {
      return {
        startState: state.startState,
        oldState: state.modules,
        breakpoint: state.breakpoint,
        modules: state.modules.map((module) => ({
          ...module,
          visible: module.name === 'member-economics',
        }))
      }
    },
    LoadModules: (state: IModules, action: any) => {
      let filteredModules = moduleFilter(
        action.payload.modules,
        action?.payload?.userGroup || null,
        action?.payload?.siteType || null,
        action?.payload?.userType || null
      );

      const previousModule = state.modules.find(
        (module) => module.active === true && module.visible === true
      );

      if (previousModule) {
        filteredModules = filteredModules.map((module) => ({
          ...module,
          visible: module.name === previousModule.name,
        }));
      }

      return {
        startState: filteredModules,
        oldState: filteredModules,
        modules: filteredModules,
        breakpoint: state.breakpoint,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  SetActiveModule,
  ResetModules,
  LoadModules,
  ActivateLoginModule,
  ActivateForgotPasswordModule,
  ActivateResetPasswordModule,
  ActivateRegisterModules,
  ActivateAccountCreationModules,
  ActivateHouseholdModule,
  ActivateCommunitiesModule,
  ActivateCommunityModule,
  ActivateMemberModule,
  NavigateToSiteContext,
  NavigateToCommunityContext,
  ActivateMembersModule,
  SetBreakPoint,
  ActivateModuleByName,
  ActivateHouseholdIdModule,
  ActivateMemberEnergyModule,
  ActivateMemberEconomicsModule,
} = ModulesSlice.actions;

export default ModulesSlice.reducer;
