import { useTranslation } from "react-i18next";

import styles from './../alert.module.scss';
import Button from "../../button/button";
import { FirebaseService, httpService } from "@flexo/general";
import { AlertContext, AlertTypes, IRightDrawerTypes, RightDrawerContext, UserContext, UserTypes } from "@flexo/providers";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { RIGHT_DRAWER, USER } from "@flexo/reducers";

const ENV = (import.meta as any).env;

export function BannerCheckEmail() {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { alertStore, setAlertStore } = useContext(AlertContext);
  const bannerContent = t('banners.check_email', { returnObjects: true }) as any;
  const firebaseService = new FirebaseService();

  async function onClickDismiss() {



    const idToken = await firebaseService.getIdToken();

    if (!idToken) {
      setAlertStore({
        type: AlertTypes.SetAlert,
        payload: {
          value: {
            type: 'ALERT_SESSION_EXPIRED',
          },
        },
      });

      // setUserStore({
      //   type: UserTypes.SetUserLogOut,
      //   payload: {
      //     value: true,
      //   },
      // });

      dispatch( USER.SetLogout() )

      return;
    }

    const email = await firebaseService.getCurrentUserEmail();
    
    const resetResponse = await httpService.post({
      url: `${ENV.VITE_APP_BASE_URL}/auth/v1_1/password-forgot`,
      data: {
        email: email,
      },
      idToken: idToken,
    })
      .then( x => true )
      .catch((error) => {

        setAlertStore({
          type: AlertTypes.SetAlert,
          payload: {
            value: {
              type: 'ALERT_UNEXPECTED_ERROR'
            },
          }
        })

        return false

      })
    
    if (resetResponse === true) {
      dispatch( RIGHT_DRAWER.ResetDrawer() )

      firebaseService.signOut();

      window.location.href = `/forgot-password?email=${email}&page=2`;

    } else {
      setAlertStore({
        type: AlertTypes.SetAlert,
        payload: {
          value: {
            type: 'ALERT_UNEXPECTED_ERROR'
          },
        }
      })
    }



    return;

  }

  function goBack() {
    setAlertStore({
      type: AlertTypes.SetAlert,
      payload: {
        value: {
          type: 'BANNER_CONFIRM_CREDENTIALS_CHANGES',
          callback: alertStore?.alert?.callback ? () => (alertStore.alert as any).callback() : () => null,
          dismissCallback: alertStore?.alert?.dismissCallback ? () => (alertStore.alert as any).dismissCallback() : () => null,
        },
      },
    });
  }


  return (
    <div id="banner-cehck-email" className={styles.Banner__Check__Email}>

      <div className={styles.Banner__Title} >
        {bannerContent.title}
      </div>
      <div className={styles.Banner__Content} >
        {bannerContent.description}
        <br /> <br />
        {bannerContent.description_2}
      </div>

      <div className={styles.Banner__Footer__Actions} >
        <Button
          variant="secondary"
          className={`${styles.Banner__Footer__Actions__Button} ${styles.Button__End} ${styles.Banner__Footer__Actions__Button__Back}`}
          label={bannerContent.back}
          onClick={() => goBack()}
        />
        <Button
          variant="primary"
          className={`${styles.Banner__Footer__Actions__Button} ${styles.Button__End}`}
          label={bannerContent.buttonLabel}
          onClick={() => onClickDismiss()}
        />
      </div>

    </div>
  )
}