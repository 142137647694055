import { useContext, useEffect } from 'react';


import {
  AlertTypes,
  AlertContext,
} from '@flexo/providers';
import { FirebaseService, httpService } from '@flexo/general';
import { CALENDAR, METADATA,store,USER } from '@flexo/reducers';
import { _navigate } from './helpers';
import {  useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import * as luxon from 'luxon';

const ENV = (import.meta as any).env;

const firebaseService = new FirebaseService();

export const MemberResolver = () => {
  
  const params = useParams();
  const dispatch = useDispatch();
  const { setAlertStore } = useContext(AlertContext);
  const location = useLocation();
  const pathSegment = location.pathname.split('/')[1]; 


  async function MemberInit() {

    if (!pathSegment?.includes('member')) {
      return;
    }
  
    const idToken = await firebaseService.getIdToken();
  
    if (!idToken) {
      setAlertStore({
        type: AlertTypes.SetAlert,
        payload: {
          value: {
            type: 'ALERT_SESSION_EXPIRED',
          },
        },
      });
  
      dispatch(USER.SetLogout())
  
      return;
    }
  
    const [member] = await Promise.all([
      httpService.get({
        // url: `/hive/api/catalog/v1/catalog/community/${globalDataStore.data?.communityID}/members/${globalDataStore.navigation?.id}`,
        url: `${ENV.VITE_APP_BASE_URL}/hive/api/catalog/v1_3/member/${params.id}`,
        // /hive/api/catalog/v1_3/member/{memberId}
        idToken,
      })
        .then((res: any) => {
          if (res.status === 200) {
            return res.data;
          } else {
            // console.warn({
            //   status: res.status,
            //   message: res.statusText
            // });
            return null
          }
        })
  
        .catch((err: any) => {
          // console.warn(err);
  
          setAlertStore({
            type: AlertTypes.SetAlert,
            payload: {
              value: {
                type: 'ALERT_UNEXPECTED_ERROR'
              },
            }
          })
        }),
      
    ]
    );

    dispatch(METADATA.SetSelectedEntity({
      selectedEntity: member,
      id: member?.memberId,
      entityType: 'member',
    } as any) )
  }

  useEffect(() => {
      MemberInit();
  }, [ params.id ]);

  return <></>;
};

export function navigateToMember(context = 'member', memberID: string) {

  const dispatch = store.dispatch;
  
    if (ENV.VITE_APP_NAME === 'admin' && context === 'member') {
  
      const D = luxon.DateTime.now().startOf('day').toJSDate();
      const D2 = luxon.DateTime.now().plus({days: 1}).startOf('day').toJSDate();
  
      dispatch(CALENDAR.setHiveDate({
        date: [D, D2],
        timeSet: 'day'
      } as any) )
    }


  _navigate(`/${context}/${memberID}`);

  // window.location.href = (`/${context}/${memberID}`);

  
}
