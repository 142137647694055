import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { CalendarContext, KpiApiContext, MetaDataApiContext, ModulesContext, ThemeContext } from '@flexo/providers';

import { v4 as uuidv4 } from 'uuid';

import styles from './horizontal-chart.module.scss';
import HorizontalChart from './horizontal-chart';
import { navigateToMember, navigateToSite } from '@flexo/atoms';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

export default function HorizontalChartWrapper(props: any) {

  const { t } = useTranslation();
  const location = useLocation();
  const pathSegment = location.pathname.split('/')[1]; 
  const { ThemeStore } = useContext(ThemeContext)
  const metadata = useSelector((state: any) => state.metadata);
  const kpi = useSelector((state: any) => state.kpi);


  const [activeKeys, setActiveKeys] = useState<string[]>(props.graphKeys);
  const [data, setData] = useState<any>([]);

  let rawData = kpi?.communityRanking || null;

  if (pathSegment?.includes('member')) {
    rawData = kpi?.memberRanking || null
  }

  function handleNavigation(line: any) {

    if (pathSegment?.includes('member')) {
      navigateToMember(
        props?.context === 'energy' ? 'member-energy' : props?.context === 'economics' ? 'member-economics' : 'member',
        line?.memberId,
      )
    } else {
      navigateToSite(
        props?.context === 'energy' ? 'site-energy' : props?.context === 'economics' ? 'site-economics' : 'site',
        line?.siteId,
      )
    }

  }

  const getItemsList = () => {

    const sitesList = ((metadata?.entities as any)?.site) || [];

    if (props?.context === 'energy') {

      if (
        rawData?.consumptionRanking?.[0]?.site &&
        rawData?.productionRanking?.[0]?.site
      ) {
        const sites = rawData?.consumptionRanking?.[0]?.site
          .map((_site: any) => ({
            ..._site,
            ...sitesList.find((entitiesSsite: any) => entitiesSsite.siteId === _site.siteId),
          }))
          .sort((a, b) => a?.ranking > b?.ranking ? 1 : -1)
        .filter(  (_, i) => i < 5)

        return sites.map((site: any) => ([
          site,
          rawData?.productionRanking?.[0]?.site
            .find((_site: any) => _site.siteId === site?.siteId)
        ]))
      }

    } else if (props?.context === 'economics') {

      return rawData?.economicsRanking?.[0]?.site
        .map((_site: any) => ({
          ..._site,
          ...sitesList.find((entitiesSsite: any) => entitiesSsite.siteId === _site.siteId),
        })).sort((a, b) => a?.ranking > b?.ranking ? 1 : -1)

    }

    return []
  }

  function getKpiValue(item: any) {
    const kpi = item?.kpi?.find((datum: any) => props?.rankingKpi === datum.name);


    const value = kpi?.value || 0;
    const unit = kpi?.unit || '';
    return props?.context === 'economics' ? `${unit} ${value}` : `${value} ${unit}`;
  }


  // const handleButtonClick = (key: string) => {
  //   if (props.combinedKeys?.includes(key)) {
  //     // Se fa parte delle combinedKeys, attiva/disattiva entrambe
  //     const combinedKeyIndex = props.combinedKeys.indexOf(key);
  //     const combinedKey = props.combinedKeys[combinedKeyIndex + 1];

  //     setActiveKeys((prev) =>
  //       prev.includes(key)
  //         ? prev.filter((k) => k !== key && k !== combinedKey)
  //         : [...prev, key, combinedKey]
  //     );
  //   } else {
  //     // Se non fa parte delle combinedKeys, attiva/disattiva normalmente
  //     setActiveKeys((prev) =>
  //       prev.includes(key)
  //         ? prev.filter((k) => k !== key)
  //         : [...prev, key]
  //     );
  //   }
  // };

  const handleButtonClick = (key: string) => {

    if (activeKeys.length === 1 && activeKeys.includes(key)) {
      return;
    }

    if (activeKeys.length === props?.combinedKeys?.length && activeKeys?.includes(key) && props?.combinedKeys?.includes(key)) {
      return;
    }

    if (props.combinedKeys.includes(key)) {
      // Trova tutte le chiavi nel gruppo combinato
      const combinedKeyGroup = props.combinedKeys;

      // Verifica se tutte le chiavi del gruppo sono attive
      const areAllKeysActive = combinedKeyGroup.every(k => activeKeys.includes(k));

      setActiveKeys((prev) =>
        (areAllKeysActive
          ? prev.filter(k => !combinedKeyGroup.includes(k))
          : [...prev, ...combinedKeyGroup.filter(k => !prev.includes(k))])
          .filter(x => x)
      );
    } else {

      setActiveKeys((prev) =>
        (prev.includes(key)
          ? prev.filter((k) => k !== key)
          : [...prev, key])
          .filter(x => x)
      );
    }
  };

  useEffect(() => {

    if (!rawData) { return }

    const _data = getItemsList();

    if (JSON.stringify(_data) !== JSON.stringify(data)) {
      setData(_data);
    }

  }, [rawData])






  return (
    <div id="HorizontalChart__Wrapper" className={styles.HorizontalChart__Wrapper}>
      <div className={styles.HorizontalChart__Title}>
        {t(props.title)} {props?.context}

      </div>
      <div className={styles.HorizontalChart__Buttons__Wrapper}>
        {
          props.buttons.map((button: any, index: number) => {
            return (
              <div
                key={`HorizontalChart__${index}__button__${button}`}
                className={`
                    ${styles.HorizontalChart__Button}
                    ${props?.combinedKeys.includes(props.graphKeys[index])
                    ? styles.HorizontalChart__Button__Touch
                    : ''
                  }
                    ${!activeKeys?.includes(props.graphKeys[index])
                    ? styles.HorizontalChart__Button__Disabled
                    : ''
                  }
                  `}
                onClick={() => handleButtonClick(props.graphKeys[index])}
              >
                {t(button)}
                <div className={styles.HorizontalChart__Button__Underline} style={{ backgroundColor: `${ThemeStore?.colors[props.colors[index].color]}${props.colors[index].colorShade}` }}></div>
              </div>
            )


          })
        }
      </div>

      {
        props?.context === 'energy'
          ? data.map((line: any, lineIndex: number) => {
            return line.map((item: any, index: number) => {
              <div key={uuidv4()} className={styles.HorizontalChart__Wrapper__Line}>

                <div className={styles.HorizontalChart__Wrapper__Line__Label} onClick={() => handleNavigation(line)}>
                  {
                    item?.pod
                  }
                </div>
                <div className={styles.HorizontalChart__Wrapper__Line__Rank}>
                  <div>
                    <p className={styles.HorizontalChart__Wrapper__Line__Rank__Value}>{getKpiValue(item)}</p>
                    <p className={styles.HorizontalChart__Wrapper__Line__Rank__Name}> {t(props?.rankingKpiName[lineIndex])}</p>

                  </div>
                  <HorizontalChart data={{
                    kpi: item?.kpi,
                    ...props,
                    activeKeys
                  }} />
                </div>

              </div>
            })
          })

          : (data || []).map((item: any, index: number) => {
            return <div key={uuidv4()} className={styles.HorizontalChart__Wrapper__Line}>
              <div className={styles.HorizontalChart__Wrapper__Line__Label} onClick={() => handleNavigation(item)}>
                {
                  item?.pod
                }

                {props?.context === 'economics' && <span className="font-flexo-light">&nbsp; {item?.siteName}</span>}
              </div>
              <div className={styles.HorizontalChart__Wrapper__Line__Rank}>
                <div>
                  <p className={styles.HorizontalChart__Wrapper__Line__Rank__Value}>{getKpiValue(item)}</p>
                  <p className={styles.HorizontalChart__Wrapper__Line__Rank__Name}> {t(props?.rankingKpiName)}</p>

                </div>
                <HorizontalChart data={{
                  kpi: item?.kpi,
                  ...props,
                  activeKeys
                }} />
              </div>

            </div>
          })
      }

    </div>
  )

}