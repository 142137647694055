
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";


import { CALENDAR } from "@flexo/reducers";

import { HiveCalendarInput } from "./hive-calendar-input";
import { HiveCalendarInnerInput } from "./hive-calendar-inner-input";
import { HiveCalendarContentDay } from "./hive-calendar-content-day";
import { HiveCalendarContentWeek } from "./hive-calendar-content-week";
import { HiveCalendarContentMonth } from "./hive-calendar-content-month";
import { HiveCalendarContentYear } from "./hive-calendar-content-year";

import styles from './hive-calendar.module.scss';
import { useOnClickOutside } from "@flexo/hooks";

export function HiveCalendar() {

  const calendarRef = useRef(null);
  
  const dispatch = useDispatch();
  const calendar = useSelector((state: any) => state.calendar);
  const hiveCalendar = calendar.hiveCalendar;
  const {
    selectedTimeSet,
    calendarTimeSet,
    calendarNavigationActive,
    navigationStart,
  } = hiveCalendar;

  const [showCalendar, setShowCalendar] = useState(false);

  const timeSet = calendarTimeSet || selectedTimeSet;

  function getCalendarContent() {
    switch (timeSet) {
      case 'day':
        return <HiveCalendarContentDay {...{ setShowCalendar }}/>
      case 'week':
        return <HiveCalendarContentWeek {...{ setShowCalendar }}/>
      case 'month':
        return <HiveCalendarContentMonth {...{ setShowCalendar }}/>
      case 'year':
        return <HiveCalendarContentYear {...{ setShowCalendar }}/>
      default:
        return <HiveCalendarContentDay {...{ setShowCalendar }}/>
    }
  }

  function checkActiveTimeSet(_timeSet: string) {

    if (calendarNavigationActive === false) {
      return _timeSet === timeSet;
    } else {
      return _timeSet === navigationStart;
    }
  }

  function isChildOf(event) {

    const parentDiv = document.getElementById('HiveCalendar')
    let targetElement = event.target; // L'elemento che è stato cliccato

    // Itera attraverso i genitori dell'elemento target fino a raggiungere il body
    while (targetElement !== document.body) {
        if (targetElement === parentDiv) {
            return true; // Il target è figlio del parentDiv
        }
        targetElement = targetElement.parentNode; // Vai al genitore dell'elemento target
    }

    return false; // Il target non è figlio del parentDiv
  }

  function SetTimeSet(newTimeSet: string) {
    dispatch( CALENDAR.setHiveCalendarNavigationInactive() ) 
    dispatch( CALENDAR.setHiveCalendarTimeSet( newTimeSet as any ) ) 
    dispatch( CALENDAR.setHiveCalendarNavigationStart( newTimeSet as any ) ) 
  }

  useOnClickOutside(calendarRef, (ev: any) => {    
    if ( isChildOf(ev) === true) { return }
    if (showCalendar === true) {
      setShowCalendar( false )
    }
  }
  );

  

  return <div className={styles.HiveCalendar__Wrapper} >

    <HiveCalendarInput {...{ setShowCalendar, showCalendar }} />

    {
      showCalendar &&
      <div id="HiveCalendar" className={styles.HiveCalendar__Calendar} ref={ calendarRef } >

        <div className={styles.HiveCalendar__Calendar__Buttons__Wrapper}>
          <button 
            className={`
            ${styles.HiveCalendar__Calendar__Buttons__Button}
            ${ checkActiveTimeSet('day') ? styles.HiveCalendar__Calendar__Buttons__Button__Active : '' }
            `} onClick={ () => SetTimeSet('day')}>
              Day
            </button>
          <button 
            className={`
            ${styles.HiveCalendar__Calendar__Buttons__Button}
            ${ checkActiveTimeSet('week') ? styles.HiveCalendar__Calendar__Buttons__Button__Active : '' }
            `} onClick={ () => SetTimeSet( 'week' as any ) }>
              Week
            </button>
          <button 
            className={`
            ${styles.HiveCalendar__Calendar__Buttons__Button}
            ${ checkActiveTimeSet('month') ? styles.HiveCalendar__Calendar__Buttons__Button__Active : '' }
            `} onClick={ () => SetTimeSet( 'month' as any )  }>
              Month
            </button>
          <button 
            className={`
            ${styles.HiveCalendar__Calendar__Buttons__Button}
            ${ checkActiveTimeSet('year') ? styles.HiveCalendar__Calendar__Buttons__Button__Active : '' }
            `} onClick={ () => SetTimeSet( 'year' as any ) }>
              Year
            </button>
          </div>

        <HiveCalendarInnerInput />

        {
          getCalendarContent()
        }



      </div>}


  </div>

}