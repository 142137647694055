//save as site: {
// 1day:{}
//1hour:{}
// }
//event driven development
import { useContext, useEffect } from 'react';

import { AlertContext, AlertTypes } from '@flexo/providers';

import {
  FirebaseService,
  formatDate,
  formatEndOfWeek,
  formatStringToDate,
  getFormattedEndOfMonth,
  getFormattedNextDay,
  getLastDayOfYear,
  getTimeGranularity,
  getTimeGranularityOverview,
  httpService,
} from '@flexo/general';
import { useDispatch, useSelector } from 'react-redux';
import { KPI, USER } from '@flexo/reducers';
import { useParams } from 'react-router-dom';

const ENV = (import.meta as any).env;

const firebaseService = new FirebaseService();

export const KpiCommunityResolver = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const metadata = useSelector((state: any) => state.metadata);
  const calendar = useSelector((state: any) => state.calendar);
  const kpi = useSelector((state: any) => state.kpi);

  const { setAlertStore } = useContext(AlertContext);

  const { hiveCalendar } = calendar;
  const { selectedDate, selectedTimeSet } = hiveCalendar;

  async function init() {
    dispatch(KPI.SetLoading(true as any as any));

    const idToken = await firebaseService.getIdToken();

    if (!idToken) {
      setAlertStore({
        type: AlertTypes.SetAlert,
        payload: {
          value: {
            type: 'ALERT_SESSION_EXPIRED',
          },
        },
      });

      // setUserStore({
      //   type: UserTypes.SetUserLogOut,
      //   payload: {
      //     value: true,
      //   },
      // });

      dispatch(USER.SetLogout());

      return;
    }

    const COMMUNITY_ID = params?.id;

    if (!COMMUNITY_ID) {
      return;
    }

    const [kpiCommunityOverview, kpiComparisonResponse, kpiCommunityDetail] =
      await Promise.all([
        httpService
          .get({
            url: `${ENV.VITE_APP_BASE_URL}/hive/api/kpi/v1_2/community/${COMMUNITY_ID}`,
            params: {
              dateFrom: formatDate(selectedDate[0]),
              dateTo: formatDate(selectedDate[1]),
              //add null: null to test the error
              timeGranularity: getTimeGranularityOverview(
                selectedTimeSet
              ),
              locale: ENV?.VITE_APP_LOCALE || 'it-CH',
            },
            // ${ENV.VITE_APP_BASE_URL}//hive/api/kpi/v1_2/community/${metaDataApiStore.selectedEntityId}
            idToken,
          })
          .then((res: any) => {
            if (res.status === 200) {
              return res.data;
            } else {
              return null;
            }
          })
          .catch((err: any) => {
            // console.warn(err);

            // setAlertStore({
            //   type: AlertTypes.SetAlert,
            //   payload: {
            //     value: {
            //       type: 'ALERT_UNEXPECTED_ERROR',
            //     },
            //   },
            // });

            return null;
          }),

        httpService
          .post({
            url: `${ENV.VITE_APP_BASE_URL}/hive/api/kpi/v1_2/siteCommunity/comparison/${COMMUNITY_ID}`,
            data: {
              dateFrom: formatDate(selectedDate[0]),
            dateTo: formatDate(selectedDate[1]),
              timeGranularity: getTimeGranularityOverview(
                selectedTimeSet
              ),
              locale: ENV?.VITE_APP_LOCALE || 'it-CH',
              siteId:
                ((metadata?.entities as any)?.site || [])?.map(
                  (site: any) => site.siteId
                ) || [],
            },
            idToken,
          })
          .then((res: any) => {
            if (res.status === 200) {
              return res.data;
            } else {
              // console.warn({
              //   status: res.status,
              //   message: res.statusText,
              // });
              return null;
            }
          })
          .catch((err: any) => {
            // setAlertStore({
            //   type: AlertTypes.SetAlert,
            //   payload: {
            //     value: {
            //       type: 'ALERT_UNEXPECTED_ERROR',
            //     },
            //   },
            // });

            return null;
          }),

        // New endpoint request
        httpService
          .get({
            url: `${ENV.VITE_APP_BASE_URL}/hive/api/kpi/v1_2/community/${COMMUNITY_ID}`,
            params: {
              dateFrom: formatDate(selectedDate[0]),
            dateTo: formatDate(selectedDate[1]),
              timeGranularity: getTimeGranularity(
                selectedTimeSet,
                null
              ),
              locale: ENV?.VITE_APP_LOCALE || 'it-IT',
            },
            idToken,
          })
          .then((res: any) => {
            if (res.status === 200) {
              return res.data;
            } else {
              // console.warn({
              //   status: res.status,
              //   message: res.statusText,
              // });
              return null;
            }
          })
          .catch((err: any) => {
            // setAlertStore({
            //   type: AlertTypes.SetAlert,
            //   payload: {
            //     value: {
            //       type: 'ALERT_UNEXPECTED_ERROR',
            //     },
            //   },
            // });

            return null;
          }),
      ]);

    if (kpi?.SetKpiDataCommunityAsyncStatus !== 'PENDING') {
      dispatch(
        KPI.SetKpiDataCommunityAsync({
          '1day': kpiCommunityOverview,
          '1hour': kpiCommunityDetail,
          comparison: kpiComparisonResponse,
          isLoading: false,
        } as any) as any
      );
    }
  }

  useEffect(() => {
    init();
  }, [params.id, selectedDate]);

  return <></>;
};
