import { useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import styles from './site-details-parameters.module.scss';

import { useTranslation } from 'react-i18next';
import { MetaDataApiContext } from '@flexo/providers'
import { capitalizeFirstLetter } from '@flexo/general';
import { timeFormat } from 'd3-time-format';
import { useSelector } from 'react-redux';




export function SiteDetailsParameters(props: any) {

  const { t } = useTranslation();
  const metadata = useSelector((state: any) => state.metadata);

  const { title, siteName, joinDate, ownership, type, productionBenefits } = props;

  const formatDate = timeFormat('%d/%m/%Y');

  const {
    Details__Wrapper__Compact,
    Details__Site__Title,
    Details__Paper,
    Details__Site__Name,
    Details__Site__Content,
    Details__Site__Content__Name,
  } = styles

  return (
    <div
      key={uuidv4()}
      className={`
      ${Details__Wrapper__Compact}
      `}
    >

      <div className={`${Details__Paper}`}>
        <p className={Details__Site__Title}>{t(title)}</p>
        <p className={Details__Site__Name}>{(metadata?.selectedEntity as any)?.pod || ''}</p>

        <div>

          <div className={'flex flex-column'} >
            <span className={Details__Site__Content}>
            {(metadata?.selectedEntity as any)?.siteName || ''}
            </span>
            <span className={Details__Site__Content__Name}>
              {t(siteName)}
            </span>
          </div>

          <div className={'flex flex-column'} >
            <span className={Details__Site__Content}>
              { formatDate(new Date((metadata?.selectedEntity as any)?.joinDate || (metadata?.selectedEntity as any)?.startDate || '')) } - { new Date((metadata?.selectedEntity as any)?.leaveDate || '') <= new Date() ? formatDate( new Date((metadata?.selectedEntity as any)?.leaveDate)) : '' }
            </span>
            <span className={Details__Site__Content__Name}>
              {t(joinDate)}
            </span>
          </div>
          
          {/* <div className={'flex flex-column'} >
            <span className={Details__Site__Content}>

            </span>
            <span className={Details__Site__Content__Name}>
              {t(ownership)}
            </span>
          </div> */}
          
          <div className={'flex flex-column'} >
            <span className={Details__Site__Content}>
            { t( `widgets.siteDetailsParameters.${ (metadata?.selectedEntity as any)?.siteType?.[0]?.siteType || 'default' }` )}
            </span>
            <span className={Details__Site__Content__Name} style={{ marginBottom: 0 }}>
              {t(type)}
            </span>
          </div>
          
          {/* <div className={'flex flex-column'} >
            <span className={Details__Site__Content}>

            </span>
            <span className={Details__Site__Content__Name}>
              {t(productionBenefits)}
            </span>
          </div> */}

        </div>
      </div>

    </div>
  )
}

export default SiteDetailsParameters;
