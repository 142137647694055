import { useContext, useEffect } from 'react';

import {
  AlertContext,
  AlertTypes,
} from '@flexo/providers';


import {
  FirebaseService,
  formatDate,
  getTimeGranularityOverview,
  httpService,
} from '@flexo/general';
import { useDispatch, useSelector } from 'react-redux';
import { KPI, USER } from '@flexo/reducers';
import { useParams } from 'react-router-dom';

const ENV = (import.meta as any).env;

const firebaseService = new FirebaseService();

export const CommunityRankingResolver = () => {
  
  const dispatch = useDispatch();
  const params = useParams();
  const calendar = useSelector((state: any) => state.calendar);

  const { setAlertStore } = useContext(AlertContext);

  const { hiveCalendar } = calendar;
  const { selectedDate, selectedTimeSet } = hiveCalendar

  async function init() {

    const idToken = await firebaseService.getIdToken();

    if (!idToken) {
      setAlertStore({
        type: AlertTypes.SetAlert,
        payload: {
          value: {
            type: 'ALERT_SESSION_EXPIRED'
          },
        },
      })

      dispatch( USER.SetLogout())

      return;
    }

    const [consumptionRanking, productionRanking, economicsRanking] = await Promise.all([
      httpService
        .post({
          url: `${ENV.VITE_APP_BASE_URL}/hive/api/kpi/v1_2/siteCommunity/ranking/${params.id}`,
          data: {
            dateFrom: formatDate(selectedDate[0]),
            dateTo: formatDate(selectedDate[1]),
            timeGranularity: getTimeGranularityOverview(
              selectedTimeSet
            ),
            locale: ENV?.VITE_APP_LOCALE || 'it-CH',
            kpiList: [
              'self_consumption_direct',
              'self_consumption_battery',
              'from_shared',
              'from_grid',
            ],
            rankingKpi: 'consumption',
          },
          // ${ENV.VITE_APP_BASE_URL}//hive/api/kpi/v1_2/community/${metaDataApiStore.selectedEntityId}
          idToken,
        })
        .then((res: any) => {
          if (res.status === 200) {
            return res.data;
          } else {
            // console.warn({
            //   status: res.status,
            //   message: res.statusText,
            // });
            return null;
          }
        })
        .catch((err: any) => {
          // console.warn(err);

          // setAlertStore({
          //   type: AlertTypes.SetAlert,
          //   payload: {
          //     value: {
          //       type: 'ALERT_UNEXPECTED_ERROR'
          //     },
          //   }
          // })

          return null;
        }),
      httpService
        .post({
          url: `${ENV.VITE_APP_BASE_URL}/hive/api/kpi/v1_2/siteCommunity/ranking/${params.id}`,
          data: {
            dateFrom: formatDate(selectedDate[0]),
            dateTo: formatDate(selectedDate[1]),
            timeGranularity: getTimeGranularityOverview(
              selectedTimeSet
            ),
            locale: ENV?.VITE_APP_LOCALE || 'it-CH',
            kpiList: [
              'self_consumption_direct',
              'production_to_battery',
              'to_shared',
              'to_grid',
            ],
            rankingKpi: 'production',
          },
          // ${ENV.VITE_APP_BASE_URL}//hive/api/kpi/v1_2/community/${metaDataApiStore.selectedEntityId}
          idToken,
        })
        .then((res: any) => {
          if (res.status === 200) {
            return res.data;
          } else {
            // console.warn({
            //   status: res.status,
            //   message: res.statusText,
            // });
            return null;
          }
        })
        .catch((err: any) => {
          // console.warn(err);

          // setAlertStore({
          //   type: AlertTypes.SetAlert,
          //   payload: {
          //     value: {
          //       type: 'ALERT_UNEXPECTED_ERROR'
          //     },
          //   }
          // })

          return null;
        }),
      httpService
        .post({
          url: `${ENV.VITE_APP_BASE_URL}/hive/api/kpi/v1_2/siteCommunity/ranking/${params.id}`,
          data: {
            dateFrom: formatDate(selectedDate[0]),
            dateTo: formatDate(selectedDate[1]),
            timeGranularity: getTimeGranularityOverview(
              selectedTimeSet
            ),
            locale: ENV?.VITE_APP_LOCALE || 'it-CH',
            rankingKpi: 'revenues',
            kpiList: ['revenues']
          },
          // ${ENV.VITE_APP_BASE_URL}//hive/api/kpi/v1_2/community/${metaDataApiStore.selectedEntityId}
          idToken,
        })
        .then((res: any) => {
          if (res.status === 200) {
            return res.data;
          } else {
            // console.warn({
            //   status: res.status,
            //   message: res.statusText,
            // });
            return null;
          }
        })
        .catch((err: any) => {
          // console.warn(err);

          // setAlertStore({
          //   type: AlertTypes.SetAlert,
          //   payload: {
          //     value: {
          //       type: 'ALERT_UNEXPECTED_ERROR'
          //     },
          //   }
          // })

          return null;
        }),
      
    ]);

    if (consumptionRanking && economicsRanking) {

      dispatch(KPI.SetKpiDataCommunityRanking({
        consumptionRanking: consumptionRanking || [],
        productionRanking: productionRanking || [],
        economicsRanking: economicsRanking || [],
      } as any) )
    }

  }

  useEffect(() => {
      init();
  }, []);

  useEffect(() => {
      init();
  }, [selectedDate]);

  return <></>;
};
