import { DateTime } from "luxon";
import { ICalendar } from "./interfaces";

const ENV = (import.meta as any).env;

const currentDate = DateTime.now();
const currentHour = currentDate.hour;
const currentMinutes = currentDate.minute;

let prevSelectedDate = currentDate.minus({ days: 1 }).toJSDate();
let selectedDate = currentDate.minus({ days: 1 }).toJSDate();

if ( currentHour < 10 && currentMinutes < 30 ) {
  prevSelectedDate = currentDate.minus({ days: 2 }).toJSDate();
  selectedDate = currentDate.minus({ days: 2 }).toJSDate();
} 

const nextDay = currentDate.plus({ days: 1 }).toJSDate();


export const initState: ICalendar = {
  calendarDisplay: ENV?.VITE_APP_DEFAULT_TIMESET || 'year',
  selectedDate: ENV?.VITE_APP_NAME === 'admin' ? new Date() : new Date('2024-01-01'),
  selectedWeek: {
    startDate: null,
    endDate: null,
  },
  showCalendar: false,
  customInteraction: undefined,
  previousCalendarDisplay: undefined,
  currentlySelectedDateType: ENV?.VITE_APP_DEFAULT_TIMESET || 'year',
  showCustomInteraction: {
    day: false,
    week: false,
    month: false,
  },
  prevSelectedDate: prevSelectedDate,
  buttonSwitch: false,
  hiveCalendar: {

    selectedDate:    ENV?.VITE_APP_NAME === 'admin' ? [ new Date(), nextDay] : [new Date('2024-01-01'), new Date('2025-12-01') ],
    selectedTimeSet: ENV?.VITE_APP_DEFAULT_TIMESET || 'year',
    calendarTimeSet: null,
    calendarDate: null,
    calendarNavigationActive: false,
    navigationStart: null,
  }
};