import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import { IDropdown } from '@flexo/atoms';
import { dropdownOption, getLastNineDigits } from '@flexo/general';
import { IconWrapper } from '@flexo/general';
import styles from './dropdown.module.scss';
import { useOnClickOutside } from '@flexo/hooks';
import {
  SiteViewContext,
  Types,
  CommunityViewContext,
  CommunityTypes,
  ThemeContext,
  ThemeTypes,
  DataContext,
  MetaDataApiContext,
  MetaDataApiTypes,
} from '@flexo/providers';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { METADATA } from '@flexo/reducers';
import { IonItem, IonList } from '@ionic/react';

const Dropdown: React.FC<IDropdown> = ({
  placeholder,
  style,
  view = 'household',
}) => {
  const {
    Dropdown,
    Dropdown__Input,
    Dropdown__Label,
    Dropdown__Options,
    Dropdown__Options__Option,
    Dropdown__Options__Option__Label,
    Dropdown__Options__Option__Description,
    Dropdown__Icon,
    Dropdown__Icon__open,
    Dropdown__Label__Ghost,
    Dropdown__Options__Option__Community,
  } = styles;
  const [showDropdown, setShowDropdown] = useState(false);
  const { siteViewStore, setSiteViewStore } = useContext(SiteViewContext);
  const { communityViewStore, setCommunityViewStore } =
    useContext(CommunityViewContext);
  const dispatch = useDispatch();
  
  const metadata = useSelector((state: any) => state.metadata);
  const { sites } = metadata?.entities || {};
  const { selectedEntityId } = metadata || {};
  

  const { communities, selectedCommunity } = communityViewStore;
  const [filteredSites, setFilteredSites] = useState<any>(sites);
  const [filteredCommunities, setFilteredCommunities] =
    useState<any>(communities);
  const [disabled, setDisabled] = useState(true);

  const { setThemeStore } = useContext(ThemeContext);

  const [_selectedSite, _SetSelectedSite] = useState<any>(
    (selectedEntityId as any)?.siteId
  );
  const [_selectedCommunity, _SetSelectedCommunity] = useState<any>(
    // selectedCommunity[0]?.community.community_name
    ''
  );

  const _selectedLabel = selectedEntityId || sites[0].siteName;
  // view === 'household'
  //   ? _selectedSite
  //     ? selectedSite[0]?.site_name
  //     : ''
  //   : _selectedCommunity
  //   ? selectedCommunity[0]?.community?.community_name
  //   : '';

  const [inputValue, setInputValue] = useState(_selectedLabel);

  const [inputPlaceHolder, setInputPlaceHolder] = useState(
    _selectedLabel ?? placeholder
  );

  const inputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  const menuRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(menuRef, (event: any) => {
    if (event?.target?.className?.includes('Dropdown') && showDropdown) {
      return;
    } else {
      setShowDropdown(false);
      setThemeStore({
        type: ThemeTypes.TraceDropDown,
        payload: {
          value: false,
        },
      });
    }
  });

  const handleClick = (option: dropdownOption) => {
    const label: string =
      option?.label || inputPlaceHolder || _selectedLabel || placeholder;

    // if (view === 'community') {
    //   if (selectedCommunity[0].flexo_community_id === option.id) {
    //     return;
    //   }

    //   setCommunityViewStore({
    //     type: CommunityTypes.SetSelectedCommunity,
    //     payload: {
    //       value: {
    //         id: option.id as string,
    //         label: option.label,
    //       } as any,
    //     },
    //   });

    //   const communitySite: any = sites.find(
    //     (site) => site.site_communities[0].flexo_community_id === option.id
    //   );

    //   setSiteViewStore({
    //     type: Types.SetSelectedSite,
    //     payload: {
    //       value: {
    //         id: communitySite.flexo_site_id as string,
    //         label: communitySite.site_name,
    //       },
    //     },
    //   });
    // }

    if (view === 'household') {
      if (_selectedSite[0].flexo_site_id === option.id) {
        return;
      }

      setSiteViewStore({
        type: Types.SetSelectedSite,
        payload: {
          value: {
            id: option.id as string,
            label: option.label,
          } as any,
        },
      });

      const community: any = sites.find(
        (site) => site.flexo_site_id === option.id
      )?.site_communities[0];

      setCommunityViewStore({
        type: CommunityTypes.SetSelectedCommunity,
        payload: {
          value: {
            id: community?.flexo_community_id as string,
            label: (community?.community as any)?.community_name,
          } as any,
        },
      });
    }

    setInputPlaceHolder(label);
    setShowDropdown(false);
    setThemeStore({
      type: ThemeTypes.TraceDropDown,
      payload: {
        value: false,
      },
    });
  };

  const getFormattedItems = () => {
    const id = view === 'household' ? 'flexo_site_id' : 'flexo_community_id';
    const name = view === 'household' ? 'site_name' : 'community_name';
    const list = view === 'household' ? filteredSites : filteredCommunities;
    return list?.map((item) => {
      return {
        label: item?.community?.[name] || item?.[name],
        id: item?.[id],
        description:
          view === 'household'
            ? `${
                item?.site_communities[0]?.community?.community_name
              }: ${getLastNineDigits(item?.pod)}`
            : '',
      };
    });
  };

  const handleDropdownClick = (e: any) => {
    const list = view === 'household' ? sites : communities;
    if (list.length < 1) {
      return;
    }
    e.stopPropagation();
    e.preventDefault();

    // Check if the chevron icon was clicked
    if (e.target.closest('.Dropdown_SVG')) {
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }
    setShowDropdown(!showDropdown);
    setThemeStore({
      type: ThemeTypes.TraceDropDown,
      payload: {
        value: !showDropdown,
      },
    });
    setInputValue('');

    if (inputRef.current) {
      // inputRef.current.focus();
      // inputRef.current.setSelectionRange(0, 0); // set cursor at the start
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value); // always update the inputValue with what the user typed

    const list = view === 'household' ? sites : communities;
    const name = view === 'household' ? 'siteId' : 'communityName';

    const _filteredList = list.filter((item) =>
      (item?.community?.[name] || item[name])
        .toLowerCase()
        .includes(e.target.value.toLowerCase())
    );

    if (view === 'household') {
      setFilteredSites(_filteredList);
    } else {
      setFilteredCommunities(_filteredList);
    }
  };

  const handleDropdownVisualisation = () => {
    let showDropdown = false;
    if (view === 'household') {
      showDropdown = _selectedSite && sites.length > 1;
    }
    // else if (view === 'community') {
    //   showDropdown = selectedCommunity && communities.length > 1;
    // }
    return showDropdown;
  };

  useEffect(() => {
    const itemName = (selectedEntityId as any)?.siteId;
    // view === 'household'
    //   ? selectedSite[0].site_name
    //   : selectedCommunity[0].community.community_name;
    if (!showDropdown && !inputValue?.length) {
      setInputValue(itemName ?? placeholder);
    } else if (!showDropdown && inputValue !== itemName) {
      setInputValue(itemName ?? placeholder);
    }
  }, [selectedEntityId, inputValue, showDropdown, placeholder]);

  // }, [selectedSite, selectedCommunity, inputValue, showDropdown, placeholder]);

  useEffect(() => {
    if (!inputValue?.length) {
      if (view === 'household') {
        setFilteredSites(sites);
      } else {
        setFilteredCommunities(communities);
      }
      if (!showDropdown) {
        if (view === 'household') {
          setFilteredSites(sites);
        } else {
          setFilteredCommunities(communities);
        }
      }
    }
  }, [inputValue, showDropdown]);

  useEffect(() => {
    if (!showDropdown) {
      inputRef?.current?.blur();
      // inputRef?.current?.setSelectionRange(null, null);
    }
  }, [showDropdown]);

  const getSelectedItemName = () => {
    const label =
      view === 'community'
        ? _selectedCommunity?.[0]?.community.community_name ?? ''
        : selectedEntityId?.[0] ?? '';
    return label;
  };

  const getSelectedItemNameLength = () => {
    const label = getSelectedItemName();
    const lenght = label.length > 20 ? 20 : label.length;
    return lenght;
  };

  // const randomHexColorCode = () => {
  //   const n = (Math.random() * 0xfffff * 1000000).toString(16);
  //   return '#' + n.slice(0, 6);
  // };
  const showChevron = () => {
    if (view === 'community') {
      return communities?.length > 1;
    } else {
      return sites?.length > 1;
    }
  };

  const [selectedOption, setSelectedOption] = useState(
    (selectedEntityId as any)?.siteId
  );

  const handleChange = (event) => {
    const newValue = event.target.value;
    const site = sites.find((site) => site.siteId === newValue);

    setSelectedOption(newValue);

    dispatch(METADATA.SetSelectedEntity({
      selectedEntity: site,
      id: site?.siteId,
      entityType: 'household',
    } as any))
    // TODO add ID of selectedSite in the common store
    // TODO ask Sara to add an ID to the site object
  };
  // return (
  //   <div>
  //     <select value={selectedOption} onChange={handleChange}>
  //       {sites?.map(
  //         (option, index) => (
  //           (
  //             <option key={index} value={option?.siteId}>
  //               {option?.siteName} {option?.siteType?.[0]?.flexoSiteType}
  //             </option>
  //           )
  //         )
  //       )}
  //     </select>
  //   </div>
  // );
  return (
    <div className={Dropdown}>
      <div
        onClick={(e) => handleDropdownClick(e)}
        tabIndex={0}
        role="button"
        className={`${Dropdown__Input} ${Dropdown} `}
        style={style}
      >
        {handleDropdownVisualisation() ? (
          <input
            onPaste={(e) => false}
            onCopy={(e) => false}
            className={`${Dropdown__Label} heading1`}
            value={inputValue}
            size={getSelectedItemNameLength()}
            placeholder={inputPlaceHolder}
            onChange={(event) => handleInputChange(event)}
            onClick={() => {
              setInputValue('');
            }}
            defaultValue={getSelectedItemName() ?? placeholder}
            ref={inputRef}
            onFocus={() => {
              // if (inputRef.current) {
              //   inputRef.current.setSelectionRange(0, 0);
              // }
              setInputValue('');
            }}
          />
        ) : (
          <div className={`heading1 ${Dropdown__Label__Ghost} font-flexo-bold`}>
            {getSelectedItemName()}
          </div>
        )}
        {disabled && showChevron() && (
          <span
            className={`${
              showDropdown ? Dropdown__Icon__open : Dropdown__Icon
            }`}
          >
            <IconWrapper iconName="chevron-down" className={'Dropdown_SVG'} />
          </span>
        )}
      </div>
      {showDropdown && (
        <IonList className={Dropdown__Options} ref={menuRef as any}>
          {getFormattedItems().length ? (
            getFormattedItems().map(
              (option: dropdownOption, option_i: number) => (
                <IonItem
                  no-lines
                  lines={'none'}
                  key={`dropdown_${option_i}`}
                  className={`${Dropdown__Options__Option} `}
                  onClick={() => handleClick(option)}
                >
                  <div
                    className={`${Dropdown__Options__Option} ${
                      view === 'community' &&
                      Dropdown__Options__Option__Community
                    }`}
                  >
                    <h2
                      className={`${Dropdown__Options__Option__Label} heading2 ${
                        option.label === getSelectedItemName() &&
                        styles.Dropdown__Options__Option__Label__Selected
                      }`}
                    >
                      {' '}
                      {option.label}
                    </h2>
                    <p
                      className={`${Dropdown__Options__Option__Description} detail`}
                    >
                      {' '}
                      {option?.description || ''}
                    </p>
                  </div>
                </IonItem>
              )
            )
          ) : (
            <IonItem
              no-lines
              padding-left={'0'}
              padding-right={'0'}
              lines={'none'}
              className={`${Dropdown__Options__Option} ${Dropdown__Options__Option__Label} heading2 color-hive-greyscale-600`}
            >
              {t(
                view === 'community'
                  ? 'widgets.site_selector.no_communities'
                  : 'widgets.site_selector.no_sites'
              )}
            </IonItem>
          )}
        </IonList>
      )}
    </div>
  );
};

export default React.memo(Dropdown);
