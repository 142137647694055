//save as site: {
// 1day:{}
//1hour:{}
// }
//event driven development
import { useContext, useEffect } from 'react';

import {
  AlertContext,
  AlertTypes,
} from '@flexo/providers';
import {
  FirebaseService,
  formatDate,
  formatEndOfWeek,
  formatStringToDate,
  getFormattedEndOfMonth,
  getFormattedNextDay,
  getLastDayOfYear,
  getTimeGranularity,
  getTimeGranularityOverview,
  httpService,
} from '@flexo/general';

import { useDispatch, useSelector } from 'react-redux';
import { KPI, USER } from '@flexo/reducers';
import { useLocation, useParams } from 'react-router-dom';
import path from 'path';

const ENV = (import.meta as any).env;

const firebaseService = new FirebaseService();

export const KpiMemberResolver = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams()

  const pathSegment = location.pathname.split('/')[1]; 
  const metadata = useSelector((state: any) => state.metadata);
  const calendar = useSelector((state: any) => state.calendar);

  const { setAlertStore } = useContext(AlertContext);

  const { hiveCalendar } = calendar;
  const { selectedDate, selectedTimeSet } = hiveCalendar;

  async function init() {

    if ( !pathSegment?.includes( 'member' )) { return }

    const idToken = await firebaseService.getIdToken();
    const MEMBER_ID = params?.id

    if (!idToken) {
      setAlertStore({
        type: AlertTypes.SetAlert,
        payload: {
          value: {
            type: 'ALERT_SESSION_EXPIRED',
          },
        },
      });

      // setUserStore({
      //   type: UserTypes.SetUserLogOut,
      //   payload: {
      //     value: true,
      //   },
      // });

      dispatch(USER.SetLogout());

      return;
    }

    if (!metadata?.entities) { return }
    if ( !MEMBER_ID) { return }

    const sites = (metadata?.entities as any)?.site
      ?.filter((site: any) => site?.memberId === MEMBER_ID)
      ?.map((site) => site?.siteId) || [];
    
    if ( sites?.length === 0 ) { return }

    const [kpiMemberDataOverview, kpiMemberDataDetail] = await Promise.all([
      httpService
        .post({
          url: `${ENV.VITE_APP_BASE_URL}/hive/api/kpi/v1_2/site/grouped`,
          data: {
            dateFrom: formatDate(selectedDate[0]),
            dateTo: formatDate(selectedDate[1]),
            //add null: null to test the error
            timeGranularity: getTimeGranularityOverview(
              selectedTimeSet
            ),
            siteId: sites,
            locale: ENV?.VITE_APP_LOCALE || 'it-CH',
          },
          idToken,
        })
        .then((res: any) => {
          if (res.status === 200) {
            return res.data;
          } else {
            // console.warn({
            //   status: res.status,
            //   message: res.statusText,
            // });
            return null;
          }
        })
        .catch((err: any) => {
          // console.warn(err);

          // setAlertStore({
          //   type: AlertTypes.SetAlert,
          //   payload: {
          //     value: {
          //       type: 'ALERT_UNEXPECTED_ERROR',
          //     },
          //   },
          // });

          return null;
        }),

      httpService
        .post({
          url: `${ENV.VITE_APP_BASE_URL}/hive/api/kpi/v1_2/site/grouped`,
          data: {
            dateFrom: formatDate(selectedDate[0]),
            dateTo: formatDate(selectedDate[1]),
            timeGranularity: getTimeGranularity(
              selectedTimeSet,
              null
            ),
            siteId: sites,
            locale: ENV?.VITE_APP_LOCALE || '=it-IT',
          },
          idToken,
        })
        .then((res: any) => {
          if (res.status === 200) {
            return res.data;
          } else {
            // console.warn({
            //   status: res.status,
            //   message: res.statusText,
            // });
            return null;
          }
        })
        .catch((err: any) => {

          // setAlertStore({
          //   type: AlertTypes.SetAlert,
          //   payload: {
          //     value: {
          //       type: 'ALERT_UNEXPECTED_ERROR',
          //     },
          //   },
          // });

          return null;
        }),
    ]);

    if (kpiMemberDataOverview && kpiMemberDataDetail) {
      dispatch(KPI.SetKpiDataMember({
        '1day': kpiMemberDataOverview,
        '1hour': kpiMemberDataDetail,
      } as any))
    }
  }

  useEffect(() => {
      init();
  }, []);

  useEffect(() => {
      init();
  }, [selectedDate, metadata?.entities, params?.id]);

  return <></>;
};
