import { store } from '@flexo/reducers';
import * as luxon from 'luxon';

const ENV = (import.meta as any).env;

export function checkIfDayIsAvailable(day: Date) {

  if ( getStartDate() && day < luxon.DateTime.fromJSDate(new Date( getStartDate() )).startOf('day').toJSDate() ) { return false; }

    if (day > luxon.DateTime.fromJSDate(new Date()).startOf('day').toJSDate()) { return false; }

    return true;
  }

export function checkItIsToday( selectedDate: [ Date, Date ] ) {
  return luxon.DateTime.fromJSDate(selectedDate[0]).startOf('day').toMillis() === luxon.DateTime.fromJSDate(new Date()).startOf('day').toMillis();
}

export function formatDay( selectedDate: [ Date, Date ] ) {
  return luxon.DateTime.fromJSDate(selectedDate[0]).toFormat('dd/MM/yyyy');
}

export function formatWeek( selectedDate: [ Date, Date ] ) {
  return `${luxon.DateTime.fromJSDate(selectedDate[0]).toFormat('dd/MM/yyyy')} - ${luxon.DateTime.fromJSDate(selectedDate[1]).minus({ days: 1}).toFormat( 'dd/MM/yyyy' )}` 
}

export function formatMonth( selectedDate: [ Date, Date ] ) {
  return `${ (selectedDate?.[0] || selectedDate ).toLocaleString( ENV.VITE_APP_LOCALE, { month: 'long', year: 'numeric' }) }`
}

export function formatYear( selectedDate: [ Date, Date ] ) {
   return `${ selectedDate?.[0].getFullYear() }`
}

export function formatDuoDecade( years: Array<Date> ) {
  return `${ years[0].getFullYear() } - ${ years[years.length - 1].getFullYear() }`
}

export function isSevenDaysApart(date1, date2) {
  const dt1 = luxon.DateTime.fromJSDate(date1);
  const dt2 = luxon.DateTime.fromJSDate(date2);
  
const diffInDays = Math.abs(dt1.diff(dt2, "days").days);

  return diffInDays === 7;
}

export function generateYearArrays(selectedDate) {
  const selectedYear = selectedDate[0].getFullYear(); // Anno della data selezionata
  const currentYear = new Date().getFullYear(); // Anno corrente
  
  // Determiniamo il primo anno per iniziare il calcolo
  let adjustedStartYear = selectedYear;
  
  // Assicuriamoci che il currentYear sia nella posizione corretta
  while ((adjustedStartYear + 10) % 12 !== currentYear % 12) {
      adjustedStartYear--;
  }
  
  const arrays: any = [];
  let startYear = adjustedStartYear;
  
  for (let i = 0; i < 3; i++) { // Creiamo 3 gruppi
      const yearGroup: any = [];
      
      for (let j = 0; j < 4; j++) { // Ogni gruppo contiene 4 anni
          yearGroup.push(new Date(startYear, 0, 1));
          startYear++;
      }
      
      arrays.push(yearGroup);
  }
  
  return arrays;
}

export function getAllDaysOfMonthByWeek(  selectedDate: [ Date, Date ] ) {

    const year = selectedDate[0].getFullYear();
    const month = selectedDate[0].getMonth(); // Note: 0-indexed (0 = January, 11 = December)
  
    const daysInMonth = new Date(year, month + 1, 0).getDate(); // Get total days in the month
    const daysArray: Array<Date> = [];
  
    // Create an array of all days in the month
    for (let day = 1; day <= daysInMonth; day++) {
      daysArray.push(new Date(year, month, day));
    }
  
    // Organize days into weeks (Monday as the start of the week)
    const weeks: Array<any> = [];
    let currentWeek: Array<Day> = [];
  
    for (const day of daysArray) {
      const dayOfWeek = day.getDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6
      const adjustedDayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek; // Adjust Sunday to be the last day (7)
  
      // Start a new week if it's Monday and the current week has entries
      if (adjustedDayOfWeek === 1 && currentWeek.length > 0) {
        weeks.push(currentWeek);
        currentWeek = [];
      }
  
      currentWeek.push(day as any);
  
      // Push the last week at the end of the loop
      if (day.getDate() === daysInMonth) {
        weeks.push(currentWeek);
      }
    }
  
    return weeks;
}
  
export function getStartDate() {
  const metadata = store.getState().metadata;

  const startDate = (metadata?.selectedEntity as any)?.sites?.length > 0
    ? luxon.DateTime.min(...(metadata?.selectedEntity as any)?.sites.map((site: any) => luxon.DateTime.fromJSDate( new Date(site.startDate)) ) as any)
    : (metadata?.selectedEntity as any)?.startDate
    || (metadata?.selectedEntity as any)?.joinDate
    || null;

  return startDate;

}