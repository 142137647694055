
import CryptoJS from 'crypto-js';

import { Storage, Drivers } from '@ionic/storage';

const ENV = (import.meta as any).env;


const storage = new Storage({
  name: ENV.VITE_APP_DB_NAME,
  driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
});

storage.create();

// Make sure your vite/env config has VITE_APP_ENCRYPTION_KEY available:
const ENCRYPTION_KEY = ENV.VITE_APP_ENCRYPTION_KEY;

/**
 * Utility to encrypt a raw string using AES with your ENV key.
 */
function encryptData(plaintext: string): string {
  return CryptoJS.AES.encrypt(plaintext, ENCRYPTION_KEY).toString();
}

/**
 * Utility to decrypt an AES-encrypted string back to plain text.
 */
function decryptData(ciphertext: string): string {
  const bytes = CryptoJS.AES.decrypt(ciphertext, ENCRYPTION_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
}


/**
 * Store an object securely (it will be JSON-stringified, then encrypted).
 */
export async function set(key: string, object: any): Promise<void> {
  const json = JSON.stringify(object);
  const encryptedValue = encryptData(json);
  await storage.set(key, encryptedValue );
}

/**
 * Retrieve an object from secure storage (it will be decrypted, then JSON-parsed).
 */
export async function get<T>(key: string): Promise<T | null> {
  const encryptedValue  = await storage.get( key);
  if (!encryptedValue) return null;

  const decryptedValue = decryptData(encryptedValue);
  try {
    return JSON.parse(decryptedValue) as T;
  } catch (error) {
    console.error('Error parsing JSON from decrypted data:', error);
    return null;
  }
}

/**
 * Remove a specific key from Preferences.
 */
export async function remove(key: string): Promise<void> {
  await storage.remove(key);
}

/**
 * Remove ALL keys and values from Preferences. Use with caution.
 */
export async function clear(): Promise<void> {
  await storage.clear();
}

export default {
  set,
  get,
  remove,
  clear,
  storage,
};

