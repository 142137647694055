import { Ikpi } from './interfaces';

export const initState: Ikpi = {
  site: {
    '1day': [],
  },
  community: {
    '1day': [],
  },
  custom: {
    '1day': [],
  },
  member: {
    '1day': [],
  },
  communityRanking: {},
  memberRanking: {},
  isLoading: false,
};