
import { v4 as uuidv4 } from 'uuid';

import {
  IonApp,
  IonContent,
  setupIonicReact,
} from '@ionic/react';

import * as Sentry from '@sentry/react';

import { useCallback, useEffect, useState } from 'react';
import {
  AlertProvider,
  RightDrawerProvider,
  LeftDrawerProvider,
  ModalProvider,
} from '@flexo/providers';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import i18next from 'i18next';
import jwt_decode from 'jwt-decode';
import { App as CapApp } from '@capacitor/app';

import { Modules } from '@flexo/templates';

// import * as JAIL from '@swernimont/capacitor-jailbreak-root-detection';

import './app.module.scss';
import '../assets/fonts.scss';
import '../assets/typography.scss';
import '../assets/colors.scss';
// eslint-disable-next-line
import { default as COLORS } from '../assets/colors';
import '@ionic/react/css/core.css';

import { default as MODULES } from './app.modules';
import { LeftDrawer, RightDrawer } from '@flexo/molecules';
import { FirebaseService } from '@flexo/general';
import { ThemeProvider } from '@flexo/providers';
import { useDispatch, useSelector } from 'react-redux';
import { USER } from '@flexo/reducers';


const firebaseService = new FirebaseService()

const ENV = (import.meta as any).env;

export interface ILoginFlow {
  logInState: 'LOGIN' | 'REGISTER' | 'ACCOUNT_CREATION' | 'FORGOT_PASSWORD';
}

setupIonicReact(); //initiates ionic with react

function App() {

  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user);

  const [logInFlow, setLogInFlow] = useState<ILoginFlow['logInState']>('LOGIN');
  const [isBlurred, setIsBlurred] = useState(false);
  const [availableModules, setAvailableModules] = useState([]);


  const changeLanguage = useCallback(async () => {
    await i18next.changeLanguage(
      `${ENV.VITE_APP_NAME}_${ENV.VITE_APP_LANGUAGE}`
    );
  }, []);

  useEffect(() => {
    const unsubscribe = firebaseService.onAuthStateChange((user) => {
      if (user) {
        let tokenContent: any = {};
        try {
          tokenContent = jwt_decode(user?.stsTokenManager?.accessToken);
        } catch (error) {
          // console.error(error);
        }

        dispatch(USER.SetUserAsync({ ...user, tokenContent }) as any)

        // Sentry.setUser({
        //   id: user.uid,
        //   email: user.email,
        // });

        // Sentry.startSession();
      } else {
        // Clear user context in Sentry when user is logged out
        // Sentry.setUser(null);
        // Sentry.endSession();
      }
    });

    // Clean up the listener when the component is unmounted
    return () => unsubscribe();
  }, [logInFlow]);

  useEffect(() => {
    // TO DO: dinamicaly change language based on device language
    i18next.changeLanguage(`${ENV.VITE_APP_NAME}_${ENV.VITE_APP_LANGUAGE}`);


  }, [changeLanguage]);

  // useEffect(() => {
  //   CapApp.addListener('appStateChange', async ({ isActive }) => {
  //     if (!isActive) {
  //       setIsBlurred(true);
  //     } else {
  //       setIsBlurred(false);
  //     }
  //   });

  //   CapApp.addListener('backButton', ({ canGoBack }) => {
  //     //alert( 'back' )
  //   });
  // }, []);


  useEffect(() => {

    setAvailableModules([])

    let _modules: any = MODULES.filter( ( module: any ) => module.active?.default === true );

    if (user?.tokenContent) {
      
      _modules = _modules
        .filter((module: any) => module?.needsAuth === true)
      
      _modules.push({
        ...(MODULES.find((module: any) => module.name === ENV.VITE_APP_FIRST_PAGE) as any),
        name: '/'
      })

    } else {
      _modules = _modules
        .filter((module: any) => module?.needsAuth === false)
      
        _modules.push({
          ...MODULES.find((module: any) => module.name === 'login') as any,
          name: '/'
        })
      
    }



    setAvailableModules( _modules )

  }, [
    user?.tokenContent
  ])
  


  

  useEffect(() => {

    if (user?.logOut === true) {

      // setUserStore({
      //   type: UserTypes.ResetUserStore,
      //   payload: null as any,
      // })
      firebaseService.signOut()

      dispatch(USER.ResetUserAsync() as any)

      window.location.href = '/login';
    }

  }, [user?.logOut])



  return (
    <AlertProvider>
      

        
        <IonApp className={`Web__APP ${isBlurred ? 'APP_Blurred' : ''}`}>

          <ThemeProvider>

                {
                  <IonContent scrollY={false} className='IonScroll'>
                    <ModalProvider>

                        <LeftDrawer />
                      <BrowserRouter>
                        {/* <MobileModal /> */}
                        {/* <div>

                          module { JSON.stringify(availableModules) }

                        </div> */}
                        {
                          user?.SetUserAsyncStatus !== 'PENDING' &&
                          <Routes>

                          {
                            availableModules
                              .map((module: any) => {
                              return <Route key={module.name} path={ module?.needsID ? `${module.name}/:id` : module.name} element={<Modules MODULE={JSON.stringify(module)} COLORS={COLORS} />} />
                                 
                            } )
                          }
                            

                        </Routes>
                        }
                        <RightDrawer />
                      </BrowserRouter>


                    </ModalProvider>

                  </IonContent>
                }

          </ThemeProvider>

        </IonApp>
      </AlertProvider>

  );
}

export default Sentry.withProfiler(App);
