/* eslint-disable */
import styles from './../../../templates/src/lib/household/household.module.scss';
import { memo, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { IRow, IWidget } from '../templates.interfaces';
import { getGridConfig } from '@flexo/general';
import {
  Footer,
  StatsOverview,
  StatsOverviewWeb,
  StatsOverviewSkeleton,
  CommunityDetailsParameters,
  MemberDetailsParameters,
  SiteDetailsParameters,
  MemberSitesList,
  SiteMembersList,
  CombinedBatteryChart,
  HorizontalChartWrapper,
  HorizontalChartWrapperDouble,
} from '@flexo/molecules';
import {
  InformationCard,
  InformationCardSkeleton,
  CustomPullToRefresh,
  GenericTable,
  CommunitiesTable,
  MembersTable,
  Box,
  PaymentsTable,
  SitePaymentsTable,
  EsriMapComponent,
} from '@flexo/atoms';
import {
  CommunityStats,
  GraphContainer,
  Header,
  HeaderWeb,
  HeaderSkeleton,
  CommunityStatsSkeleton,
  RegisterOOBcode,
  AccountCreationOOBcode,
  ForgottenPassword,
  AccountCreationWeb,
} from '@flexo/organisms';

import { PasswordResetWeb } from '@flexo/templates';

import React from 'react';

import ApiLogIn from './api-log-in/log-in';
import WebLogIn from './web-log-in/log-in';
import ForgotPassword from './forgot-password/forgot-password';
import { RegisterByInvite } from './register-by-invite/register-by-invite';

const areEqual = (prevProps: any, nextProps: any) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
};

// TODO: is it worth to cache these components?
// if so, maybe we should use a custom hook also to hard refresh them
// and should we create also different cache for different types ( like production and consumption )?
// const CachedGraphContainerSkeleton = memo(CachedGraphContainerSkeleton, areEqual);
const CachedHeaderSkeleton = memo(HeaderSkeleton, areEqual);
const CachedStatsOverviewSkeleton = memo(StatsOverviewSkeleton, areEqual);
const CachedInformationCardSkeleton = memo(InformationCardSkeleton, areEqual);
const CachedFooter = memo(Footer, areEqual);
const CachedCommunityStatsSkeleton = memo(CommunityStatsSkeleton, areEqual);

const CachedGraphContainer = memo(GraphContainer, areEqual);
const CachedCombinedBatteryChart = memo(CombinedBatteryChart, areEqual);
const CachedHorizontalChart = memo(HorizontalChartWrapper, areEqual);
const CachedHorizontalChartDouble = memo(HorizontalChartWrapperDouble, areEqual);
const CachedHeader = memo(Header, areEqual);
const CachedGenericTable = memo(GenericTable, areEqual);
const CachedCommunitiesTable = memo(CommunitiesTable, areEqual);
const CachedMembersTable = memo(MembersTable, areEqual);
const CachedConsumptionStatsOverview = memo(StatsOverview, areEqual);
const CachedConsumptionStatsOverviewWeb = memo(StatsOverviewWeb, areEqual);
const CachedProductionStatsOverview = memo(StatsOverview, areEqual);
const CachedInformationCard = memo(InformationCard, areEqual);
const CachedCommunityStats = memo(CommunityStats as any, areEqual);
const CachedGraph = memo(GraphContainer, areEqual);
const CachedPullToRefresh = memo(CustomPullToRefresh, areEqual);
const CachedApiLogIn = memo(ApiLogIn, areEqual);
const CachedWebLogIn = memo(WebLogIn, areEqual);
const CachedForgotPassword = memo(ForgotPassword, areEqual);
const CachedRegister = memo(RegisterOOBcode, areEqual);
const CachedAccountCreationOOBcode = memo(AccountCreationOOBcode, areEqual);
const CachedPasswordResetWeb = memo(PasswordResetWeb, areEqual);
const CachedRegisterByInvite = memo(RegisterByInvite, areEqual);
const CachedAccountCreationWeb = memo(AccountCreationWeb, areEqual);


export function Factory(
  key: string,
  breakpoint = 'xs',
  props: IWidget | null = null,
  additionalStyles = {}, //extra styles for the wrapping div
) {
  let component: any = <div key={uuidv4()}> </div>;

  if (!props) return component;

  switch (props.type as any) {
    case 'box':
      component = (
        <div
          onClick={() => props?.showInspector(props)}
          key={props.id}
          className={`${getGridConfig(props.gridOptions, breakpoint)}`}
        >
          <div style={additionalStyles}>
            <Box widgetName={props.widgetName} />
          </div>
        </div>
      );
      break;
    case 'map':
      component = (
        <div 
          onClick={() => console.log('clicked', props)}
          key={props.id}>
          <div style={additionalStyles}>
          <EsriMapComponent key={uuidv4()} {...props} />
          </div>
        </div>
      );
      break;
    case 'login':
      component = (
        <div key={props.id}
        onClick={() => props?.showInspector(props)}>
          <div style={additionalStyles}>
            <CachedApiLogIn
              {...{ ...(props.data as any), ...props.widgetsData }}
            />
          </div>
        </div>
      );
      break;
    case 'forgot-password-mobile':
      component = (
        <div key={props.id}
        onClick={() => props?.showInspector(props)}>
          <div style={additionalStyles}>
            <ForgottenPassword />
          </div>
        </div>
      );
      break;
    case 'web-login':
      component = (
        <div key={props.id}
        onClick={() => props?.showInspector(props)}
        >
          <div style={additionalStyles}>
            <CachedWebLogIn
              {...{ ...(props.data as any), ...props.widgetsData }}
            />
          </div>
        </div>
      );
      break;
    case 'forgot-password':
      component = (
        <div key={props.id}
        onClick={() => props?.showInspector(props)}
        >
          <div style={additionalStyles}>
            <CachedForgotPassword
              {...{ ...(props.data as any), ...props.widgetsData }}
            />
          </div>
        </div>
      );
      break;
    case 'register':
      component = (
        <div key={props.id}
        onClick={() => props?.showInspector(props)}
        >
          <div style={additionalStyles}>
            <CachedRegister {...props} />
          </div>
        </div>
      );
      break;
    case 'accountCreation':
      component = (
        <div key={props.id}
        onClick={() => props?.showInspector(props)}
        >
          <div style={additionalStyles}>
            <CachedAccountCreationOOBcode {...props.data} />
          </div>
        </div>
      );
      break;
    case 'accountCreation-web':
      component = (
        <div key={props.id}
        onClick={() => props?.showInspector(props)}
        >
          <div style={additionalStyles}>
            <CachedAccountCreationWeb {...{ ...(props.data as any) }} />
          </div>
        </div>
      );
      break;
    case 'PasswordReset-web':
      component = (
        <div key={props.id}
        onClick={() => props?.showInspector(props)}
        >
          <div style={additionalStyles}>
            <PasswordResetWeb {...{ ...(props.data as any) }} />
          </div>
        </div>
      );
      break;
    case 'RegisterByInvite':
      component = (
        <div key={props.id}
        onClick={() => props?.showInspector(props)}
        >
          <div style={additionalStyles}>
            <CachedRegisterByInvite {...{ ...(props.data as any) }} />
          </div>
        </div>
      );
      break;
    case 'header':
      component = (
        <div
          className={`Header_Mobile`}
          onClick={() => props?.showInspector(props)}
          key={props.id}
          style={{ zIndex: 9999, position: 'fixed', width: '100%' }}
        >
          <Header {...props} />
        </div>
      );
      break;
    case 'headerWeb':
      component = (
        <div
          className={`Header__Web__Wrapper`}
          onClick={() => props?.showInspector(props)}
          key={props.id}
          style={{ zIndex: 9999, position: 'fixed', width: '100%' }}
        >
          <HeaderWeb {...{ ...(props.data as any) }} />
        </div>
      );
      break;
    case 'sitePaymentsTable':
      component = (
        <div key={props.id}
        onClick={() => props?.showInspector(props)}
        >
          <div style={additionalStyles}>
            <SitePaymentsTable />
          </div>
        </div>
      );
      break;
    case 'paymentsTable':
      component = (
        <div key={props.id}
        onClick={() => props?.showInspector(props)}
        >
          <div style={additionalStyles}>
            <PaymentsTable />
          </div>
        </div>
      );
      break;
    case 'memberSitesList':
      component = (
        <div key={props.id}
        onClick={() => props?.showInspector(props)}
        >
          <div style={additionalStyles}>
            <MemberSitesList />
          </div>
        </div>
      );
      break;
    case 'siteMembersList':
      component = (
        <div key={props.id}
        onClick={() => props?.showInspector(props)}
        >
          <div style={additionalStyles}>
            <SiteMembersList />
          </div>
        </div>
      );
      break;
    case 'statsOverview':
      component = (
        <div key={props.id}
        onClick={() => props?.showInspector(props)}
          style={additionalStyles}>
          <CachedConsumptionStatsOverview {...props} />
        </div>
      );
      break;
    case 'statsOverviewWeb':
      component = (
        <div key={props.id}
        onClick={() => props?.showInspector(props)}
          style={additionalStyles}>
          <CachedConsumptionStatsOverviewWeb {...props} />
        </div>
      );
      break;
    case 'card':
      component = (
        <div key={props.id}
        onClick={() => props?.showInspector(props)}
        >
          <div style={additionalStyles}>
            <CachedInformationCard {...props} />
          </div>
        </div>
      );
      break;
    case 'CommunityDetailsParameters':
      component = (
        <div key={props.id}
        onClick={() => props?.showInspector(props)}
        >
          <div style={additionalStyles}>
            <CommunityDetailsParameters {...(props.data as any)} />
          </div>
        </div>
      );
      break;
    case 'MemberDetailsParameters':
      component = (
        <div key={props.id}
        onClick={() => props?.showInspector(props)}
        >
          <div style={additionalStyles}>
            <MemberDetailsParameters {...(props.data as any)} />
          </div>
        </div>
      );
      break;
    case 'SiteDetailsParameters':
      component = (
        <div key={props.id}
        onClick={() => props?.showInspector(props)}
        >
          <div style={additionalStyles}>
            <SiteDetailsParameters {...(props.data as any)} />
          </div>
        </div>
      );
      break;
    case 'graphs':
      component = (
        <div key={props.id}
        onClick={() => props?.showInspector(props)}
          style={additionalStyles}>
          <CachedGraphContainer {...props} />
        </div>
      );
      break;
    case 'table':
      component = (
        <div key={props.id}
        onClick={() => props?.showInspector(props)}
        >
          <div style={additionalStyles}>
            <GenericTable {...{ ...(props.data as any) }} />
          </div>
        </div>
      );
      break;
    case 'communitiesTable':
      component = (
        <div key={props.id}
        onClick={() => props?.showInspector(props)}
        >
            <div style={additionalStyles}>
              <CommunitiesTable {...{ ...(props.data as any) }} />
            </div>
        </div>
      );
      break;
    case 'membersTable':
      component = (
        <div key={props.id}
        onClick={() => props?.showInspector(props)}
        >
            <div style={additionalStyles}>
              <MembersTable {...{ ...(props.data as any) }} />
            </div>
        </div>
      );
      break;
    case 'communityStats':
      component = (
        <div key={props.id}
        onClick={() => props?.showInspector(props)}
        >
          <div style={additionalStyles}>
            <CachedCommunityStats {...props} />
          </div>
        </div>
      );
      break;
    case 'areachart':
    case 'areaChart':
      component = (
        <div key={props.id}
        onClick={() => props?.showInspector(props)}
          style={additionalStyles}>
          <CachedGraphContainer {...props} />
        </div>
      );
      break;
    case 'combinedGraph':
      component = (
        <div key={props.id}
        onClick={() => props?.showInspector(props)}
          style={additionalStyles}>
          <CachedGraphContainer {...{ ...(props.data as any) }} />
        </div>
      );
      break;
    case 'batteryChart':
      component = (
        <div key={props.id}
        onClick={() => props?.showInspector(props)}
        >
          <div style={additionalStyles}>
            <CachedCombinedBatteryChart {...props} />
          </div>
        </div>
      );
      break;
    case 'horizontalChart':
      component = (
        <div key={props.id}
        onClick={() => props?.showInspector(props)}
        >
          <div style={additionalStyles}>
            <CachedHorizontalChart {...props} />
          </div>
        </div>
      );
      break;
    case 'horizontalChartDouble':
      component = (
        <div key={props.id}
        onClick={() => props?.showInspector(props)}
        >
          <div style={additionalStyles}>
            <CachedHorizontalChartDouble {...props} />
          </div>
        </div>
      );
      break;
    case 'footer':
      component = (
        <div
          key={props.id}
          onClick={() => props?.showInspector(props)}
          style={additionalStyles}
          className={`Footer_Mobile`}
        >
          <Footer {...(props as any)} />
        </div>
      );
      break;
    case 'pull-to-refresh':
      component = <CustomPullToRefresh {...{ ...(props.data as any) }} />;

      break;

    default:
      break;
  }

  return component;
}

const MemoizedFactoryComponent = React.memo(_FactoryComponent, areEqual);
const CommunityMemoizedFactoryComponent = React.memo(_FactoryComponent);

export function _FactoryComponent(props: any = null, loading?): any {
  const {
    configuration = null,
    breakpoint = 'xs',
    refreshAction = null,
    dataInTimeSpan = true,
  } = props;
  if (configuration?.rows) {
    return (
      <>
        {configuration.rows.map((row: IRow) => (
          <div
            className={getGridConfig(row.gridOptions, breakpoint)}
            key={`row_${uuidv4()}`}
          >
            {row?.widgets && row?.widgets?.length > 0
              ? row.widgets.map((widget: IWidget) =>
                  Factory(uuidv4(), breakpoint, widget)
                )
              : ''}
          </div>
        ))}
      </>
    );
  } else {
    return <div key={uuidv4()}>Invalid configuration </div>;
  }
}

export { MemoizedFactoryComponent as FactoryComponent };
export { CommunityMemoizedFactoryComponent as CommunityFactoryComponent };
