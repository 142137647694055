import { useDispatch, useSelector } from "react-redux";
import * as luxon from 'luxon';


import styles from './hive-calendar.module.scss';
import { CALENDAR } from "@flexo/reducers";
import { generateYearArrays, getStartDate } from "./helpers";

export function HiveCalendarContentYear( props: any ) {

  const dispatch = useDispatch();

  const { setShowCalendar } = props;

  const calendar = useSelector((state: any) => state.calendar);
  const hiveCalendar = calendar.hiveCalendar;

  const {
    calendarDate,
    selectedDate,
    calendarTimeSet,
    selectedTimeSet,
    calendarNavigationActive,
  } = hiveCalendar;

  const _selectedDate = calendarDate || selectedDate;
  const startDate = getStartDate();

  

  function checkSelectedYear(year: Date) {

    if (calendarNavigationActive) { return false }
    
    if (selectedTimeSet !== 'year') { return false; }
    const timeSet = calendarTimeSet || selectedTimeSet;
    return timeSet === 'year' && (luxon.DateTime.fromJSDate(year).startOf('year').toMillis() === luxon.DateTime.fromJSDate(selectedDate[0]).startOf('year').toMillis());
  }

  function setYear(year: Date) {


    if (year.getFullYear() > new Date().getFullYear()) { return; }

    const yearStart = new Date(year.getFullYear(), 0, 1);
    const yearEnd = new Date(year.getFullYear() + 1, 0, 1);

    
    if (calendarNavigationActive) {


      dispatch(CALENDAR.setHiveCalendarDate([yearStart, yearEnd] as any));
      dispatch(CALENDAR.setHiveCalendarTimeSet('month' as any));

    } else {
      dispatch(CALENDAR.setHiveDate({
        date: [yearStart, yearEnd],
        timeSet: 'year',
      } as any));

      setShowCalendar(false);

    }

  }

  function checkYearRange(year: Date) {

    if ( startDate && new Date( startDate ).getFullYear() > year.getFullYear() ) { return false; }
    return year.getFullYear() <= new Date().getFullYear();
  }
  
  const yearArrays = generateYearArrays(_selectedDate);
  
  return <div className={styles.HiveCalendar__Calendar__Content__Year}> 
    
    {
      yearArrays
        .map(( yearsLine: Array<Date>, index) => {
          return <div key={`HiveCalendar__Calendar__Content__Year__Line__${index}`}
            className={`
              ${styles.HiveCalendar__Calendar__Content__Year__Line}
            `}
          >
            {
              yearsLine
                .map(( year: Date, i) => {
                  return <div key={`HiveCalendar__Calendar__Content__Year__Line__Year__${index}__${i}`}
                    className={`
                      ${styles.HiveCalendar__Calendar__Content__Year__Line__Year}
                      ${checkSelectedYear(year)
                        ? styles.HiveCalendar__Calendar__Content__Year__Line__Year__Selected :
                        ''
                      }
                      ${checkYearRange(year)
                        ? '' :
                        styles.HiveCalendar__Calendar__Content__Year__Line__Year__Disabled
                      }
                  `}
                    onClick={() => checkYearRange(year) ? setYear(year): null}
                  >
                    { year.getFullYear() }
                  </div>
                })
            }
          </div>
        })
    }

  </div>

}