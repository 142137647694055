

export default [
  // ######### LOGIN

  {
    name: 'login',
    active: {
      default: true,
    },
    visible: {
      default: true,
    },
    footer: {
      default: false,
    },
    header: {
      default: false,
    },
    datePicker: {
      default: false,
    },
    pullToRefresh: {
      default: false,
    },
    layouts: {
      default: {
        lg: [
          {
            i: 'WebLogin',
            x: 4,
            y: 12,
            w: 4,
            h: 75,
            static: true,
            draggable: false,
            resize: false,
          },
        ],
      },
    },
    widgets: {
      default: [
        {
          id: 'WebLogin',
          type: 'web-login',
          devID: 'hysterical-kiwi',
          devPath: 'login->default->WebLogin',
        },
      ],
    },
  },

  // ######### FORGOT PASSWORD #########

  {
    name: 'forgot-password',
    active: {
      default: true,
    },
    visible: {
      default: true,
    },
    footer: {
      default: false,
    },
    header: {
      default: false,
    },
    datePicker: {
      default: false,
    },
    pullToRefresh: {
      default: false,
    },
    needsAuth: false,
    layouts: {
      default: {
        lg: [
          {
            i: 'ForgotPassword',
            x: 4,
            y: 12,
            w: 4,
            h: 75,
            static: true,
            draggable: false,
            resize: false,
          },
        ],
      },
    },
    widgets: {
      default: [
        {
          id: 'ForgotPassword',
          type: 'forgot-password',
          devID: 'clumsy-lemonade',
          devPath: 'forgot-password->default->ForgotPassword',
        },
      ],
    },
  },

  // ######### REGISTER #########

  {
    name: 'register',
    active: {
      default: false,
    },
    visible: {
      default: false,
    },
    footer: {
      default: false,
    },
    header: {
      default: false,
    },
    datePicker: {
      default: false,
    },
    pullToRefresh: {
      default: false,
    },
    needsAuth: false,
    layouts: {
      default: {
        lg: [
          {
            i: 'Register',
            x: 0,
            y: 12,
            w: 12,
            h: 8,
            static: true,
            draggable: false,
            resize: false,
          },
        ],
      },
    },
    widgets: {
      default: [
        {
          id: 'Register',
          type: 'register',
          devID: 'unruly-glove',
          devPath: 'register->default->Register',
        },
      ],
    },
  },

  // ######### ACCOUNT CREATION #########

  {
    name: 'account-creation',
    active: {
      default: false,
    },
    visible: {
      default: false,
    },
    footer: {
      default: false,
    },
    header: {
      default: false,
    },
    datePicker: {
      default: false,
    },
    pullToRefresh: {
      default: false,
    },
    needsAuth: false,
    layouts: {
      default: {
        lg: [
          {
            i: 'AccountCreation',
            x: 4,
            y: 12,
            w: 4,
            h: 75,
            static: true,
            draggable: false,
            resize: false,
          },
        ],
      },
    },
    widgets: {
      default: [
        {
          id: 'AccountCreation',
          type: 'accountCreation-web',
          devID: 'spritely-cat',
          devPath: 'account-creation->default->AccountCreation',
        },
      ],
    },
  },

  // ######### PASSWORD RESET #########

  {
    name: 'password-reset',
    active: {
      default: true,
    },
    visible: {
      default: false,
    },
    footer: {
      default: false,
    },
    header: {
      default: false,
    },
    datePicker: {
      default: false,
    },
    pullToRefresh: {
      default: false,
    },
    needsAuth: false,
    layouts: {
      default: {
        lg: [
          {
            i: 'PasswordReset',
            x: 4,
            y: 12,
            w: 4,
            h: 75,
            static: true,
            draggable: false,
            resize: false,
          },
        ],
      },
    },
    widgets: {
      default: [
        {
          id: 'PasswordReset',
          type: 'PasswordReset-web',
          devID: 'nocturnal-cat',
          devPath: 'password-reset->default->PasswordReset',
        },
      ],
    },
  },

  // ######### REGISTER BY INVITE #########

  {
    name: 'register-by-invite',
    active: {
      default: true,
    },
    visible: {
      default: false,
    },
    footer: {
      default: false,
    },
    header: {
      default: false,
    },
    datePicker: {
      default: false,
    },
    pullToRefresh: {
      default: false,
    },
    needsAuth: false,
    layouts: {
      default: {
        lg: [
          {
            i: 'RegisterByInvite',
            x: 4,
            y: 12,
            w: 4,
            h: 75,
            static: true,
            draggable: false,
            resize: false,
          },
        ],
      },
    },
    widgets: {
      default: [
        {
          id: 'RegisterByInvite',
          type: 'RegisterByInvite',
          devID: 'golden-iceberg',
          devPath: 'register-by-invite->default->RegisterByInvite',
        },
      ],
    },
  },

  // ######### COMMUNITIES #########

  {
    name: 'communities',
    needsAuth: true,
    active: {
      default: true,
    },
    visible: {
      default: false,
    },
    sideBar: {
      default: false,
    },
    pullToRefresh: {
      default: false,
    },
    apis: {
      default: [
        'CommunitiesResolver',
        'CommunitiesOverviewResolver',
        'EntitiesResolver',
      ],
    },
    layouts: {
      default: {
        lg: [
          {
            i: 'headerWeb',
            x: 0,
            y: 0,
            w: 12,
            h: 10,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'communitiesTable',
            x: 0,
            y: 11,
            w: 12,
            h: 38,
            static: true,
            draggable: false,
            resize: false,
          },
        ],
      },
    },
    widgets: {
      default: [
        {
          type: 'headerWeb',
          id: 'headerWeb',
          data: {
            label: 'communities',
            navigation: false,
            calendar: false,
            download: false,
            searchBar: true,
            profile: true,
          },
          devID: 'peculiar-lollipop',
          devPath: 'communities->default->headerWeb',
        },
        {
          type: 'communitiesTable',
          id: 'communitiesTable',
          devID: 'nocturnal-dragon',
          devPath: 'communities->default->communitiesTable',
        },
      ],
    },
  },

  // ######### COMMUNITY #########

  {
    name: 'community',
    needsAuth: true,
    needsID: true,
    active: {
      default: true,
    },
    visible: {
      default: false,
    },
    sideBar: {
      default: false,
    },
    pullToRefresh: {
      default: false,
    },
    apis: {
      default: [
        'CommunityResolver',
        'MembersResolver',
        'EntitiesResolver',
        'KpiCommunityResolver',
      ],
    },
    layouts: {
      default: {
        lg: [
          {
            i: 'headerWeb',
            x: 0,
            y: 0,
            w: 12,
            h: 10,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'communityBenefitsCard',
            x: 0,
            y: 12,
            w: 4,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingRight: 8,
              height: '100%',
            },
          },
          {
            i: 'communityEnergyFlowsCard',
            x: 4,
            y: 12,
            w: 4,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingRight: 8,
              paddingLeft: 8,
              height: '100%',
            },
          },
          {
            i: 'map',
            x: 8,
            y: 12,
            w: 4,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingLeft: 8,
              height: '100%',
            },
          },
          {
            i: 'membersTable',
            x: 0,
            y: 42,
            w: 9,
            h: 38,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'CommunityDetailsParameters',
            x: 10,
            y: 42,
            w: 3,
            h: 38,
            static: true,
            draggable: false,
            resize: false,
          },
        ],
      },
    },
    widgets: {
      default: [
        {
          type: 'headerWeb',
          id: 'headerWeb',
          data: {
            label: 'community',
            breadCrumbs: true,
            navigation: true,
            calendar: false,
            download: false,
            searchBar: true,
            profile: true,
          },
          devID: 'whimsical-quokka',
          devPath: 'community->default->headerWeb',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          subtype: 'communityBenefitsCard',
          id: 'communityBenefitsCard',
          totalValue: 'total_benefits',
          icon: 'euro',
          descriptionCallback: 'communityEconomics',
          descriptionType: 'label',
          description: 'widgets.savingsCard.description.default',
          devID: 'whimsical-nacho',
          devPath: 'community->default->communityBenefitsCard',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          subtype: 'communityEnergyFlowsCard',
          id: 'communityEnergyFlowsCard',
          icon: 'lightning',
          descriptionCallback: 'communityEnergy',
          descriptionType: 'label+value',
          description: [
            {
              key: 'widgets.communityEnergyFlowsCard.description.default.consumption',
              value: 'consumption',
            },
            {
              key: 'widgets.communityEnergyFlowsCard.description.default.production',
              value: 'production',
            },
            {
              key: 'widgets.communityEnergyFlowsCard.description.default.shared',
              value: 'total_shared',
            },
          ],
          devID: 'vivacious-glove',
          devPath: 'community->default->communityEnergyFlowsCard',
        },
        {
          env: 'web',
          type: 'map',
          gradientBorder: false,
          id: 'map',
          devID: 'oddball-kangaroo',
          devPath: 'community->default->map',
        },
        {
          type: 'membersTable',
          id: 'membersTable',
          devID: 'luminous-espresso',
          devPath: 'community->default->membersTable',
        },
        {
          type: 'CommunityDetailsParameters',
          id: 'CommunityDetailsParameters',
          data: {
            title: 'widgets.communityCommunityDetailsParameters.title',
            fields: {
              communityId:
                'widgets.communityCommunityDetailsParameters.communityId',
              externalId:
                'widgets.communityCommunityDetailsParameters.externalId',
              communityCode:
                'widgets.communityCommunityDetailsParameters.communityCode',
              communityName:
                'widgets.communityCommunityDetailsParameters.communityName',
              communityType:
                'widgets.communityCommunityDetailsParameters.communityType',
              startDate:
                'widgets.communityCommunityDetailsParameters.startDate',
            },
            shownFields: [
              'externalId',
              'communityName',
              'communityType',
              'startDate',
            ],
          },
          devID: 'quirky-shoe',
          devPath: 'community->default->CommunityDetailsParameters',
        },
      ],
    },
  },

  // ######### MEMBER #########

  {
    name: 'member',
    needsAuth: true,
    needsID: true,
    active: {
      default: true,
    },
    visible: {
      default: false,
    },
    sideBar: {
      default: false,
    },
    pullToRefresh: {
      default: false,
    },
    apis: {
      default: [
        'EntitiesResolver',
        'MembersResolver',
        'MemberResolver',
        'KpiMemberResolver',
      ],
    },
    layouts: {
      default: {
        lg: [
          {
            i: 'headerWeb',
            x: 0,
            y: 0,
            w: 12,
            h: 10,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'memberBenefitsCard',
            x: 0,
            y: 12,
            w: 4,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingRight: 8,
              height: '100%',
            },
          },
          {
            i: 'memberEnergyFlowsCard',
            x: 4,
            y: 12,
            w: 4,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingRight: 8,
              paddingLeft: 8,
              height: '100%',
            },
          },
          {
            i: 'map',
            x: 8,
            y: 12,
            w: 4,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingLeft: 8,
              height: '100%',
            },
          },
          {
            i: 'paymentsTable',
            x: 0,
            y: 41,
            w: 9,
            h: 38,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'MemberDetailsParameters',
            x: 10,
            y: 41,
            w: 3,
            h: 60,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'memberSitesList',
            x: 10,
            y: 101,
            w: 3,
            h: 82,
            static: true,
            draggable: false,
            resize: false,
          },
        ],
      },
    },
    widgets: {
      default: [
        {
          type: 'headerWeb',
          id: 'headerWeb',
          data: {
            label: 'members',
            breadCrumbs: true,
            navigation: true,
            calendar: false,
            download: false,
            searchBar: true,
            profile: true,
          },
          devID: 'whacky-quokka',
          devPath: 'member->default->headerWeb',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          subtype: 'memberBenefitsCard',
          id: 'memberBenefitsCard',
          totalValue: 'total_benefits',
          icon: 'euro',
          descriptionCallback: 'MemberBenefits',
          descriptionType: 'label',
          description: 'widgets.memberBenefitsCard.description.default',
          devID: 'waggish-brick',
          devPath: 'member->default->memberBenefitsCard',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          subtype: 'memberEnergyFlowsCard',
          id: 'memberEnergyFlowsCard',
          icon: 'lightning',
          descriptionCallback: 'memberEnergy',
          descriptionType: 'label+value',
          description: [
            {
              key: 'widgets.memberEnergyFlowsCard.description.default.consumption',
              value: 'consumption',
            },
            {
              key: 'widgets.memberEnergyFlowsCard.description.default.production',
              value: 'production',
            },
            {
              key: 'widgets.memberEnergyFlowsCard.description.default.self_consumption',
              value: 'self_consumption',
            },
          ],
          devID: 'wondrous-lemonade',
          devPath: 'member->default->memberEnergyFlowsCard',
        },
        {
          env: 'web',
          type: 'map',
          gradientBorder: false,
          id: 'map',
          context: 'member',
          devID: 'mystical-kiwi',
          devPath: 'member->default->map',
        },
        {
          type: 'paymentsTable',
          id: 'paymentsTable',
          devID: 'cannibal-pajamas',
          devPath: 'member->default->paymentsTable',
        },
        {
          type: 'MemberDetailsParameters',
          id: 'MemberDetailsParameters',
          data: {
            title: 'widgets.memberDetailsParameters.title',
            fields: {
              communityId: 'widgets.memberDetailsParameters.communityId',
              externalId: 'widgets.memberDetailsParameters.externalId',
              communityCode: 'widgets.memberDetailsParameters.communityCode',
              communityName: 'widgets.memberDetailsParameters.communityName',
              communityType: 'widgets.memberDetailsParameters.communityType',
              startDate: 'widgets.memberDetailsParameters.startDate',
              memberId: 'widgets.memberDetailsParameters.memberId',
              clientId: 'widgets.memberDetailsParameters.clientId',
              name: 'widgets.memberDetailsParameters.name',
              address: 'widgets.memberDetailsParameters.address',
              city: 'widgets.memberDetailsParameters.city',
              postcode: 'widgets.memberDetailsParameters.postcode',
              phone: 'widgets.memberDetailsParameters.phone',
              email: 'widgets.memberDetailsParameters.email',
            },
            sitesNumber: 'widgets.memberDetailsParameters.sitesNumber',
            shownFields: ['memberId', 'address', 'city', 'phone', 'email'],
          },
          devID: 'quirksome-zeppelin',
          devPath: 'member->default->MemberDetailsParameters',
        },
        {
          type: 'memberSitesList',
          id: 'memberSitesList',
          devID: 'peculiar-marshmallow',
          devPath: 'member->default->memberSitesList',
        },
      ],
    },
  },

  // ######### MEMBER ECONOMICS #########

  {
    name: 'member-economics',
    active: {
      default: true,
    },
    visible: {
      default: false,
    },
    footer: {
      default: false,
    },
    header: {
      default: false,
    },
    datePicker: {
      default: false,
    },
    pullToRefresh: {
      default: false,
    },
    needsAuth: true,
    needsID: true,
    apis: {
      default: [
        'EntitiesResolver',
        'MemberResolver',
        'KpiMemberResolver',
        'MemberRankingResolver',
      ],
    },
    layouts: {
      default: {
        lg: [
          {
            i: 'headerWeb',
            x: 0,
            y: 0,
            w: 12,
            h: 10,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'memberSavingsCard',
            x: 0,
            y: 13,
            w: 12,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              minHeight: '100%',
              height: '100%',
            },
          },
          {
            i: 'savingsStackBar',
            x: 0,
            y: 42,
            w: 12,
            h: 52,
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',
              boxSizing: 'border-box',
            },
          },
          {
            i: 'horizontalChart',
            x: 0,
            y: 96,
            w: 12,
            h: 53,
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',
              boxSizing: 'border-box',
            },
          },
        ],
      },
      administrator: {
        default: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'memberSavingsCard',
              x: 0,
              y: 13,
              w: 12,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                minHeight: '100%',
                height: '100%',
              },
            },
            {
              i: 'savingsStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 52,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChart',
              x: 0,
              y: 96,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        default_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'memberSavingsCard',
              x: 0,
              y: 13,
              w: 12,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                minHeight: '100%',
                height: '100%',
              },
            },
            {
              i: 'savingsStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 52,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
      },
    },
    widgets: {
      default: [
        {
          type: 'headerWeb',
          id: 'headerWeb',
          data: {
            label: 'member-economics',
            breadCrumbs: true,
            navigation: true,
            calendar: true,
            download: false,
            searchBar: true,
            profile: true,
          },
          devID: 'happy-zeppelin',
          devPath: 'member-economics->default->headerWeb',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          subtype: 'memberSavingsCard',
          id: 'memberSavingsCard',
          totalValue: 'total_benefits',
          icon: 'savings',
          descriptionType: 'label',
          description: 'widgets.memberSavingsCard.description.default',
          descriptionCallback: 'memberTotalEstimatedBenefits',
          devID: 'playful-dragon',
          devPath: 'member-economics->default->memberSavingsCard',
        },
        {
          env: 'web',
          type: 'graphs',
          gradientBorder: false,
          subtype: 'savingsStackBar',
          id: 'savingsStackBar',
          graphKeys: [
            {
              key: 'revenues',
              color: 'primary',
              combined: false,
            },
          ],
          colors: ['primary'],
          title: 'widgets.savingsStackBar.title',
          devID: 'mystical-tangerine',
          devPath: 'member-economics->default->savingsStackBar',
        },
        {
          type: 'horizontalChart',
          gradientBorder: false,
          subtype: 'horizontalChart',
          id: 'horizontalChart',
          entity: 'member',
          context: 'economics',
          rankingKpi: 'revenues',
          rankingKpiName: 'widgets.horizontalChart.economics.revenues',
          graphKeys: ['revenues'],
          combinedKeys: [],
          buttons: ['widgets.horizontalChart.economics.benefits'],
          colors: [
            {
              color: 'economics',
              colorShade: '',
            },
          ],
          title: 'widgets.horizontalChart.economics.title',
          devID: 'hysterical-kangaroo',
          devPath: 'member-economics->default->horizontalChart',
        },
      ],
      administrator: {
        default: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'member-economics',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'rowdy-nacho',
            devPath: 'member-economics->administrator->default->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            subtype: 'memberSavingsCard',
            id: 'memberSavingsCard',
            totalValue: 'total_benefits',
            icon: 'savings',
            descriptionType: 'label',
            description: 'widgets.memberSavingsCard.description.default',
            descriptionCallback: 'memberTotalEstimatedBenefits',
            devID: 'quirky-kiwi',
            devPath:
              'member-economics->administrator->default->memberSavingsCard',
          },
          {
            env: 'web',
            type: 'graphs',
            gradientBorder: false,
            subtype: 'savingsStackBar',
            id: 'savingsStackBar',
            graphKeys: [
              {
                key: 'revenues',
                color: 'primary',
                combined: false,
              },
            ],
            colors: ['primary'],
            title: 'widgets.savingsStackBar.title',
            devID: 'peculiar-jellyfish',
            devPath:
              'member-economics->administrator->default->savingsStackBar',
          },
          {
            type: 'horizontalChart',
            gradientBorder: false,
            subtype: 'horizontalChart',
            id: 'horizontalChart',
            entity: 'member',
            context: 'economics',
            rankingKpi: 'revenues',
            rankingKpiName: 'widgets.horizontalChart.economics.revenues',
            graphKeys: ['revenues'],
            combinedKeys: [],
            buttons: ['widgets.horizontalChart.economics.benefits'],
            colors: [
              {
                color: 'economics',
                colorShade: '',
              },
            ],
            title: 'widgets.horizontalChart.economics.title',
            devID: 'happy-hedgehog',
            devPath:
              'member-economics->administrator->default->horizontalChart',
          },
        ],
        default_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'member-economics',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'wild-nacho',
            devPath:
              'member-economics->administrator->default_short->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            subtype: 'memberSavingsCard',
            id: 'memberSavingsCard',
            totalValue: 'total_benefits',
            icon: 'savings',
            descriptionType: 'label',
            description: 'widgets.memberSavingsCard.description.default',
            descriptionCallback: 'memberTotalEstimatedBenefits',
            devID: 'ferocious-butterfly',
            devPath:
              'member-economics->administrator->default_short->memberSavingsCard',
          },
          {
            env: 'web',
            type: 'graphs',
            gradientBorder: false,
            subtype: 'savingsStackBar',
            id: 'savingsStackBar',
            graphKeys: [
              {
                key: 'revenues',
                color: 'primary',
                combined: false,
              },
            ],
            colors: ['primary'],
            title: 'widgets.savingsStackBar.title',
            devID: 'fantastic-octopus',
            devPath:
              'member-economics->administrator->default_short->savingsStackBar',
          },
        ],
      },
    },
  },

  // ######### MEMBER ENERGY #########

  {
    name: 'member-energy',
    active: {
      default: true,
    },
    visible: {
      default: false,
    },
    footer: {
      default: false,
    },
    header: {
      default: false,
    },
    datePicker: {
      default: false,
    },
    pullToRefresh: {
      default: false,
    },
    needsAuth: true,
    needsID: true,
    apis: {
      default: [
        'EntitiesResolver',
        'MemberResolver',
        'KpiMemberResolver',
        'MemberRankingResolver',
      ],
    },
    layouts: {
      default: {
        lg: [
          {
            i: 'headerWeb',
            x: 0,
            y: 0,
            w: 12,
            h: 10,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'memberStatsOverviewConsumption',
            x: 0,
            y: 11,
            w: 4,
            h: 50,
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',
              backgroundColor: 'var( --hive-pureWhite )',
              marginLeft: -16,
              paddingLeft: 16,
              minWidth: '33.3%!important',
            },
          },
          {
            i: 'memberStatsOverviewProduction',
            x: 4,
            y: 11,
            w: 4,
            h: 50,
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',
              backgroundColor: 'var( --hive-pureWhite )',
            },
          },
          {
            i: 'map',
            x: 8,
            y: 11,
            w: 4,
            h: 50,
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',
              marginRight: -16,
              minWidth: '33.3%!important',
            },
          },
          {
            i: 'memberSelfSufficiencyCard',
            x: 0,
            y: 63,
            w: 4,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingRight: 8,
              paddingLeft: 8,
              height: '100%',
            },
          },
          {
            i: 'memberSharedEnergyCard',
            x: 4,
            y: 63,
            w: 4,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingRight: 8,
              paddingLeft: 8,
              height: '100%',
            },
          },
          {
            i: 'memberPollutionCard',
            x: 8,
            y: 63,
            w: 4,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingLeft: 8,
              height: '100%',
            },
          },
          {
            i: 'consumptionStackBar',
            x: 0,
            y: 92,
            w: 6,
            h: 53,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingRight: 8,
              boxSizing: 'border-box',
              height: '100%',
            },
          },
          {
            i: 'productionStackBar',
            x: 7,
            y: 92,
            w: 6,
            h: 53,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingLeft: 8,
              boxSizing: 'border-box',
              height: '100%',
            },
          },
          {
            i: 'batteryChart',
            x: 0,
            y: 147,
            w: 6,
            h: 48,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingRight: 8,
              height: '100%',
              boxSizing: 'border-box',
            },
          },
          {
            i: 'areaChartSelfConsumption',
            x: 6,
            y: 147,
            w: 6,
            h: 53,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingLeft: 8,
              height: '100%',
              boxSizing: 'border-box',
            },
          },
          {
            i: 'horizontalChartDouble',
            x: 0,
            y: 206,
            w: 12,
            h: 53,
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',
              boxSizing: 'border-box',
            },
          },
        ],
      },
      default_short: {
        lg: [
          {
            i: 'headerWeb',
            x: 0,
            y: 0,
            w: 12,
            h: 10,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'memberStatsOverviewConsumption',
            x: 0,
            y: 11,
            w: 4,
            h: 50,
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',
              backgroundColor: 'var( --hive-pureWhite )',
              marginLeft: -16,
              paddingLeft: 16,
              minWidth: '33.3%!important',
            },
          },
          {
            i: 'memberStatsOverviewProduction',
            x: 4,
            y: 11,
            w: 4,
            h: 50,
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',
              backgroundColor: 'var( --hive-pureWhite )',
            },
          },
          {
            i: 'map',
            x: 8,
            y: 11,
            w: 4,
            h: 50,
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',
              marginRight: -16,
              minWidth: '33.3%!important',
            },
          },
          {
            i: 'memberSelfSufficiencyCard',
            x: 0,
            y: 63,
            w: 4,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingRight: 8,
              paddingLeft: 8,
              height: '100%',
            },
          },
          {
            i: 'memberSharedEnergyCard',
            x: 4,
            y: 63,
            w: 4,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingRight: 8,
              paddingLeft: 8,
              height: '100%',
            },
          },
          {
            i: 'memberPollutionCard',
            x: 8,
            y: 63,
            w: 4,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingLeft: 8,
              height: '100%',
            },
          },
          {
            i: 'consumptionStackBar',
            x: 0,
            y: 92,
            w: 6,
            h: 53,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingRight: 8,
              boxSizing: 'border-box',
              height: '100%',
            },
          },
          {
            i: 'productionStackBar',
            x: 7,
            y: 92,
            w: 6,
            h: 53,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingLeft: 8,
              boxSizing: 'border-box',
              height: '100%',
            },
          },
          {
            i: 'batteryChart',
            x: 0,
            y: 147,
            w: 6,
            h: 48,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingRight: 8,
              height: '100%',
              boxSizing: 'border-box',
            },
          },
          {
            i: 'areaChartSelfConsumption',
            x: 6,
            y: 147,
            w: 6,
            h: 53,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingLeft: 8,
              height: '100%',
              boxSizing: 'border-box',
            },
          },
        ],
      },
      administrator: {
        consumer: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalConsumptionCard',
              x: 0,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 3,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 6,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'map',
              x: 9,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChart',
              x: 0,
              y: 98,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        producer: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalProductionCard',
              x: 0,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 3,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 6,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'map',
              x: 9,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'productionStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChart',
              x: 0,
              y: 98,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        consumer_producer: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalConsumptionCard',
              x: 0,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'totalProductionCard',
              x: 2.4,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 4.8,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 16,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'pollutionCard',
              x: 7.2,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'map',
              x: 9.6,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                marginRight: -8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 42,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 42,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChartDouble',
              x: 0,
              y: 98,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        prosumer: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'memberStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'memberStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'selfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'areaChartSelfConsumptionSite',
              x: 0,
              y: 147,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChartDouble',
              x: 0,
              y: 202,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        prosumage: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'memberStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'memberStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'memberSelfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'memberSharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'memberPollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'batteryChart',
              x: 0,
              y: 147,
              w: 6,
              h: 48,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'areaChartSelfConsumption',
              x: 6,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChartDouble',
              x: 0,
              y: 98,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        prosumer_battery: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'memberStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'memberStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'memberSelfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'memberSharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'memberPollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'batteryChart',
              x: 0,
              y: 147,
              w: 6,
              h: 48,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'areaChartSelfConsumption',
              x: 6,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChartDouble',
              x: 0,
              y: 202,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        consumer_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalConsumptionCard',
              x: 0,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 3,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 6,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'map',
              x: 9,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        producer_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalProductionCard',
              x: 0,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 3,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 6,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'map',
              x: 9,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'productionStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        producer_shortconsumer_producer_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalConsumptionCard',
              x: 0,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'totalProductionCard',
              x: 2.4,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 4.8,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 16,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'pollutionCard',
              x: 7.2,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'map',
              x: 9.6,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                marginRight: -8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 42,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 42,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
          ],
        },
        prosumer_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'memberStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'memberStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'selfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'areaChartSelfConsumptionSite',
              x: 0,
              y: 147,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        prosumage_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'memberStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'memberStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'memberSelfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'memberSharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'memberPollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'batteryChart',
              x: 0,
              y: 147,
              w: 6,
              h: 48,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'areaChartSelfConsumption',
              x: 6,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        prosumer_battery_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'memberStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'memberStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'memberSelfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'memberSharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'memberPollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'batteryChart',
              x: 0,
              y: 147,
              w: 6,
              h: 48,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'areaChartSelfConsumption',
              x: 6,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
      },
    },
    widgets: {
      default: [
        {
          type: 'headerWeb',
          id: 'headerWeb',
          data: {
            label: 'community-energy',
            breadCrumbs: true,
            navigation: true,
            calendar: true,
            download: false,
            searchBar: true,
            profile: true,
          },
          devID: 'zany-waffle',
          devPath: 'member-energy->default->headerWeb',
        },
        {
          env: 'web',
          type: 'statsOverviewWeb',
          subtype: 'statsOverviewWeb',
          id: 'memberStatsOverviewConsumption',
          totalValue: 'consumption',
          color: 'consumption',
          view: 'member',
          graphDataKeys: [
            {
              key: 'self_consumption_direct',
              percentage: 'consumption_self_consumption_direct_rate',
              label:
                'widgets.memberStatsOverviewConsumption.self_consumption_direct',
            },
            {
              key: 'from_grid',
              percentage: 'consumption_from_grid_rate',
              label: 'widgets.memberStatsOverviewConsumption.from_grid',
            },
          ],
          graphColors: ['self-consumption', 'consumption'],
          title: 'widgets.memberStatsOverviewConsumption.title',
          devID: 'wondrous-giraffe',
          devPath: 'member-energy->default->memberStatsOverviewConsumption',
        },
        {
          env: 'web',
          type: 'statsOverviewWeb',
          subtype: 'statsOverviewWeb',
          id: 'memberStatsOverviewProduction',
          totalValue: 'production',
          color: 'production',
          view: 'member',
          graphDataKeys: [
            {
              key: 'self_consumption_direct',
              percentage: 'production_self_consumption_direct_rate',
              label:
                'widgets.memberStatsOverviewProduction.self_consumption_direct',
            },
            {
              key: 'to_grid',
              percentage: 'production_to_grid_rate',
              label: 'widgets.memberStatsOverviewProduction.to_grid',
            },
          ],
          graphColors: ['self-consumption', 'production'],
          title: 'widgets.memberStatsOverviewProduction.title',
          devID: 'zippy-hedgehog',
          devPath: 'member-energy->default->memberStatsOverviewProduction',
        },
        {
          env: 'web',
          type: 'map',
          gradientBorder: false,
          id: 'map',
          devID: 'witty-kiwi',
          devPath: 'member-energy->default->map',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          detail: false,
          subtype: 'memberSelfSufficiencyCard',
          id: 'memberSelfSufficiencyCard',
          totalValue: 'self_sufficiency_rate',
          icon: 'self_sufficiency',
          descriptionType: 'label',
          description: 'widgets.memberSelfSufficiencyCard.description.default',
          devID: 'nutty-kiwi',
          devPath: 'member-energy->default->memberSelfSufficiencyCard',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          detail: false,
          subtype: 'memberSharedEnergyCard',
          id: 'memberSharedEnergyCard',
          totalValue: 'total_shared',
          icon: 'shared_energy',
          descriptionType: 'web:label+value|label+value',
          description: [
            {
              key: 'widgets.memberSharedEnergyCard.description.key_1',
              value: 'from_shared',
            },
            {
              key: 'widgets.memberSharedEnergyCard.description.key_2',
              value: 'to_shared',
            },
          ],
          devID: 'mirthful-igloo',
          devPath: 'member-energy->default->memberSharedEnergyCard',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          detail: false,
          subtype: 'memberPollutionCard',
          id: 'memberPollutionCard',
          totalValue: 'co2_offset_kgs',
          trees: 'co2_offset_trees',
          icon: 'pollution',
          descriptionValue: 'co2_offset_trees',
          descriptionType: '{{trees}}',
          description: {
            line_1: 'widgets.memberPollutionCard.description.prosumer.line_1',
            line_2: 'widgets.memberPollutionCard.description.prosumer.line_2',
          },
          devID: 'playful-buffet',
          devPath: 'member-energy->default->memberPollutionCard',
        },
        {
          type: 'graphs',
          gradientBorder: false,
          subtype: 'consumptionStackBar',
          id: 'consumptionStackBar',
          totalValue: 'consumption',
          graphKeys: [
            {
              key: 'self_consumption_direct',
              color: 'self-consumption',
              combined: false,
            },
            {
              key: 'self_consumption_battery',
              color: 'battery',
              combined: false,
            },
            {
              key: 'from_shared',
              color: 'consumption',
              combined: true,
            },
            {
              key: 'from_grid',
              color: 'consumptionShared',
              combined: true,
            },
          ],
          combinedKeys: [
            {
              key: 'from_shared',
              color: 'consumption',
              combined: true,
            },
            {
              key: 'from_grid',
              color: 'consumptionShared',
              combined: true,
            },
          ],
          colors: [
            {
              color: 'self-consumption',
              colorShade: '',
            },
            {
              color: 'battery',
              colorShade: '',
            },
            {
              color: 'consumption',
              colorShade: '',
            },
            {
              color: 'consumption',
              colorShade: '60',
            },
          ],
          title: 'widgets.consumptionStackBar.title',
          devID: 'daring-firecracker',
          devPath: 'member-energy->default->consumptionStackBar',
        },
        {
          type: 'graphs',
          gradientBorder: false,
          env: 'web',
          subtype: 'productionStackBar',
          id: 'productionStackBar',
          totalValue: 'production',
          graphKeys: [
            {
              key: 'self_consumption_direct',
              color: 'self-consumption',
              combined: false,
            },
            {
              key: 'production_to_battery',
              color: 'battery',
              combined: false,
            },
            {
              key: 'to_shared',
              color: 'production',
              combined: true,
            },
            {
              key: 'to_grid',
              color: 'productionShared',
              combined: true,
            },
          ],
          combinedKeys: [
            {
              key: 'to_shared',
              color: 'productionShared',
              combined: true,
            },
            {
              key: 'to_grid',
              color: 'productionShared',
              combined: true,
            },
          ],
          colors: [
            {
              color: 'self-consumption',
              colorShade: '',
            },
            {
              color: 'battery',
              colorShade: '',
            },
            {
              color: 'production',
              colorShade: '',
            },
            {
              color: 'productionShared',
              colorShade: '',
            },
          ],
          title: 'widgets.productionStackBar.title',
          devID: 'nutty-kangaroo',
          devPath: 'member-energy->default->productionStackBar',
        },
        {
          id: 'batteryChart',
          title: 'widgets.batteryChart.title',
          type: 'batteryChart',
          gradientBorder: false,
          context: 'member',
          buttons: ['widgets.batteryChart.self_consumption'],
          colors: [
            {
              color: 'self-consumption',
              colorShade: '',
            },
          ],
          db_fields: ['battery_net_flow'],
          devID: 'fantastic-banana',
          devPath: 'member-energy->default->batteryChart',
        },
        {
          env: 'web',
          id: 'areaChartSelfConsumption',
          title: 'widgets.areaChartSelfConsumptionCommunity.title',
          type: 'areaChart',
          subtype: 'areaChart',
          gradientBorder: false,
          view: 'community',
          graphKeys: ['consumption', 'production', 'self_consumption'],
          colors: ['consumption', 'production', 'shared'],
          devID: 'boisterous-lemonade',
          devPath: 'member-energy->default->areaChartSelfConsumption',
        },
        {
          type: 'horizontalChart',
          gradientBorder: false,
          subtype: 'horizontalChart',
          id: 'horizontalChart',
          context: 'energy',
          rankingKpi: 'consumption',
          rankingKpiName: [
            'widgets.horizontalChart.energy.consumption',
            'widgets.horizontalChart.energy.production',
          ],
          graphKeys: [
            [
              'self_consumption_direct',
              'self_consumption_battery',
              'from_shared',
              'from_grid',
            ],
            [
              'self_consumption_direct',
              'production_to_battery',
              'to_shared',
              'to_grid',
            ],
          ],
          combinedKeys: ['from_shared', 'from_grid'],
          buttons: [
            'widgets.horizontalChart.energy.self_consumption_direct',
            'widgets.horizontalChart.energy.self_consumption_battery',
            'widgets.horizontalChart.energy.from_shared',
            'widgets.horizontalChart.energy.from_grid',
          ],
          colors: [
            {
              color: 'self-consumption',
              colorShade: '',
            },
            {
              color: 'battery',
              colorShade: '',
            },
            {
              color: 'consumption',
              colorShade: '',
            },
            {
              color: 'consumption',
              colorShade: '60',
            },
          ],
          title: 'widgets.horizontalChart.energy.title',
          devID: 'maniacal-gumball',
          devPath: 'member-energy->default->horizontalChart',
        },
      ],
      administrator: {
        consumer: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'waggish-yogurt',
            devPath: 'member-energy->administrator->consumer->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalConsumptionCard',
            id: 'totalConsumptionCard',
            totalValue: 'consumption',
            icon: 'house_import',
            descriptionValue: 'consumption',
            descriptionType: 'label',
            description: 'widgets.totalConsumptionCard.description.consumer',
            devID: 'zippy-cat',
            devPath:
              'member-energy->administrator->consumer->totalConsumptionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'label',
            description: 'widgets.sharedEnergyCard.description.consumer.key_1',
            devID: 'kooky-cat',
            devPath: 'member-energy->administrator->consumer->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.consumer.line_1',
              line_2: 'widgets.pollutionCard.description.consumer.line_2',
            },
            devID: 'nocturnal-ferret',
            devPath: 'member-energy->administrator->consumer->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'member-energy',
            devID: 'wild-yogurt',
            devPath: 'member-energy->administrator->consumer->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'angry-pajamas',
            devPath:
              'member-energy->administrator->consumer->consumptionStackBar',
          },
          {
            type: 'horizontalChart',
            gradientBorder: false,
            subtype: 'horizontalChart',
            id: 'horizontalChart',
            context: 'energy',
            rankingKpi: 'consumption',
            rankingKpiName: 'widgets.horizontalChart.energy.consumption',
            graphKeys: ['from_shared', 'from_grid'],
            combinedKeys: ['from_shared', 'from_grid'],
            buttons: [
              'widgets.horizontalChart.energy.from_shared',
              'widgets.horizontalChart.energy.from_grid',
            ],
            colors: [
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.horizontalChart.energy.title',
            devID: 'waggish-gumball',
            devPath: 'member-energy->administrator->consumer->horizontalChart',
          },
        ],
        producer: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'zesty-banana',
            devPath: 'member-energy->administrator->producer->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalProductionCard',
            id: 'totalProductionCard',
            totalValue: 'production',
            icon: 'house_export',
            descriptionValue: 'production',
            descriptionType: 'label',
            description: 'widgets.totalProductionCard.description.producer',
            devID: 'hysterical-pajamas',
            devPath:
              'member-energy->administrator->producer->totalProductionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'keyValue',
            description: [
              {
                key: 'widgets.sharedEnergyCard.description.producer.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.sharedEnergyCard.description.producer.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'luminous-cabbage',
            devPath: 'member-energy->administrator->producer->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.producer.line_1',
              line_2: 'widgets.pollutionCard.description.producer.line_2',
            },
            devID: 'bizarre-cactus',
            devPath: 'member-energy->administrator->producer->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'member-energy',
            devID: 'boisterous-hamburger',
            devPath: 'member-energy->administrator->producer->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: false,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: false,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'boisterous-hamburger',
            devPath:
              'member-energy->administrator->producer->productionStackBar',
          },
          {
            type: 'horizontalChart',
            gradientBorder: false,
            subtype: 'horizontalChart',
            id: 'horizontalChart',
            context: 'energy',
            rankingKpi: 'production',
            rankingKpiName: 'widgets.horizontalChart.energy.production',
            graphKeys: ['production_to_battery', 'to_shared', 'to_grid'],
            combinedKeys: ['to_shared', 'to_grid'],
            buttons: [
              'widgets.horizontalChart.energy.to_shared',
              'widgets.horizontalChart.energy.to_grid',
            ],
            colors: [
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.horizontalChart.energy.title',
            devID: 'witty-kiwi',
            devPath: 'member-energy->administrator->producer->horizontalChart',
          },
        ],
        consumer_producer: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'mystical-dinosaur',
            devPath:
              'member-energy->administrator->consumer_producer->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalConsumptionCard',
            id: 'totalConsumptionCard',
            totalValue: 'consumption',
            icon: 'house_import',
            descriptionValue: 'consumption',
            descriptionType: 'label',
            description: 'widgets.totalConsumptionCard.description.consumer',
            devID: 'fantastic-cactus',
            devPath:
              'member-energy->administrator->consumer_producer->totalConsumptionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalProductionCard',
            id: 'totalProductionCard',
            totalValue: 'production',
            icon: 'house_export',
            descriptionValue: 'production',
            descriptionType: 'label',
            description: 'widgets.totalProductionCard.description.producer',
            devID: 'oddball-saxophone',
            devPath:
              'member-energy->administrator->consumer_producer->totalProductionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'label',
            description:
              'widgets.memberSharedEnergyCard.description.consumer.key_1',
            devID: 'zealous-nacho',
            devPath:
              'member-energy->administrator->consumer_producer->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.producer.line_1',
              line_2: 'widgets.pollutionCard.description.producer.line_2',
            },
            devID: 'witty-dragon',
            devPath:
              'member-energy->administrator->consumer_producer->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'member-energy',
            devID: 'peculiar-quokka',
            devPath: 'member-energy->administrator->consumer_producer->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumptionShared',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'jazzy-espresso',
            devPath:
              'member-energy->administrator->consumer_producer->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'productionShared',
                colorShade: '',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'whimsical-vortex',
            devPath:
              'member-energy->administrator->consumer_producer->productionStackBar',
          },
          {
            type: 'horizontalChartDouble',
            gradientBorder: false,
            subtype: 'horizontalChartDouble',
            id: 'horizontalChartDouble',
            context: 'energy',
            rankingKpi: 'consumption',
            rankingKpiName: [
              'widgets.horizontalChart.energy.consumption',
              'widgets.horizontalChart.energy.production',
            ],
            graphKeys: [
              ['self_consumption_direct', 'from_shared', 'from_grid'],
              ['self_consumption_direct', 'to_shared', 'to_grid'],
            ],
            combinedKeys: [
              ['from_shared', 'from_grid'],
              ['to_shared', 'to_grid'],
            ],
            buttons: [
              'widgets.horizontalChart.energy.self_consumption_direct',
              'widgets.horizontalChart.energy.from_shared',
              'widgets.horizontalChart.energy.from_grid',
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.horizontalChart.energy.title',
            devID: 'unruly-hedgehog',
            devPath:
              'member-energy->administrator->consumer_producer->horizontalChartDouble',
          },
        ],
        prosumer: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'waggish-iceberg',
            devPath: 'member-energy->administrator->prosumer->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.memberStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'consumption'],
            title: 'widgets.memberStatsOverviewConsumption.title',
            devID: 'hysterical-saxophone',
            devPath:
              'member-energy->administrator->prosumer->memberStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.memberStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'production'],
            title: 'widgets.memberStatsOverviewProduction.title',
            devID: 'zesty-cactus',
            devPath:
              'member-energy->administrator->prosumer->memberStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'selfSufficiencyCard',
            id: 'selfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            icon: 'self_sufficiency',
            descriptionType: 'label',
            description: 'widgets.selfSufficiencyCard.description.prosumer',
            devID: 'fantastic-tangerine',
            devPath:
              'member-energy->administrator->prosumer->selfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'web:label+value|label+value',
            description: [
              {
                key: 'widgets.sharedEnergyCard.description.prosumer.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.sharedEnergyCard.description.prosumer.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'whimsical-lemonade',
            devPath: 'member-energy->administrator->prosumer->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.prosumer.line_1',
              line_2: 'widgets.pollutionCard.description.prosumer.line_2',
            },
            devID: 'ferocious-enchilada',
            devPath: 'member-energy->administrator->prosumer->pollutionCard',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'kooky-flamingo',
            devPath:
              'member-energy->administrator->prosumer->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'zesty-dinosaur',
            devPath:
              'member-energy->administrator->prosumer->productionStackBar',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumptionSite',
            title: 'widgets.areaChartSelfConsumptionSite.title',
            type: 'areachart',
            subtype: 'areachart',
            gradientBorder: false,
            colors: ['consumption', 'production', 'self-consumption'],
            graphKeys: ['consumption', 'production', 'self_consumption'],
            devID: 'golden-narwhal',
            devPath:
              'member-energy->administrator->prosumer->areaChartSelfConsumptionSite',
          },
          {
            type: 'horizontalChartDouble',
            gradientBorder: false,
            subtype: 'horizontalChartDouble',
            id: 'horizontalChartDouble',
            context: 'energy',
            rankingKpi: 'consumption',
            kpiNameLabels: ['consumption', 'production'],
            rankingKpiName: [
              'widgets.horizontalChart.energy.consumption',
              'widgets.horizontalChart.energy.production',
            ],
            graphKeys: [
              ['self_consumption_direct', 'from_shared', 'from_grid'],
              ['self_consumption_direct', 'to_shared', 'to_grid'],
            ],
            combinedKeys: [
              ['from_shared', 'from_grid'],
              ['to_shared', 'to_grid'],
            ],
            buttons: [
              'widgets.horizontalChart.energy.self_consumption_direct',
              'widgets.horizontalChart.energy.from_shared',
              'widgets.horizontalChart.energy.from_grid',
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.horizontalChart.energy.title',
            devID: 'wondrous-mango',
            devPath:
              'member-energy->administrator->prosumer->horizontalChartDouble',
          },
        ],
        prosumage: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'daring-lollipop',
            devPath: 'member-energy->administrator->prosumage->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'self_consumption_battery',
                percentage: 'consumption_self_consumption_battery_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_battery',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.memberStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'consumption'],
            title: 'widgets.memberStatsOverviewConsumption.title',
            devID: 'frisky-firecracker',
            devPath:
              'member-energy->administrator->prosumage->memberStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'production_to_battery',
                percentage: 'production_to_battery_rate',
                label:
                  'widgets.memberStatsOverviewProduction.production_to_battery',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.memberStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'productionGrid', ],
            title: 'widgets.memberStatsOverviewProduction.title',
            devID: 'bizarre-dinosaur',
            devPath:
              'member-energy->administrator->prosumage->memberStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            devID: 'zippy-mango',
            devPath: 'member-energy->administrator->prosumage->map',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberSelfSufficiencyCard',
            id: 'memberSelfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            icon: 'self_sufficiency',
            descriptionType: 'label',
            description:
              'widgets.memberSelfSufficiencyCard.description.default',
            devID: 'ludicrous-waffle',
            devPath:
              'member-energy->administrator->prosumage->memberSelfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberSharedEnergyCard',
            id: 'memberSharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'web:label+value|label+value',
            description: [
              {
                key: 'widgets.memberSharedEnergyCard.description.key_1',
                value: 'consumption',
              },
              {
                key: 'widgets.memberSharedEnergyCard.description.key_2',
                value: 'production',
              },
            ],
            devID: 'nocturnal-vortex',
            devPath:
              'member-energy->administrator->prosumage->memberSharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberPollutionCard',
            id: 'memberPollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.memberPollutionCard.description.prosumer.line_1',
              line_2: 'widgets.memberPollutionCard.description.prosumer.line_2',
            },
            devID: 'boisterous-dragon',
            devPath:
              'member-energy->administrator->prosumage->memberPollutionCard',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'self_consumption_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'quirksome-narwhal',
            devPath:
              'member-energy->administrator->prosumage->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'production_to_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'productionShared',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'wondrous-jellyfish',
            devPath:
              'member-energy->administrator->prosumage->productionStackBar',
          },
          {
            id: 'batteryChart',
            title: 'widgets.batteryChart.title',
            type: 'batteryChart',
            gradientBorder: false,
            context: 'member',
            buttons: ['widgets.batteryChart.self_consumption'],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
            ],
            db_fields: ['battery_net_flow'],
            devID: 'boisterous-rhinoceros',
            devPath: 'member-energy->administrator->prosumage->batteryChart',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumption',
            title: 'widgets.areaChartSelfConsumptionCommunity.title',
            type: 'areaChart',
            subtype: 'areaChart',
            gradientBorder: false,
            view: 'community',
            graphKeys: ['consumption', 'production', 'self_consumption'],
            colors: ['consumption', 'production', 'shared'],
            devID: 'mystical-banana',
            devPath:
              'member-energy->administrator->prosumage->areaChartSelfConsumption',
          },
          {
            type: 'horizontalChartDouble',
            gradientBorder: false,
            subtype: 'horizontalChartDouble',
            id: 'horizontalChartDouble',
            context: 'energy',
            rankingKpi: 'consumption',
            kpiNameLabels: ['consumption', 'production'],
            rankingKpiName: [
              'widgets.horizontalChart.energy.consumption',
              'widgets.horizontalChart.energy.production',
            ],
            graphKeys: [
              [
                'self_consumption_direct',
                'self_consumption_battery',
                'from_shared',
                'from_grid',
              ],
              [
                'self_consumption_direct',
                'production_to_battery',
                'to_shared',
                'to_grid',
              ],
            ],
            combinedKeys: [
              ['from_shared', 'from_grid'],
              ['to_shared', 'to_grid'],
            ],
            buttons: [
              'widgets.horizontalChart.energy.self_consumption_direct',
              'widgets.horizontalChart.energy.self_consumption_battery',
              'widgets.horizontalChart.energy.from_shared',
              'widgets.horizontalChart.energy.from_grid',
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.horizontalChart.energy.title',
            devID: 'clumsy-saxophone',
            devPath:
              'member-energy->administrator->prosumage->horizontalChartDouble',
          },
        ],
        prosumer_battery: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'zealous-yogurt',
            devPath:
              'member-energy->administrator->prosumer_battery->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'self_consumption_battery',
                percentage: 'consumption_self_consumption_battery_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_battery',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.memberStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'consumption'],
            title: 'widgets.memberStatsOverviewConsumption.title',
            devID: 'rampaging-nacho',
            devPath:
              'member-energy->administrator->prosumer_battery->memberStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'production_to_battery',
                percentage: 'production_to_battery_rate',
                label:
                  'widgets.memberStatsOverviewProduction.production_to_battery',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.memberStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'productionGrid'],
            title: 'widgets.memberStatsOverviewProduction.title',
            devID: 'ferocious-zeppelin',
            devPath:
              'member-energy->administrator->prosumer_battery->memberStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            devID: 'witty-zeppelin',
            devPath: 'member-energy->administrator->prosumer_battery->map',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberSelfSufficiencyCard',
            id: 'memberSelfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            icon: 'self_sufficiency',
            descriptionType: 'label',
            description:
              'widgets.memberSelfSufficiencyCard.description.default',
            devID: 'giddy-penguin',
            devPath:
              'member-energy->administrator->prosumer_battery->memberSelfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberSharedEnergyCard',
            id: 'memberSharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'web:label+value|label+value',
            description: [
              {
                key: 'widgets.memberSharedEnergyCard.description.key_1',
                value: 'consumption',
              },
              {
                key: 'widgets.memberSharedEnergyCard.description.key_2',
                value: 'production',
              },
            ],
            devID: 'peculiar-spoon',
            devPath:
              'member-energy->administrator->prosumer_battery->memberSharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberPollutionCard',
            id: 'memberPollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.memberPollutionCard.description.prosumer.line_1',
              line_2: 'widgets.memberPollutionCard.description.prosumer.line_2',
            },
            devID: 'oddball-penguin',
            devPath:
              'member-energy->administrator->prosumer_battery->memberPollutionCard',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'self_consumption_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'turbulent-tangerine',
            devPath:
              'member-energy->administrator->prosumer_battery->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'production_to_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'productionShared',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'jubilant-buffet',
            devPath:
              'member-energy->administrator->prosumer_battery->productionStackBar',
          },
          {
            id: 'batteryChart',
            title: 'widgets.batteryChart.title',
            type: 'batteryChart',
            gradientBorder: false,
            context: 'member',
            buttons: ['widgets.batteryChart.self_consumption'],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
            ],
            db_fields: ['battery_net_flow'],
            devID: 'rambunctious-cactus',
            devPath:
              'member-energy->administrator->prosumer_battery->batteryChart',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumption',
            title: 'widgets.areaChartSelfConsumptionCommunity.title',
            type: 'areaChart',
            subtype: 'areaChart',
            gradientBorder: false,
            view: 'community',
            graphKeys: ['consumption', 'production', 'self_consumption'],
            colors: ['consumption', 'production', 'shared'],
            devID: 'happy-ferret',
            devPath:
              'member-energy->administrator->prosumer_battery->areaChartSelfConsumption',
          },
          {
            type: 'horizontalChartDouble',
            gradientBorder: false,
            subtype: 'horizontalChartDouble',
            id: 'horizontalChartDouble',
            context: 'energy',
            rankingKpi: 'consumption',
            kpiNameLabels: ['consumption', 'production'],
            rankingKpiName: [
              'widgets.horizontalChart.energy.consumption',
              'widgets.horizontalChart.energy.production',
            ],
            graphKeys: [
              [
                'self_consumption_direct',
                'self_consumption_battery',
                'from_shared',
                'from_grid',
              ],
              [
                'self_consumption_direct',
                'production_to_battery',
                'to_shared',
                'to_grid',
              ],
            ],
            combinedKeys: [
              ['from_shared', 'from_grid'],
              ['to_shared', 'to_grid'],
            ],
            buttons: [
              'widgets.horizontalChart.energy.self_consumption_direct',
              'widgets.horizontalChart.energy.self_consumption_battery',
              'widgets.horizontalChart.energy.from_shared',
              'widgets.horizontalChart.energy.from_grid',
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.horizontalChart.energy.title',
            devID: 'whacky-narwhal',
            devPath:
              'member-energy->administrator->prosumer_battery->horizontalChartDouble',
          },
        ],
        consumer_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'zany-hamburger',
            devPath: 'member-energy->administrator->consumer_short->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalConsumptionCard',
            id: 'totalConsumptionCard',
            totalValue: 'consumption',
            icon: 'house_import',
            descriptionValue: 'consumption',
            descriptionType: 'label',
            description: 'widgets.totalConsumptionCard.description.consumer',
            devID: 'glowing-marshmallow',
            devPath:
              'member-energy->administrator->consumer_short->totalConsumptionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'label',
            description: 'widgets.sharedEnergyCard.description.consumer.key_1',
            devID: 'daring-iceberg',
            devPath:
              'member-energy->administrator->consumer_short->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.consumer.line_1',
              line_2: 'widgets.pollutionCard.description.consumer.line_2',
            },
            devID: 'waggish-banana',
            devPath:
              'member-energy->administrator->consumer_short->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'member-energy',
            devID: 'whimsical-jellyfish',
            devPath: 'member-energy->administrator->consumer_short->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'frisky-hedgehog',
            devPath:
              'member-energy->administrator->consumer_short->consumptionStackBar',
          },
        ],
        producer_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'jazzy-xylophone',
            devPath: 'member-energy->administrator->producer_short->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalProductionCard',
            id: 'totalProductionCard',
            totalValue: 'production',
            icon: 'house_export',
            descriptionValue: 'production',
            descriptionType: 'label',
            description: 'widgets.totalProductionCard.description.producer',
            devID: 'vivacious-hamburger',
            devPath:
              'member-energy->administrator->producer_short->totalProductionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'keyValue',
            description: [
              {
                key: 'widgets.sharedEnergyCard.description.producer.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.sharedEnergyCard.description.producer.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'wild-saxophone',
            devPath:
              'member-energy->administrator->producer_short->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.producer.line_1',
              line_2: 'widgets.pollutionCard.description.producer.line_2',
            },
            devID: 'golden-vortex',
            devPath:
              'member-energy->administrator->producer_short->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'member-energy',
            devID: 'unruly-narwhal',
            devPath: 'member-energy->administrator->producer_short->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: false,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: false,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'silly-espresso',
            devPath:
              'member-energy->administrator->producer_short->productionStackBar',
          },
        ],
        producer_shortconsumer_producer_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'fantastic-octopus',
            devPath:
              'member-energy->administrator->consumer_producer_short->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalConsumptionCard',
            id: 'totalConsumptionCard',
            totalValue: 'consumption',
            icon: 'house_import',
            descriptionValue: 'consumption',
            descriptionType: 'label',
            description: 'widgets.totalConsumptionCard.description.consumer',
            devID: 'cannibal-dinosaur',
            devPath:
              'member-energy->administrator->consumer_producer_short->totalConsumptionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalProductionCard',
            id: 'totalProductionCard',
            totalValue: 'production',
            icon: 'house_export',
            descriptionValue: 'production',
            descriptionType: 'label',
            description: 'widgets.totalProductionCard.description.producer',
            devID: 'vivacious-jellyfish',
            devPath:
              'member-energy->administrator->consumer_producer_short->totalProductionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'label',
            description:
              'widgets.memberSharedEnergyCard.description.consumer.key_1',
            devID: 'rampaging-obelisk',
            devPath:
              'member-energy->administrator->consumer_producer_short->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.producer.line_1',
              line_2: 'widgets.pollutionCard.description.producer.line_2',
            },
            devID: 'zesty-ukulele',
            devPath:
              'member-energy->administrator->consumer_producer_short->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'member-energy',
            devID: 'zippy-octopus',
            devPath:
              'member-energy->administrator->consumer_producer_short->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumptionShared',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'clumsy-cabbage',
            devPath:
              'member-energy->administrator->consumer_producer_short->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'productionShared',
                colorShade: '',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'mirthful-tangerine',
            devPath:
              'member-energy->administrator->consumer_producer_short->productionStackBar',
          },
        ],
        prosumer_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'jubilant-quokka',
            devPath: 'member-energy->administrator->prosumer_short->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.memberStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'consumption'],
            title: 'widgets.memberStatsOverviewConsumption.title',
            devID: 'zesty-igloo',
            devPath:
              'member-energy->administrator->prosumer_short->memberStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.memberStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'production'],
            title: 'widgets.memberStatsOverviewProduction.title',
            devID: 'zany-buffet',
            devPath:
              'member-energy->administrator->prosumer_short->memberStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'selfSufficiencyCard',
            id: 'selfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            icon: 'self_sufficiency',
            descriptionType: 'label',
            description: 'widgets.selfSufficiencyCard.description.prosumer',
            devID: 'fantastic-ferret',
            devPath:
              'member-energy->administrator->prosumer_short->selfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'web:label+value|label+value',
            description: [
              {
                key: 'widgets.sharedEnergyCard.description.prosumer.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.sharedEnergyCard.description.prosumer.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'mystical-cabbage',
            devPath:
              'member-energy->administrator->prosumer_short->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.prosumer.line_1',
              line_2: 'widgets.pollutionCard.description.prosumer.line_2',
            },
            devID: 'happy-kangaroo',
            devPath:
              'member-energy->administrator->prosumer_short->pollutionCard',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'whimsical-quokka',
            devPath:
              'member-energy->administrator->prosumer_short->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'zesty-igloo',
            devPath:
              'member-energy->administrator->prosumer_short->productionStackBar',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumptionSite',
            title: 'widgets.areaChartSelfConsumptionSite.title',
            type: 'areachart',
            subtype: 'areachart',
            gradientBorder: false,
            colors: ['consumption', 'production', 'self-consumption'],
            graphKeys: ['consumption', 'production', 'self_consumption'],
            devID: 'whacky-enchilada',
            devPath:
              'member-energy->administrator->prosumer_short->areaChartSelfConsumptionSite',
          },
        ],
        prosumage_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'turbulent-ferret',
            devPath: 'member-energy->administrator->prosumage_short->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_direct',
              },

              {
                key: 'self_consumption_battery',
                percentage: 'consumption_self_consumption_battery_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_battery',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.memberStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'consumption'],
            title: 'widgets.memberStatsOverviewConsumption.title',
            devID: 'ludicrous-mango',
            devPath:
              'member-energy->administrator->prosumage_short->memberStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'production_to_battery',
                percentage: 'production_to_battery_rate',
                label:
                  'widgets.memberStatsOverviewProduction.production_to_battery',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.memberStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'productionGrid'],
            title: 'widgets.memberStatsOverviewProduction.title',
            devID: 'zealous-jellyfish',
            devPath:
              'member-energy->administrator->prosumage_short->memberStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            devID: 'vivacious-dragon',
            devPath: 'member-energy->administrator->prosumage_short->map',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberSelfSufficiencyCard',
            id: 'memberSelfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            icon: 'self_sufficiency',
            descriptionType: 'label',
            description:
              'widgets.memberSelfSufficiencyCard.description.default',
            devID: 'happy-iceberg',
            devPath:
              'member-energy->administrator->prosumage_short->memberSelfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberSharedEnergyCard',
            id: 'memberSharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'web:label+value|label+value',
            description: [
              {
                key: 'widgets.memberSharedEnergyCard.description.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.memberSharedEnergyCard.description.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'impish-zeppelin',
            devPath:
              'member-energy->administrator->prosumage_short->memberSharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberPollutionCard',
            id: 'memberPollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.memberPollutionCard.description.prosumer.line_1',
              line_2: 'widgets.memberPollutionCard.description.prosumer.line_2',
            },
            devID: 'kooky-firecracker',
            devPath:
              'member-energy->administrator->prosumage_short->memberPollutionCard',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'self_consumption_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'whimsical-kangaroo',
            devPath:
              'member-energy->administrator->prosumage_short->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'production_to_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'productionShared',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'happy-firecracker',
            devPath:
              'member-energy->administrator->prosumage_short->productionStackBar',
          },
          {
            id: 'batteryChart',
            title: 'widgets.batteryChart.title',
            type: 'batteryChart',
            gradientBorder: false,
            context: 'member',
            buttons: ['widgets.batteryChart.self_consumption'],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
            ],
            db_fields: ['battery_net_flow'],
            devID: 'rowdy-espresso',
            devPath:
              'member-energy->administrator->prosumage_short->batteryChart',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumption',
            title: 'widgets.areaChartSelfConsumptionCommunity.title',
            type: 'areaChart',
            subtype: 'areaChart',
            gradientBorder: false,
            view: 'community',
            graphKeys: ['consumption', 'production', 'self_consumption'],
            colors: ['consumption', 'production', 'shared'],
            devID: 'mirthful-pajamas',
            devPath:
              'member-energy->administrator->prosumage_short->areaChartSelfConsumption',
          },
        ],
        prosumer_battery_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'glowing-xylophone',
            devPath:
              'member-energy->administrator->prosumer_battery_short->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'self_consumption_battery',
                percentage: 'consumption_self_consumption_battery_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_battery',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.memberStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'consumption'],
            title: 'widgets.memberStatsOverviewConsumption.title',
            devID: 'rampaging-igloo',
            devPath:
              'member-energy->administrator->prosumer_battery_short->memberStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'production_to_battery',
                percentage: 'production_to_battery_rate',
                label:
                  'widgets.memberStatsOverviewProduction.production_to_battery',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.memberStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'productionGrid'],
            title: 'widgets.memberStatsOverviewProduction.title',
            devID: 'daring-narwhal',
            devPath:
              'member-energy->administrator->prosumer_battery_short->memberStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            devID: 'rowdy-shoe',
            devPath:
              'member-energy->administrator->prosumer_battery_short->map',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberSelfSufficiencyCard',
            id: 'memberSelfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            icon: 'self_sufficiency',
            descriptionType: 'label',
            description:
              'widgets.memberSelfSufficiencyCard.description.default',
            devID: 'jubilant-ukulele',
            devPath:
              'member-energy->administrator->prosumer_battery_short->memberSelfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberSharedEnergyCard',
            id: 'memberSharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'web:label+value|label+value',
            description: [
              {
                key: 'widgets.memberSharedEnergyCard.description.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.memberSharedEnergyCard.description.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'daring-jigsaw',
            devPath:
              'member-energy->administrator->prosumer_battery_short->memberSharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberPollutionCard',
            id: 'memberPollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.memberPollutionCard.description.prosumer.line_1',
              line_2: 'widgets.memberPollutionCard.description.prosumer.line_2',
            },
            devID: 'zany-lighthouse',
            devPath:
              'member-energy->administrator->prosumer_battery_short->memberPollutionCard',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'self_consumption_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'nutty-cabbage',
            devPath:
              'member-energy->administrator->prosumer_battery_short->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'production_to_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'productionShared',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'zany-waffle',
            devPath:
              'member-energy->administrator->prosumer_battery_short->productionStackBar',
          },
          {
            id: 'batteryChart',
            title: 'widgets.batteryChart.title',
            type: 'batteryChart',
            gradientBorder: false,
            context: 'member',
            buttons: ['widgets.batteryChart.self_consumption'],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
            ],
            db_fields: ['battery_net_flow'],
            devID: 'whimsical-buffet',
            devPath:
              'member-energy->administrator->prosumer_battery_short->batteryChart',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumption',
            title: 'widgets.areaChartSelfConsumptionCommunity.title',
            type: 'areaChart',
            subtype: 'areaChart',
            gradientBorder: false,
            view: 'community',
            graphKeys: ['consumption', 'production', 'self_consumption'],
            colors: ['consumption', 'production', 'shared'],
            devID: 'nocturnal-octopus',
            devPath:
              'member-energy->administrator->prosumer_battery_short->areaChartSelfConsumption',
          },
        ],
      },
    },
  },

  // ######### SITE #########

  {
    name: 'site',
    needsAuth: true,
    needsID: true,
    active: {
      default: true,
    },
    visible: {
      default: false,
    },
    sideBar: {
      default: false,
    },
    pullToRefresh: {
      default: false,
    },
    apis: {
      default: ['EntitiesResolver', 'SiteOverviewResolver', 'KpiSiteResolver'],
    },
    layouts: {
      default: {
        lg: [
          {
            i: 'headerWeb',
            x: 0,
            y: 0,
            w: 12,
            h: 10,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'siteBenefitsCard',
            x: 0,
            y: 12,
            w: 4,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingRight: 8,
              height: '100%',
            },
          },
          {
            i: 'siteEnergyFlowsCard',
            x: 4,
            y: 12,
            w: 4,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingRight: 8,
              paddingLeft: 8,
              height: '100%',
            },
          },
          {
            i: 'map',
            x: 8,
            y: 12,
            w: 4,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingLeft: 8,
              height: '100%',
            },
          },
          {
            i: 'sitePaymentsTable',
            x: 0,
            y: 42,
            w: 9,
            h: 38,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'SiteDetailsParameters',
            x: 10,
            y: 42,
            w: 3,
            h: 38,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'siteMembersList',
            x: 10,
            y: 82,
            w: 3,
            h: 37,
            static: true,
            draggable: false,
            resize: false,
          },
        ],
      },
    },
    widgets: {
      default: [
        {
          type: 'headerWeb',
          id: 'headerWeb',
          data: {
            label: 'site',
            breadCrumbs: true,
            navigation: true,
            calendar: false,
            download: false,
            searchBar: true,
            profile: true,
          },
          devID: 'wondrous-jigsaw',
          devPath: 'site->default->headerWeb',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          subtype: 'siteBenefitsCard',
          id: 'siteBenefitsCard',
          totalValue: 'total_benefits',
          icon: 'euro',
          descriptionCallback: 'siteEconomics',
          descriptionType: 'label',
          description: 'widgets.savingsCard.description.default',
          devID: 'cannibal-rhinoceros',
          devPath: 'site->default->siteBenefitsCard',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          subtype: 'siteEnergyFlowsCard',
          id: 'siteEnergyFlowsCard',
          icon: 'euro',
          descriptionCallback: 'siteEnergy',
          descriptionType: 'siteType[label+value]',
          description: {
            default: [
              {
                key: 'widgets.siteEnergyFlowsCard.description.default.consumption',
                value: 'consumption',
              },
            ],
            consumer: [
              {
                key: 'widgets.siteEnergyFlowsCard.description.default.consumption',
                value: 'consumption',
              },
            ],
            producer: [
              {
                key: 'widgets.siteEnergyFlowsCard.description.default.production',
                value: 'production',
              },
            ],
            prosumage: [
              {
                key: 'widgets.siteEnergyFlowsCard.description.default.consumption',
                value: 'consumption',
              },
              {
                key: 'widgets.siteEnergyFlowsCard.description.default.production',
                value: 'production',
              },
              {
                key: 'widgets.siteEnergyFlowsCard.description.default.self_consumption',
                value: 'self_consumption',
              },
            ],
            prosumer: [
              {
                key: 'widgets.siteEnergyFlowsCard.description.default.consumption',
                value: 'consumption',
              },
              {
                key: 'widgets.siteEnergyFlowsCard.description.default.production',
                value: 'production',
              },
              {
                key: 'widgets.siteEnergyFlowsCard.description.default.self_consumption',
                value: 'self_consumption',
              },
            ],
          },
          devID: 'impish-giraffe',
          devPath: 'site->default->siteEnergyFlowsCard',
        },
        {
          env: 'web',
          type: 'map',
          gradientBorder: false,
          id: 'map',
          context: 'site',
          devID: 'mirthful-tangerine',
          devPath: 'site->default->map',
        },
        {
          type: 'sitePaymentsTable',
          id: 'sitePaymentsTable',
          devID: 'ludicrous-lemonade',
          devPath: 'site->default->sitePaymentsTable',
        },
        {
          type: 'SiteDetailsParameters',
          id: 'SiteDetailsParameters',
          data: {
            title: 'widgets.siteDetailsParameters.title',
            siteName: 'widgets.siteDetailsParameters.siteName',
            joinDate: 'widgets.siteDetailsParameters.joinDate',
            ownership: 'widgets.siteDetailsParameters.ownership',
            type: 'widgets.siteDetailsParameters.type',
            productionBenefits:
              'widgets.siteDetailsParameters.productionBenefits',
          },
          devID: 'wondrous-xylophone',
          devPath: 'site->default->SiteDetailsParameters',
        },
        {
          type: 'siteMembersList',
          id: 'siteMembersList',
          devID: 'giddy-banana',
          devPath: 'site->default->siteMembersList',
        },
      ],
    },
  },

  // ######### SITE ECONOMICS #########

  {
    name: 'site-economics',
    active: {
      default: true,
    },
    visible: {
      default: false,
    },
    footer: {
      default: false,
    },
    header: {
      default: false,
    },
    datePicker: {
      default: false,
    },
    pullToRefresh: {
      default: false,
    },
    needsAuth: true,
    needsID: true,
    apis: {
      default: ['EntitiesResolver', 'SiteOverviewResolver', 'KpiSiteResolver'],
    },
    layouts: {
      default: {
        lg: [
          {
            i: 'headerWeb',
            x: 0,
            y: 0,
            w: 12,
            h: 10,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'savingsCard',
            x: 0,
            y: 13,
            w: 12,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              minHeight: '100%',
              height: '100%',
            },
          },
          {
            i: 'savingsStackBar',
            x: 0,
            y: 42,
            w: 12,
            h: 52,
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',
              boxSizing: 'border-box',
            },
          },
        ],
      },
    },
    widgets: {
      default: [
        {
          type: 'headerWeb',
          id: 'headerWeb',
          data: {
            label: 'site-economics',
            breadCrumbs: true,
            navigation: true,
            calendar: true,
            download: false,
            searchBar: true,
            profile: true,
          },
          devID: 'witty-saxophone',
          devPath: 'site-economics->default->headerWeb',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          subtype: 'savingsCard',
          id: 'savingsCard',
          totalValue: 'total_benefits',
          icon: 'savings',
          descriptionType: 'label',
          descriptionCallback: 'SiteTotalEstimatedBenefits',
          description: 'widgets.savingsCard.description.default',
          devID: 'vivacious-enchilada',
          devPath: 'site-economics->default->savingsCard',
        },
        {
          type: 'graphs',
          gradientBorder: false,
          subtype: 'savingsStackBar',
          id: 'savingsStackBar',
          graphKeys: [
            {
              key: 'revenues',
              color: 'primary',
              combined: false,
            },
          ],
          combinedKeys: [],
          colors: ['primary'],
          title: 'widgets.savingsStackBar.title',
          devID: 'zany-quokka',
          devPath: 'site-economics->default->savingsStackBar',
        },
      ],
    },
  },

  // ######### SITE ENERGY #########

  {
    name: 'site-energy',
    active: {
      default: true,
    },
    visible: {
      default: false,
    },
    footer: {
      default: false,
    },
    header: {
      default: false,
    },
    datePicker: {
      default: false,
    },
    pullToRefresh: {
      default: false,
    },
    needsAuth: true,
    needsID: true,
    apis: {
      default: ['EntitiesResolver', 'SiteOverviewResolver', 'KpiSiteResolver'],
    },
    layouts: {
      default: {
        lg: [
          {
            i: 'headerWeb',
            x: 0,
            y: 0,
            w: 12,
            h: 10,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'selfSufficiencyCard',
            x: 0,
            y: 13,
            w: 4,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',
              paddingRight: 8,
            },
          },
          {
            i: 'sharedEnergyCard',
            x: 4,
            y: 13,
            w: 4,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',
              paddingRight: 8,
              paddingLeft: 8,
            },
          },
          {
            i: 'pollutionCard',
            x: 9,
            y: 13,
            w: 4,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',
              paddingLeft: 8,
            },
          },
          {
            i: 'consumptionStackBar',
            x: 0,
            y: 42,
            w: 6,
            h: 53,
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',
              boxSizing: 'border-box',
              paddingRight: 8,
            },
          },
          {
            i: 'productionStackBar',
            x: 7,
            y: 42,
            w: 6,
            h: 53,
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',
              paddingLeft: 8,
              boxSizing: 'border-box',
            },
          },
          {
            i: 'selfConsumptionStackBar',
            x: 0,
            y: 95,
            w: 12,
            h: 53,
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',
            },
          },
        ],
      },
      administrator: {
        consumer: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalConsumptionCard',
              x: 0,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 3,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 6,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'map',
              x: 9,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        producer: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalProductionCard',
              x: 0,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 6,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'map',
              x: 9,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'productionStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        prosumer: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'siteStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'siteStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'selfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'areaChartSelfConsumptionSite',
              x: 0,
              y: 147,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        prosumage: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'siteStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'siteStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'selfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
                paddingLeft: 8,
              },
            },
            {
              i: 'batteryChart',
              x: 0,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'areaChartSelfConsumptionSite',
              x: 6,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
      },
    },
    widgets: {
      default: [
        {
          type: 'headerWeb',
          id: 'headerWeb',
          data: {
            label: 'site-energy',
            breadCrumbs: true,
            navigation: true,
            calendar: true,
            download: false,
            searchBar: true,
            profile: true,
          },
          devID: 'mystical-rhinoceros',
          devPath: 'site-energy->default->headerWeb',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          subtype: 'selfSufficiencyCard',
          id: 'selfSufficiencyCard',
          totalValue: 'self_sufficiency_rate',
          icon: 'self_sufficiency',
          descriptionType: 'label',
          description: 'widgets.selfSufficiencyCard.description.prosumer',
          devID: 'cannibal-spoon',
          devPath: 'site-energy->default->selfSufficiencyCard',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          subtype: 'sharedEnergyCard',
          id: 'sharedEnergyCard',
          totalValue: 'total_shared',
          icon: 'shared_energy',
          descriptionType: 'web:label+value|label+value',
          description: [
            {
              key: 'widgets.sharedEnergyCard.description.prosumer.key_1',
              value: 'from_shared',
            },
            {
              key: 'widgets.sharedEnergyCard.description.prosumer.key_2',
              value: 'to_shared',
            },
          ],
          devID: 'jubilant-nacho',
          devPath: 'site-energy->default->sharedEnergyCard',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          subtype: 'pollutionCard',
          id: 'pollutionCard',
          totalValue: 'co2_offset_kgs',
          trees: 'co2_offset_trees',
          icon: 'pollution',
          descriptionValue: 'co2_offset_trees',
          descriptionType: '{{trees}}',
          description: {
            line_1: 'widgets.pollutionCard.description.prosumer.line_1',
            line_2: 'widgets.pollutionCard.description.prosumer.line_2',
          },
          devID: 'silly-nacho',
          devPath: 'site-energy->default->pollutionCard',
        },
        {
          env: 'web',
          type: 'map',
          gradientBorder: false,
          id: 'map',
          context: 'site-energy',
          devID: 'peculiar-vortex',
          devPath: 'site-energy->default->map',
        },
        {
          type: 'graphs',
          gradientBorder: false,
          subtype: 'consumptionStackBar',
          id: 'consumptionStackBar',
          totalValue: 'consumption',
          graphKeys: [
            {
              key: 'self_consumption_direct',
              color: 'self-consumption',
              combined: false,
            },
            {
              key: 'self_consumption_battery',
              color: 'battery',
              combined: false,
            },
            {
              key: 'from_shared',
              color: 'consumption',
              combined: false,
            },
            {
              key: 'from_grid',
              color: 'consumptionShared',
              combined: false,
            },
          ],
          colors: [
            {
              color: 'self-consumption',
              colorShade: '',
            },
            {
              color: 'battery',
              colorShade: '',
            },
            {
              color: 'consumption',
              colorShade: '',
            },
            {
              color: 'consumption',
              colorShade: '60',
            },
          ],
          title: 'widgets.consumptionStackBar.title',
          devID: 'hysterical-cactus',
          devPath: 'site-energy->default->consumptionStackBar',
        },
        {
          type: 'graphs',
          gradientBorder: false,
          env: 'web',
          subtype: 'productionStackBar',
          id: 'productionStackBar',
          totalValue: 'production',
          graphKeys: [
            {
              key: 'self_consumption_direct',
              color: 'self-consumption',
              combined: false,
            },
            {
              key: 'production_to_battery',
              color: 'battery',
              combined: false,
            },
            {
              key: 'to_shared',
              color: 'production',
              combined: true,
            },
            {
              key: 'to_grid',
              color: 'productionShared',
              combined: true,
            },
          ],
          combinedKey: [
            {
              key: 'to_shared',
              color: 'production',
              combined: true,
            },
            {
              key: 'to_grid',
              color: 'productionShared',
              combined: true,
            },
          ],
          colors: [
            {
              color: 'self-consumption',
              colorShade: '',
            },
            {
              color: 'battery',
              colorShade: '',
            },
            {
              color: 'production',
              colorShade: '',
            },
            {
              color: 'productionShared',
              colorShade: '60',
            },
          ],
          title: 'widgets.productionStackBar.title',
          devID: 'luminous-buffet',
          devPath: 'site-energy->default->productionStackBar',
        },
        {
          env: 'web',
          id: 'areaChartSelfConsumptionSite',
          title: 'widgets.areaChartSelfConsumptionSite.title',
          type: 'areachart',
          subtype: 'areachart',
          gradientBorder: false,
          colors: ['consumption', 'production', 'self-consumption'],
          graphKeys: ['consumption', 'production', 'self_consumption'],
          devID: 'frisky-narwhal',
          devPath: 'site-energy->default->areaChartSelfConsumptionSite',
        },
      ],
      administrator: {
        consumer: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'playful-pajamas',
            devPath: 'site-energy->administrator->consumer->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalConsumptionCard',
            id: 'totalConsumptionCard',
            totalValue: 'consumption',
            icon: 'house_import',
            descriptionValue: 'consumption',
            descriptionType: 'label',
            description: 'widgets.totalConsumptionCard.description.consumer',
            devID: 'quirksome-rhinoceros',
            devPath:
              'site-energy->administrator->consumer->totalConsumptionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'label',
            description: 'widgets.sharedEnergyCard.description.consumer.key_1',
            devID: 'unruly-marshmallow',
            devPath: 'site-energy->administrator->consumer->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.consumer.line_1',
              line_2: 'widgets.pollutionCard.description.consumer.line_2',
            },
            devID: 'maniacal-cabbage',
            devPath: 'site-energy->administrator->consumer->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'site-energy',
            devID: 'wondrous-jellyfish',
            devPath: 'site-energy->administrator->consumer->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'rampaging-glove',
            devPath:
              'site-energy->administrator->consumer->consumptionStackBar',
          },
        ],
        producer: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'luminous-xylophone',
            devPath: 'site-energy->administrator->producer->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalProductionCard',
            id: 'totalProductionCard',
            totalValue: 'production',
            icon: 'house_export',
            descriptionValue: 'production',
            descriptionType: 'label',
            description: 'widgets.totalProductionCard.description.producer',
            devID: 'luminous-igloo',
            devPath:
              'site-energy->administrator->producer->totalProductionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'keyValue',
            description: [
              {
                key: 'widgets.sharedEnergyCard.description.producer.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.sharedEnergyCard.description.producer.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'witty-lollipop',
            devPath: 'site-energy->administrator->producer->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.producer.line_1',
              line_2: 'widgets.pollutionCard.description.producer.line_2',
            },
            devID: 'glowing-kangaroo',
            devPath: 'site-energy->administrator->producer->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'site-energy',
            devID: 'silly-enchilada',
            devPath: 'site-energy->administrator->producer->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: false,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: false,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'fantastic-quokka',
            devPath: 'site-energy->administrator->producer->productionStackBar',
          },
        ],
        prosumer: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'mystical-zeppelin',
            devPath: 'site-energy->administrator->prosumer->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'siteStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'site',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.siteStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.siteStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'consumption'],
            title: 'widgets.siteStatsOverviewConsumption.title',
            devID: 'mystical-octopus',
            devPath:
              'site-energy->administrator->prosumer->siteStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'siteStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'site',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.siteStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.siteStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'production'],
            title: 'widgets.siteStatsOverviewProduction.title',
            devID: 'zippy-narwhal',
            devPath:
              'site-energy->administrator->prosumer->siteStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'selfSufficiencyCard',
            id: 'selfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            icon: 'self_sufficiency',
            descriptionType: 'label',
            description: 'widgets.selfSufficiencyCard.description.prosumer',
            devID: 'rampaging-iceberg',
            devPath:
              'site-energy->administrator->prosumer->selfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'web:label+value|label+value',
            description: [
              {
                key: 'widgets.sharedEnergyCard.description.prosumer.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.sharedEnergyCard.description.prosumer.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'happy-pajamas',
            devPath: 'site-energy->administrator->prosumer->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.prosumer.line_1',
              line_2: 'widgets.pollutionCard.description.prosumer.line_2',
            },
            devID: 'ludicrous-penguin',
            devPath: 'site-energy->administrator->prosumer->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'site-energy',
            devID: 'vivacious-rhinoceros',
            devPath: 'site-energy->administrator->prosumer->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'rambunctious-cat',
            devPath:
              'site-energy->administrator->prosumer->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'productionShared',
                colorShade: '',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'giddy-hedgehog',
            devPath: 'site-energy->administrator->prosumer->productionStackBar',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumptionSite',
            title: 'widgets.areaChartSelfConsumptionSite.title',
            type: 'areachart',
            subtype: 'areachart',
            gradientBorder: false,
            colors: ['consumption', 'production', 'self-consumption'],
            graphKeys: ['consumption', 'production', 'self_consumption'],
            devID: 'giddy-quokka',
            devPath:
              'site-energy->administrator->prosumer->areaChartSelfConsumptionSite',
          },
        ],
        prosumage: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'zany-nacho',
            devPath: 'site-energy->administrator->prosumage->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'siteStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'site',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.siteStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'self_consumption_battery',
                percentage: 'consumption_self_consumption_battery_rate',
                label:
                  'widgets.siteStatsOverviewConsumption.self_consumption_battery',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.siteStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'consumption'],
            title: 'widgets.siteStatsOverviewConsumption.title',
            devID: 'nutty-vortex',
            devPath:
              'site-energy->administrator->prosumage->siteStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'siteStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'site',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.siteStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'production_to_battery',
                percentage: 'production_to_battery_rate',
                label:
                  'widgets.siteStatsOverviewProduction.production_to_battery',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.siteStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'productionGrid'],
            title: 'widgets.siteStatsOverviewProduction.title',
            devID: 'zesty-hamburger',
            devPath:
              'site-energy->administrator->prosumage->siteStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'selfSufficiencyCard',
            id: 'selfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            icon: 'self_sufficiency',
            descriptionType: 'label',
            description: 'widgets.selfSufficiencyCard.description.prosumage',
            devID: 'happy-zeppelin',
            devPath:
              'site-energy->administrator->prosumage->selfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'web:label+value|label+value',
            description: [
              {
                key: 'widgets.sharedEnergyCard.description.prosumage.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.sharedEnergyCard.description.prosumage.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'eccentric-kangaroo',
            devPath: 'site-energy->administrator->prosumage->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.prosumage.line_1',
              line_2: 'widgets.pollutionCard.description.prosumage.line_2',
            },
            devID: 'mystical-lemonade',
            devPath: 'site-energy->administrator->prosumage->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'site-energy',
            devID: 'hysterical-spoon',
            devPath: 'site-energy->administrator->prosumage->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'self_consumption_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumptionShared',
                colorShade: '',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'quirksome-hedgehog',
            devPath:
              'site-energy->administrator->prosumage->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'production_to_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'productionShared',
                colorShade: '',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'impish-tangerine',
            devPath:
              'site-energy->administrator->prosumage->productionStackBar',
          },
          {
            id: 'batteryChart',
            title: 'widgets.batteryChart.title',
            type: 'batteryChart',
            gradientBorder: false,
            context: 'site',
            buttons: [
              'widgets.batteryChart.self_consumption',
              'widgets.batteryChart.soc',
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
            ],
            db_fields: ['battery_net_flow', 'soc_battery'],
            devID: 'jazzy-narwhal',
            devPath: 'site-energy->administrator->prosumage->batteryChart',
          },
          {
            id: 'areaChartSelfConsumptionSite',
            title: 'widgets.areaChartSelfConsumptionSite.title',
            type: 'areaChart',
            subtype: 'areaChart',
            gradientBorder: false,
            view: 'site',
            graphKeys: ['consumption', 'production', 'self_consumption'],
            colors: ['consumption', 'production', 'self-consumption'],
            devID: 'waggish-quokka',
            devPath:
              'site-energy->administrator->prosumage->areaChartSelfConsumptionSite',
          },
        ],
      },
    },
  },

  // ######### COMMUNITY ECONOMICS #########

  {
    name: 'community-economics',
    active: {
      default: true,
    },
    visible: {
      default: false,
    },
    footer: {
      default: false,
    },
    header: {
      default: false,
    },
    datePicker: {
      default: false,
    },
    pullToRefresh: {
      default: false,
    },
    needsAuth: true,
    needsID: true,
    apis: {
      default: [
        'EntitiesResolver',
        'KpiCommunityResolver',
        'CommunityResolver',
        'CommunityRankingResolver',
      ],
    },
    layouts: {
      default: {
        lg: [
          {
            i: 'headerWeb',
            x: 0,
            y: 0,
            w: 12,
            h: 10,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'communitySavingsCard',
            x: 0,
            y: 13,
            w: 6,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              minHeight: '100%',
              height: '100%',
              paddingRight: 8,
            },
          },
          {
            i: 'communityEarningsCard',
            x: 7,
            y: 13,
            w: 6,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              minHeight: '100%',
              height: '100%',
              paddingLeft: 8,
            },
          },
          {
            i: 'savingsStackBar',
            x: 0,
            y: 42,
            w: 12,
            h: 52,
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',
              boxSizing: 'border-box',
            },
          },
          {
            i: 'horizontalChart',
            x: 0,
            y: 96,
            w: 12,
            h: 53,
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',
              boxSizing: 'border-box',
            },
          },
        ],
      },
      administrator: {
        default: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'communitySavingsCard',
              x: 0,
              y: 13,
              w: 12,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                minHeight: '100%',
                height: '100%',
              },
            },
            {
              i: 'savingsStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 52,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChart',
              x: 0,
              y: 96,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        default_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'communitySavingsCard',
              x: 0,
              y: 13,
              w: 12,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                minHeight: '100%',
                height: '100%',
              },
            },
            {
              i: 'savingsStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 52,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
      },
    },
    widgets: {
      default: [
        {
          type: 'headerWeb',
          id: 'headerWeb',
          data: {
            label: 'community-economics',
            breadCrumbs: true,
            navigation: true,
            calendar: true,
            download: false,
            searchBar: true,
            profile: true,
          },
          devID: 'cannibal-cat',
          devPath: 'community-economics->default->headerWeb',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          subtype: 'communitySavingsCard',
          id: 'communitySavingsCard',
          totalValue: 'total_benefits',
          icon: 'savings',
          descriptionType: 'label',
          description: 'widgets.communitySavingsCard.description.default',
          descriptionCallback: 'communityTotalEstimatedBenefits',
          devID: 'jubilant-ukulele',
          devPath: 'community-economics->default->communitySavingsCard',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          detail: false,
          subtype: 'communityEarningsCard',
          id: 'communityEarningsCard',
          totalValue: 'total_benefits',
          icon: 'house',
          descriptionType: 'ranking[label+value]',
          description: 'widgets.communityEarningsCard.description.default',
          style: {
            paddingLeft: 8,
          },
          devID: 'angry-dinosaur',
          devPath: 'community-economics->default->communityEarningsCard',
        },
        {
          env: 'web',
          type: 'graphs',
          gradientBorder: false,
          subtype: 'savingsStackBar',
          id: 'savingsStackBar',
          graphKeys: [
            {
              key: 'revenues',
              color: 'primary',
              combined: false,
            },
          ],
          colors: ['primary'],
          title: 'widgets.savingsStackBar.title',
          devID: 'rampaging-obelisk',
          devPath: 'community-economics->default->savingsStackBar',
        },
        {
          type: 'horizontalChart',
          gradientBorder: false,
          subtype: 'horizontalChart',
          id: 'horizontalChart',
          context: 'economics',
          rankingKpi: 'revenues',
          rankingKpiName: 'widgets.horizontalChart.economics.revenues',
          graphKeys: ['revenues'],
          combinedKeys: [],
          buttons: ['widgets.horizontalChart.economics.benefits'],
          colors: [
            {
              color: 'economics',
              colorShade: '',
            },
          ],
          title: 'widgets.horizontalChart.economics.title',
          devID: 'rambunctious-marshmallow',
          devPath: 'community-economics->default->horizontalChart',
        },
      ],
      administrator: {
        default: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-economics',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'kooky-banana',
            devPath: 'community-economics->administrator->default->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            subtype: 'communitySavingsCard',
            id: 'communitySavingsCard',
            totalValue: 'total_benefits',
            icon: 'savings',
            descriptionType: 'label',
            description: 'widgets.communitySavingsCard.description.default',
            descriptionCallback: 'communityTotalEstimatedBenefits',
            devID: 'quirky-banana',
            devPath:
              'community-economics->administrator->default->communitySavingsCard',
          },
          {
            env: 'web',
            type: 'graphs',
            gradientBorder: false,
            subtype: 'savingsStackBar',
            id: 'savingsStackBar',
            graphKeys: [
              {
                key: 'revenues',
                color: 'primary',
                combined: false,
              },
            ],
            colors: ['primary'],
            title: 'widgets.savingsStackBar.title',
            devID: 'spritely-cabbage',
            devPath:
              'community-economics->administrator->default->savingsStackBar',
          },
          {
            type: 'horizontalChart',
            gradientBorder: false,
            subtype: 'horizontalChart',
            id: 'horizontalChart',
            entity: 'community',
            context: 'economics',
            rankingKpi: 'revenues',
            rankingKpiName: 'widgets.horizontalChart.economics.revenues',
            graphKeys: ['revenues'],
            combinedKeys: [],
            buttons: ['widgets.horizontalChart.economics.benefits'],
            colors: [
              {
                color: 'economics',
                colorShade: '',
              },
            ],
            title: 'widgets.horizontalChart.economics.title',
            devID: 'rambunctious-shoe',
            devPath:
              'community-economics->administrator->default->horizontalChart',
          },
        ],
        default_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-economics',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'witty-cabbage',
            devPath:
              'community-economics->administrator->default_short->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            subtype: 'communitySavingsCard',
            id: 'communitySavingsCard',
            totalValue: 'total_benefits',
            icon: 'savings',
            descriptionType: 'label',
            description: 'widgets.communitySavingsCard.description.default',
            descriptionCallback: 'communityTotalEstimatedBenefits',
            devID: 'wondrous-flamingo',
            devPath:
              'community-economics->administrator->default_short->communitySavingsCard',
          },
          {
            env: 'web',
            type: 'graphs',
            gradientBorder: false,
            subtype: 'savingsStackBar',
            id: 'savingsStackBar',
            graphKeys: [
              {
                key: 'revenues',
                color: 'primary',
                combined: false,
              },
            ],
            colors: ['primary'],
            title: 'widgets.savingsStackBar.title',
            devID: 'zesty-penguin',
            devPath:
              'community-economics->administrator->default_short->savingsStackBar',
          },
        ],
      },
    },
  },

  // ######### COMMUNITY ENERGY #########

  {
    name: 'community-energy',
    active: {
      default: true,
    },
    visible: {
      default: false,
    },
    footer: {
      default: false,
    },
    header: {
      default: false,
    },
    datePicker: {
      default: false,
    },
    pullToRefresh: {
      default: false,
    },
    needsAuth: true,
    needsID: true,
    apis: {
      default: [
        'EntitiesResolver',
        'KpiCommunityResolver',
        'CommunityResolver',
        'CommunityRankingResolver',
      ],
    },
    layouts: {
      default: {
        lg: [
          {
            i: 'headerWeb',
            x: 0,
            y: 0,
            w: 12,
            h: 10,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'communityStatsOverviewConsumption',
            x: 0,
            y: 11,
            w: 4,
            h: 50,
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',
              backgroundColor: 'var( --hive-pureWhite )',
              marginLeft: -16,
              paddingLeft: 16,
              minWidth: '33.3%!important',
            },
          },
          {
            i: 'communityStatsOverviewProduction',
            x: 4,
            y: 11,
            w: 4,
            h: 50,
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',
              backgroundColor: 'var( --hive-pureWhite )',
            },
          },
          {
            i: 'map',
            x: 8,
            y: 11,
            w: 4,
            h: 50,
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',
              marginRight: -16,
              minWidth: '33.3%!important',
            },
          },
          {
            i: 'totalSitesCard',
            x: 0,
            y: 63,
            w: 3,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingRight: 8,
              height: '100%',
            },
          },
          {
            i: 'communitySharedEnergyCard',
            x: 3,
            y: 63,
            w: 3,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingRight: 8,
              paddingLeft: 8,
              height: '100%',
            },
          },
          {
            i: 'communitySelfSufficiencyCard',
            x: 6,
            y: 63,
            w: 3,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingRight: 8,
              paddingLeft: 8,
              height: '100%',
            },
          },
          {
            i: 'communityPollutionCard',
            x: 9,
            y: 63,
            w: 3,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingLeft: 8,
              height: '100%',
            },
          },
          {
            i: 'consumptionStackBar',
            x: 0,
            y: 92,
            w: 6,
            h: 53,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingRight: 8,
              height: '100%',
              boxSizing: 'border-box',
            },
          },
          {
            i: 'productionStackBar',
            x: 7,
            y: 92,
            w: 6,
            h: 53,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingLeft: 8,
              height: '100%',
              boxSizing: 'border-box',
            },
          },
          {
            i: 'batteryChart',
            x: 0,
            y: 147,
            w: 6,
            h: 53,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingRight: 8,
              height: '100%',
              boxSizing: 'border-box',
            },
          },
          {
            i: 'areaChartSelfConsumption',
            x: 6,
            y: 147,
            w: 6,
            h: 53,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingLeft: 8,
              height: '100%',
              boxSizing: 'border-box',
            },
          },
          {
            i: 'horizontalChartDouble',
            x: 0,
            y: 202,
            w: 12,
            h: 53,
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',
              boxSizing: 'border-box',
            },
          },
        ],
      },
      default_short: {
        lg: [
          {
            i: 'headerWeb',
            x: 0,
            y: 0,
            w: 12,
            h: 10,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'communityStatsOverviewConsumption',
            x: 0,
            y: 11,
            w: 4,
            h: 50,
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',
              backgroundColor: 'var( --hive-pureWhite )',
              marginLeft: -16,
              paddingLeft: 16,
              minWidth: '33.3%!important',
            },
          },
          {
            i: 'communityStatsOverviewProduction',
            x: 4,
            y: 11,
            w: 4,
            h: 50,
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',
              backgroundColor: 'var( --hive-pureWhite )',
            },
          },
          {
            i: 'map',
            x: 8,
            y: 11,
            w: 4,
            h: 50,
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',
              marginRight: -16,
              minWidth: '33.3%!important',
            },
          },
          {
            i: 'memberSelfSufficiencyCard',
            x: 0,
            y: 63,
            w: 4,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingRight: 8,
              paddingLeft: 8,
              height: '100%',
            },
          },
          {
            i: 'memberSharedEnergyCard',
            x: 4,
            y: 63,
            w: 4,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingRight: 8,
              paddingLeft: 8,
              height: '100%',
            },
          },
          {
            i: 'memberPollutionCard',
            x: 8,
            y: 63,
            w: 4,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingLeft: 8,
              height: '100%',
            },
          },
          {
            i: 'consumptionStackBar',
            x: 0,
            y: 92,
            w: 6,
            h: 53,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingRight: 8,
              boxSizing: 'border-box',
              height: '100%',
            },
          },
          {
            i: 'productionStackBar',
            x: 7,
            y: 92,
            w: 6,
            h: 53,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingLeft: 8,
              boxSizing: 'border-box',
              height: '100%',
            },
          },
          {
            i: 'batteryChart',
            x: 0,
            y: 147,
            w: 6,
            h: 48,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingRight: 8,
              height: '100%',
              boxSizing: 'border-box',
            },
          },
          {
            i: 'areaChartSelfConsumption',
            x: 6,
            y: 147,
            w: 6,
            h: 53,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingLeft: 8,
              height: '100%',
              boxSizing: 'border-box',
            },
          },
        ],
      },
      administrator: {
        consumer: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalConsumptionCard',
              x: 0,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 3,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 6,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'map',
              x: 9,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChart',
              x: 0,
              y: 98,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        producer: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalProductionCard',
              x: 0,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 3,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 6,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'map',
              x: 9,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'productionStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChart',
              x: 0,
              y: 98,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        consumer_producer: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalConsumptionCard',
              x: 0,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'totalProductionCard',
              x: 2.4,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 4.8,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 16,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'pollutionCard',
              x: 7.2,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'map',
              x: 9.6,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                marginRight: -8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 42,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 42,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChartDouble',
              x: 0,
              y: 98,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        prosumer: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'communityStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'communityStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'selfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'areaChartSelfConsumptionSite',
              x: 0,
              y: 147,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChartDouble',
              x: 0,
              y: 202,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        prosumage: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'communityStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'communityStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'communitySelfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'communitySharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'communityPollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'batteryChart',
              x: 0,
              y: 147,
              w: 6,
              h: 48,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'areaChartSelfConsumption',
              x: 6,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChartDouble',
              x: 0,
              y: 98,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        prosumer_battery: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'communityStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'communityStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'communitySelfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'communitySharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'communityPollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'batteryChart',
              x: 0,
              y: 147,
              w: 6,
              h: 48,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'areaChartSelfConsumption',
              x: 6,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChartDouble',
              x: 0,
              y: 202,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        consumer_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalConsumptionCard',
              x: 0,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 3,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 6,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'map',
              x: 9,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        producer_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalProductionCard',
              x: 0,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 3,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 6,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'map',
              x: 9,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'productionStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        producer_shortconsumer_producer_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalConsumptionCard',
              x: 0,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'totalProductionCard',
              x: 2.4,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 4.8,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 16,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'pollutionCard',
              x: 7.2,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'map',
              x: 9.6,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                marginRight: -8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 42,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 42,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
          ],
        },
        prosumer_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'communityStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'communityStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'selfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'areaChartSelfConsumptionSite',
              x: 0,
              y: 147,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        prosumage_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'communityStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'communityStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'communitySelfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'communitySharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'communityPollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'batteryChart',
              x: 0,
              y: 147,
              w: 6,
              h: 48,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'areaChartSelfConsumption',
              x: 6,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        prosumer_battery_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'communityStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'communityStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'communitySelfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'communitySharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'communityPollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'batteryChart',
              x: 0,
              y: 147,
              w: 6,
              h: 48,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'areaChartSelfConsumption',
              x: 6,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
      },
    },
    widgets: {
      default: [
        {
          type: 'headerWeb',
          id: 'headerWeb',
          data: {
            label: 'community-energy',
            breadCrumbs: true,
            navigation: true,
            calendar: true,
            download: false,
            searchBar: true,
            profile: true,
          },
          devID: 'turbulent-yogurt',
          devPath: 'community-energy->default->headerWeb',
        },
        {
          env: 'web',
          type: 'statsOverviewWeb',
          subtype: 'statsOverviewWeb',
          id: 'communityStatsOverviewConsumption',
          totalValue: 'consumption',
          color: 'consumption',
          view: 'community',
          graphDataKeys: [
            {
              key: 'self_consumption_direct',
              percentage: 'consumption_self_consumption_direct_rate',
              label:
                'widgets.communityStatsOverviewConsumption.self_consumption_direct',
            },
            {
              key: 'from_grid',
              percentage: 'consumption_from_grid_rate',
              label: 'widgets.communityStatsOverviewConsumption.from_grid',
            },
          ],
          graphColors: ['self-consumption', 'consumption'],
          title: 'widgets.communityStatsOverviewConsumption.title',
          devID: 'zealous-mango',
          devPath:
            'community-energy->default->communityStatsOverviewConsumption',
        },
        {
          env: 'web',
          type: 'statsOverviewWeb',
          subtype: 'statsOverviewWeb',
          id: 'communityStatsOverviewProduction',
          totalValue: 'production',
          color: 'production',
          view: 'community',
          graphDataKeys: [
            {
              key: 'self_consumption_direct',
              percentage: 'consumption_self_consumption_direct_rate',
              label:
                'widgets.communityStatsOverviewProduction.self_consumption_direct',
            },
            {
              key: 'to_grid',
              percentage: 'production_to_grid_rate',
              label: 'widgets.communityStatsOverviewProduction.to_grid',
            },
          ],
          graphColors: ['self-consumption', 'production'],
          title: 'widgets.communityStatsOverviewProduction.title',
          devID: 'eccentric-kiwi',
          devPath:
            'community-energy->default->communityStatsOverviewProduction',
        },
        {
          env: 'web',
          type: 'statsOverviewWeb',
          subtype: 'statsOverviewWeb',
          id: 'communityStatsOverviewProduction',
          totalValue: 'production',
          color: 'production',
          view: 'community',
          graphDataKeys: [

            {
              key: 'self_consumption_direct',
              percentage: 'production_self_consumption_direct_rate',
              label:
                'widgets.communityStatsOverviewProduction.self_consumption_direct',
            },
            {
              key: 'production_to_battery',
              percentage: 'production_to_battery_rate',
              label:
                'widgets.communityStatsOverviewProduction.production_to_battery',
            },
            {
              key: 'to_grid',
              percentage: 'production_to_grid_rate',
              label: 'widgets.communityStatsOverviewProduction.to_grid',
            },
          ],
          graphColors: ['self-consumption', 'battery', 'production' /*'#EBC1DC'*/],
          title: 'widgets.communityStatsOverviewProduction.title',
          devID: 'frisky-cabbage',
          devPath:
            'community-energy->default->communityStatsOverviewProduction',
        },
        {
          env: 'web',
          type: 'map',
          gradientBorder: false,
          id: 'map',
          devID: 'fantastic-xylophone',
          devPath: 'community-energy->default->map',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          detail: false,
          subtype: 'totalSitesCard',
          id: 'totalSitesCard',
          totalValue: 'sites_count',
          icon: 'sites',
          descriptionType: 'totalSitesCard',
          description: [
            {
              key: 'widgets.totalSitesCard.description.default.consumer',
              value: 'consumer',
            },
            {
              key: 'widgets.totalSitesCard.description.default.producer',
              value: 'producer',
            },
            {
              key: 'widgets.totalSitesCard.description.default.prosumage',
              value: 'prosumage',
            },
            {
              key: 'widgets.totalSitesCard.description.default.prosumer',
              value: 'prosumer',
            },
          ],
          devID: 'unruly-dinosaur',
          devPath: 'community-energy->default->totalSitesCard',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          detail: false,
          subtype: 'communitySelfSufficiencyCard',
          id: 'communitySelfSufficiencyCard',
          totalValue: 'self_sufficiency_rate',
          icon: 'self_sufficiency',
          descriptionType: 'label',
          description:
            'widgets.communitySelfSufficiencyCard.description.default',
          devID: 'playful-yogurt',
          devPath: 'community-energy->default->communitySelfSufficiencyCard',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          detail: false,
          subtype: 'communitySharedEnergyCard',
          id: 'communitySharedEnergyCard',
          totalValue: 'total_shared',
          icon: 'shared_energy',
          descriptionType: 'label',
          description: 'widgets.communitySharedEnergyCard.description.default',
          devID: 'boisterous-mango',
          devPath: 'community-energy->default->communitySharedEnergyCard',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          detail: false,
          subtype: 'communityPollutionCard',
          id: 'communityPollutionCard',
          totalValue: 'co2_offset_kgs',
          trees: 'co2_offset_trees',
          icon: 'pollution',
          descriptionValue: 'co2_offset_trees',
          descriptionType: '{{trees}}',
          description: {
            line_1: 'widgets.pollutionCard.description.prosumer.line_1',
            line_2: 'widgets.pollutionCard.description.prosumer.line_2',
          },
          devID: 'oddball-zeppelin',
          devPath: 'community-energy->default->communityPollutionCard',
        },
        {
          type: 'graphs',
          gradientBorder: false,
          subtype: 'consumptionStackBar',
          id: 'consumptionStackBar',
          totalValue: 'consumption',
          graphKeys: [
            {
              key: 'self_consumption_direct',
              color: 'self-consumption',
              combined: false,
            },
            {
              key: 'self_consumption_battery',
              color: 'battery',
              combined: false,
            },
            {
              key: 'total_shared',
              color: 'consumption',
              combined: false,
            },
            {
              key: 'from_grid',
              color: 'consumptionShared',
              combined: false,
            },
          ],
          combinedKeys: [
            {
              key: 'total_shared',
              color: 'consumption',
              combined: true,
            },
            {
              key: 'from_grid',
              color: 'consumptionShared',
              combined: true,
            },
          ],
          colors: [
            {
              color: 'self-consumption',
              colorShade: '',
            },
            {
              color: 'battery',
              colorShade: '',
            },
            {
              color: 'consumption',
              colorShade: '',
            },
            {
              color: 'consumption',
              colorShade: '60',
            },
          ],
          title: 'widgets.consumptionStackBar.title',
          devID: 'happy-hedgehog',
          devPath: 'community-energy->default->consumptionStackBar',
        },
        {
          type: 'graphs',
          gradientBorder: false,
          env: 'web',
          subtype: 'productionStackBar',
          id: 'productionStackBar',
          totalValue: 'production',
          graphKeys: [
            {
              key: 'self_consumption_direct',
              color: 'self-consumption',
              combined: false,
            },
            {
              key: 'production_to_battery',
              color: 'battery',
              combined: false,
            },
            {
              key: 'total_shared',
              color: 'production',
              combined: true,
            },
            {
              key: 'to_grid',
              color: 'productionShared',
              combined: true,
            },
          ],
          combinedKeys: [
            {
              key: 'total_shared',
              color: 'productionShared',
              combined: true,
            },
            {
              key: 'to_grid',
              color: 'productionShared',
              combined: true,
            },
          ],
          colors: [
            {
              color: 'self-consumption',
              colorShade: '',
            },
            {
              color: 'battery',
              colorShade: '',
            },
            {
              color: 'production',
              colorShade: '',
            },
            {
              color: 'productionShared',
              colorShade: '',
            },
          ],
          title: 'widgets.productionStackBar.title',
          devID: 'wild-rhinoceros',
          devPath: 'community-energy->default->productionStackBar',
        },
        {
          id: 'batteryChart',
          title: 'widgets.batteryChart.title',
          type: 'batteryChart',
          gradientBorder: false,
          context: 'community',
          buttons: ['widgets.batteryChart.self_consumption'],
          colors: [
            {
              color: 'self-consumption',
              colorShade: '',
            },
          ],
          db_fields: ['battery_net_flow'],
          devID: 'boisterous-igloo',
          devPath: 'community-energy->default->batteryChart',
        },
        {
          env: 'web',
          id: 'areaChartSelfConsumption',
          title: 'widgets.areaChartSelfConsumptionCommunity.title',
          type: 'areaChart',
          subtype: 'areaChart',
          gradientBorder: false,
          view: 'community',
          graphKeys: ['consumption', 'production', 'total_shared'],
          colors: ['consumption', 'production', 'shared'],
          devID: 'giddy-dinosaur',
          devPath: 'community-energy->default->areaChartSelfConsumption',
        },
        {
          type: 'horizontalChartDouble',
          gradientBorder: false,
          subtype: 'horizontalChartDouble',
          id: 'horizontalChartDouble',
          context: 'energy',
          rankingKpi: 'consumption',
          kpiNameLabels: ['consumption', 'production'],
          rankingKpiName: [
            'widgets.horizontalChart.energy.consumption',
            'widgets.horizontalChart.energy.production',
          ],
          graphKeys: [
            [
              'self_consumption_direct',
              'self_consumption_battery',
              'from_shared',
              'from_grid',
            ],
            [
              'self_consumption_direct',
              'production_to_battery',
              'to_shared',
              'to_grid',
            ],
          ],
          combinedKeys: [
            ['from_shared', 'from_grid'],
            ['to_shared', 'to_grid'],
          ],
          buttons: [
            'widgets.horizontalChart.energy.self_consumption_direct',
            'widgets.horizontalChart.energy.self_consumption_battery',
            'widgets.horizontalChart.energy.from_shared',
            'widgets.horizontalChart.energy.from_grid',
          ],
          colors: [
            {
              color: 'self-consumption',
              colorShade: '',
            },
            {
              color: 'battery',
              colorShade: '',
            },
            {
              color: 'consumption',
              colorShade: '',
            },
            {
              color: 'consumption',
              colorShade: '60',
            },
          ],
          title: 'widgets.horizontalChart.energy.title',
          devID: 'boisterous-shoe',
          devPath: 'community-energy->default->horizontalChartDouble',
        },
      ],
      administrator: {
        consumer: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'boisterous-jigsaw',
            devPath: 'community-energy->administrator->consumer->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalConsumptionCard',
            id: 'totalConsumptionCard',
            totalValue: 'consumption',
            icon: 'house_import',
            descriptionValue: 'consumption',
            descriptionType: 'label',
            description: 'widgets.totalConsumptionCard.description.consumer',
            devID: 'waggish-lighthouse',
            devPath:
              'community-energy->administrator->consumer->totalConsumptionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'label',
            description: 'widgets.sharedEnergyCard.description.consumer.key_1',
            devID: 'oddball-ferret',
            devPath:
              'community-energy->administrator->consumer->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.consumer.line_1',
              line_2: 'widgets.pollutionCard.description.consumer.line_2',
            },
            devID: 'eccentric-vortex',
            devPath: 'community-energy->administrator->consumer->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'community-energy',
            devID: 'rampaging-rhinoceros',
            devPath: 'community-energy->administrator->consumer->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'angry-hedgehog',
            devPath:
              'community-energy->administrator->consumer->consumptionStackBar',
          },
          {
            type: 'horizontalChart',
            gradientBorder: false,
            subtype: 'horizontalChart',
            id: 'horizontalChart',
            context: 'energy',
            rankingKpi: 'consumption',
            rankingKpiName: 'widgets.horizontalChart.energy.consumption',
            graphKeys: ['from_shared', 'from_grid'],
            combinedKeys: ['from_shared', 'from_grid'],
            buttons: [
              'widgets.horizontalChart.energy.from_shared',
              'widgets.horizontalChart.energy.from_grid',
            ],
            colors: [
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.horizontalChart.energy.title',
            devID: 'cannibal-igloo',
            devPath:
              'community-energy->administrator->consumer->horizontalChart',
          },
        ],
        producer: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'hysterical-quokka',
            devPath: 'community-energy->administrator->producer->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalProductionCard',
            id: 'totalProductionCard',
            totalValue: 'production',
            icon: 'house_export',
            descriptionValue: 'production',
            descriptionType: 'label',
            description: 'widgets.totalProductionCard.description.producer',
            devID: 'zippy-cabbage',
            devPath:
              'community-energy->administrator->producer->totalProductionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'keyValue',
            description: [
              {
                key: 'widgets.sharedEnergyCard.description.producer.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.sharedEnergyCard.description.producer.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'eccentric-octopus',
            devPath:
              'community-energy->administrator->producer->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.producer.line_1',
              line_2: 'widgets.pollutionCard.description.producer.line_2',
            },
            devID: 'daring-octopus',
            devPath: 'community-energy->administrator->producer->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'community-energy',
            devID: 'mirthful-zeppelin',
            devPath: 'community-energy->administrator->producer->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'total_shared',
                color: 'production',
                combined: false,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: false,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'zippy-gumball',
            devPath:
              'community-energy->administrator->producer->productionStackBar',
          },
          {
            type: 'horizontalChart',
            gradientBorder: false,
            subtype: 'horizontalChart',
            id: 'horizontalChart',
            context: 'energy',
            rankingKpi: 'production',
            rankingKpiName: 'widgets.horizontalChart.energy.production',
            graphKeys: ['production_to_battery', 'to_shared', 'to_grid'],
            combinedKeys: ['to_shared', 'to_grid'],
            buttons: [
              'widgets.horizontalChart.energy.to_shared',
              'widgets.horizontalChart.energy.to_grid',
            ],
            colors: [
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.horizontalChart.energy.title',
            devID: 'frisky-jigsaw',
            devPath:
              'community-energy->administrator->producer->horizontalChart',
          },
        ],
        consumer_producer: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'impish-kangaroo',
            devPath:
              'community-energy->administrator->consumer_producer->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalConsumptionCard',
            id: 'totalConsumptionCard',
            totalValue: 'consumption',
            icon: 'house_import',
            descriptionValue: 'consumption',
            descriptionType: 'label',
            description: 'widgets.totalConsumptionCard.description.consumer',
            devID: 'turbulent-espresso',
            devPath:
              'community-energy->administrator->consumer_producer->totalConsumptionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalProductionCard',
            id: 'totalProductionCard',
            totalValue: 'production',
            icon: 'house_export',
            descriptionValue: 'production',
            descriptionType: 'label',
            description: 'widgets.totalProductionCard.description.producer',
            devID: 'maniacal-brick',
            devPath:
              'community-energy->administrator->consumer_producer->totalProductionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'label',
            description:
              'widgets.communitySharedEnergyCard.description.consumer.key_1',
            devID: 'witty-tangerine',
            devPath:
              'community-energy->administrator->consumer_producer->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.producer.line_1',
              line_2: 'widgets.pollutionCard.description.producer.line_2',
            },
            devID: 'oddball-waffle',
            devPath:
              'community-energy->administrator->consumer_producer->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'community-energy',
            devID: 'jubilant-iceberg',
            devPath: 'community-energy->administrator->consumer_producer->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumptionShared',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'whacky-cactus',
            devPath:
              'community-energy->administrator->consumer_producer->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'productionShared',
                colorShade: '',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'playful-yogurt',
            devPath:
              'community-energy->administrator->consumer_producer->productionStackBar',
          },
          {
            type: 'horizontalChartDouble',
            gradientBorder: false,
            subtype: 'horizontalChartDouble',
            id: 'horizontalChartDouble',
            context: 'energy',
            rankingKpi: 'consumption',
            rankingKpiName: [
              'widgets.horizontalChart.energy.consumption',
              'widgets.horizontalChart.energy.production',
            ],
            graphKeys: [
              ['self_consumption_direct', 'from_shared', 'from_grid'],
              ['self_consumption_direct', 'to_shared', 'to_grid'],
            ],
            combinedKeys: [
              ['from_shared', 'from_grid'],
              ['to_shared', 'to_grid'],
            ],
            buttons: [
              'widgets.horizontalChart.energy.self_consumption_direct',
              'widgets.horizontalChart.energy.from_shared',
              'widgets.horizontalChart.energy.from_grid',
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.horizontalChart.energy.title',
            devID: 'ferocious-enchilada',
            devPath:
              'community-energy->administrator->consumer_producer->horizontalChartDouble',
          },
        ],
        prosumer: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'eccentric-cactus',
            devPath: 'community-energy->administrator->prosumer->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'communityStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'community',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.communityStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.communityStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'consumption'],
            title: 'widgets.communityStatsOverviewConsumption.title',
            devID: 'kooky-marshmallow',
            devPath:
              'community-energy->administrator->prosumer->communityStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'communityStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'community',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.communityStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.communityStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'production'],
            title: 'widgets.communityStatsOverviewProduction.title',
            devID: 'jazzy-ukulele',
            devPath:
              'community-energy->administrator->prosumer->communityStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'selfSufficiencyCard',
            id: 'selfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            icon: 'self_sufficiency',
            descriptionType: 'label',
            description: 'widgets.selfSufficiencyCard.description.prosumer',
            devID: 'ludicrous-nacho',
            devPath:
              'community-energy->administrator->prosumer->selfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'label',
            description: 'widgets.communitySharedEnergyCard.description.default',
            devID: 'vivacious-giraffe',
            devPath:
              'community-energy->administrator->prosumer->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.prosumer.line_1',
              line_2: 'widgets.pollutionCard.description.prosumer.line_2',
            },
            devID: 'frisky-jellyfish',
            devPath: 'community-energy->administrator->prosumer->pollutionCard',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'whacky-quokka',
            devPath:
              'community-energy->administrator->prosumer->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'rowdy-ferret',
            devPath:
              'community-energy->administrator->prosumer->productionStackBar',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumptionSite',
            title: 'widgets.areaChartSelfConsumptionSite.title',
            type: 'areachart',
            subtype: 'areachart',
            gradientBorder: false,
            colors: ['consumption', 'production', 'self-consumption'],
            graphKeys: ['consumption', 'production', 'self_consumption'],
            devID: 'waggish-buffet',
            devPath:
              'community-energy->administrator->prosumer->areaChartSelfConsumptionSite',
          },
          {
            type: 'horizontalChartDouble',
            gradientBorder: false,
            subtype: 'horizontalChartDouble',
            id: 'horizontalChartDouble',
            context: 'energy',
            rankingKpi: 'consumption',
            kpiNameLabels: ['consumption', 'production'],
            rankingKpiName: [
              'widgets.horizontalChart.energy.consumption',
              'widgets.horizontalChart.energy.production',
            ],
            graphKeys: [
              ['self_consumption_direct', 'from_shared', 'from_grid'],
              ['self_consumption_direct', 'to_shared', 'to_grid'],
            ],
            combinedKeys: [
              ['from_shared', 'from_grid'],
              ['to_shared', 'to_grid'],
            ],
            buttons: [
              'widgets.horizontalChart.energy.self_consumption_direct',
              'widgets.horizontalChart.energy.from_shared',
              'widgets.horizontalChart.energy.from_grid',
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.horizontalChart.energy.title',
            devID: 'cannibal-flamingo',
            devPath:
              'community-energy->administrator->prosumer->horizontalChartDouble',
          },
        ],
        prosumage: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'zippy-shoe',
            devPath: 'community-energy->administrator->prosumage->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'communityStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'community',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'self_consumption_battery',
                percentage: 'consumption_self_consumption_battery_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_battery',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.memberStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'consumption'],
            title: 'widgets.communityStatsOverviewConsumption.title',
            values: [
              {
                label:
                  'widgets.communityStatsOverviewConsumption.self_consumption_direct',
                totalPercentage: 'self_consumption_direct',
                id: 'self_consumption_direct',
                progress: [
                  {
                    value: 'self_consumption_direct',
                    color: 'self-consumption',
                  },
                ],
              },
              {
                label:
                  'widgets.communityStatsOverviewConsumption.self_consumption_battery',
                totalPercentage: 'self_consumption_battery',
                id: 'self_consumption_battery',
                progress: [
                  {
                    value: 'self_consumption_direct',
                    color: '#F0F0F0',
                  },
                  {
                    value: 'self_consumption_battery',
                    color: 'battery',
                  },
                ],
              },
              {
                label: 'widgets.memberStatsOverviewConsumption.from_grid',
                totalPercentage: 'from_grid',
                id: 'from_grid',
                progress: [
                  {
                    value: 'self_consumption_direct',
                    color: '#F0F0F0',
                  },
                  {
                    value: 'self_consumption_battery',
                    color: '#F0F0F0',
                  },
                  {
                    value: 'from_grid',
                    color: 'consumption',
                  },
                ],
              },
            ],
            devID: 'luminous-ukulele',
            devPath:
              'community-energy->administrator->prosumage->communityStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'communityStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'community',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'production_to_battery',
                percentage: 'production_to_battery_rate',
                label:
                  'widgets.memberStatsOverviewProduction.production_to_battery',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.memberStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: [ 'self-consumption', 'battery', 'production'],
            title: 'widgets.communityStatsOverviewProduction.title',
            devID: 'oddball-cabbage',
            devPath:
              'community-energy->administrator->prosumage->communityStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            devID: 'rampaging-vortex',
            devPath: 'community-energy->administrator->prosumage->map',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'communitySelfSufficiencyCard',
            id: 'communitySelfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            icon: 'self_sufficiency',
            descriptionType: 'label',
            description:
              'widgets.communitySelfSufficiencyCard.description.default',
            devID: 'whimsical-shoe',
            devPath:
              'community-energy->administrator->prosumage->communitySelfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'communitySharedEnergyCard',
            id: 'communitySharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'web:label+value|label+value',
            description: [
              {
                key: 'widgets.communitySharedEnergyCard.description.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.communitySharedEnergyCard.description.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'mirthful-hedgehog',
            devPath:
              'community-energy->administrator->prosumage->communitySharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'communityPollutionCard',
            id: 'communityPollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.memberPollutionCard.description.prosumer.line_1',
              line_2: 'widgets.memberPollutionCard.description.prosumer.line_2',
            },
            devID: 'happy-banana',
            devPath:
              'community-energy->administrator->prosumage->communityPollutionCard',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'self_consumption_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'silly-banana',
            devPath:
              'community-energy->administrator->prosumage->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'production_to_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'productionShared',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'giddy-pajamas',
            devPath:
              'community-energy->administrator->prosumage->productionStackBar',
          },
          {
            id: 'batteryChart',
            title: 'widgets.batteryChart.title',
            type: 'batteryChart',
            gradientBorder: false,
            context: 'community',
            buttons: ['widgets.batteryChart.self_consumption'],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
            ],
            db_fields: ['battery_net_flow'],
            devID: 'mystical-hamburger',
            devPath: 'community-energy->administrator->prosumage->batteryChart',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumption',
            title: 'widgets.areaChartSelfConsumptionCommunity.title',
            type: 'areaChart',
            subtype: 'areaChart',
            gradientBorder: false,
            view: 'community',
            graphKeys: ['consumption', 'production', 'self_consumption'],
            colors: ['consumption', 'production', 'shared'],
            devID: 'turbulent-rhinoceros',
            devPath:
              'community-energy->administrator->prosumage->areaChartSelfConsumption',
          },
          {
            type: 'horizontalChartDouble',
            gradientBorder: false,
            subtype: 'horizontalChartDouble',
            id: 'horizontalChartDouble',
            context: 'energy',
            rankingKpi: 'consumption',
            kpiNameLabels: ['consumption', 'production'],
            rankingKpiName: [
              'widgets.horizontalChart.energy.consumption',
              'widgets.horizontalChart.energy.production',
            ],
            graphKeys: [
              [
                'self_consumption_direct',
                'self_consumption_battery',
                'from_shared',
                'from_grid',
              ],
              [
                'self_consumption_direct',
                'production_to_battery',
                'to_shared',
                'to_grid',
              ],
            ],
            combinedKeys: [
              ['from_shared', 'from_grid'],
              ['to_shared', 'to_grid'],
            ],
            buttons: [
              'widgets.horizontalChart.energy.self_consumption_direct',
              'widgets.horizontalChart.energy.self_consumption_battery',
              'widgets.horizontalChart.energy.from_shared',
              'widgets.horizontalChart.energy.from_grid',
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.horizontalChart.energy.title',
            devID: 'whimsical-firecracker',
            devPath:
              'community-energy->administrator->prosumage->horizontalChartDouble',
          },
        ],
        prosumer_battery: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'zany-ukulele',
            devPath:
              'community-energy->administrator->prosumer_battery->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'communityStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'community',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'self_consumption_battery',
                percentage: 'consumption_self_consumption_battery_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_battery',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.memberStatsOverviewConsumption.from_grid',
              },

            ],
            graphColors: ['self-consumption', 'battery', 'consumption'],
            title: 'widgets.communityStatsOverviewConsumption.title',
            devID: 'rowdy-cat',
            devPath:
              'community-energy->administrator->prosumer_battery->communityStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'communityStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'community',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'production_to_battery',
                percentage: 'production_to_battery_rate',
                label:
                  'widgets.memberStatsOverviewProduction.production_to_battery',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.memberStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'production'],
            title: 'widgets.communityStatsOverviewProduction.title',
            devID: 'daring-zeppelin',
            devPath:
              'community-energy->administrator->prosumer_battery->communityStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'community',
            devID: 'mirthful-saxophone',
            devPath: 'community-energy->administrator->prosumer_battery->map',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'communitySelfSufficiencyCard',
            id: 'communitySelfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            icon: 'self_sufficiency',
            descriptionType: 'label',
            description:
              'widgets.communitySelfSufficiencyCard.description.default',
            devID: 'witty-marshmallow',
            devPath:
              'community-energy->administrator->prosumer_battery->communitySelfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'communitySharedEnergyCard',
            id: 'communitySharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'label',
            description: 'widgets.communitySharedEnergyCard.description.default',
            devID: 'hysterical-dragon',
            devPath:
              'community-energy->administrator->prosumer_battery->communitySharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'communityPollutionCard',
            id: 'communityPollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.memberPollutionCard.description.prosumer.line_1',
              line_2: 'widgets.memberPollutionCard.description.prosumer.line_2',
            },
            devID: 'clumsy-espresso',
            devPath:
              'community-energy->administrator->prosumer_battery->communityPollutionCard',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'self_consumption_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'giddy-porcupine',
            devPath:
              'community-energy->administrator->prosumer_battery->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'production_to_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'productionShared',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'silly-hedgehog',
            devPath:
              'community-energy->administrator->prosumer_battery->productionStackBar',
          },
          {
            id: 'batteryChart',
            title: 'widgets.batteryChart.title',
            type: 'batteryChart',
            gradientBorder: false,
            context: 'community',
            buttons: ['widgets.batteryChart.self_consumption'],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
            ],
            db_fields: ['battery_net_flow'],
            devID: 'spritely-tangerine',
            devPath:
              'community-energy->administrator->prosumer_battery->batteryChart',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumption',
            title: 'widgets.areaChartSelfConsumptionCommunity.title',
            type: 'areaChart',
            subtype: 'areaChart',
            gradientBorder: false,
            view: 'community',
            graphKeys: ['consumption', 'production', 'self_consumption'],
            colors: ['consumption', 'production', 'shared'],
            devID: 'maniacal-igloo',
            devPath:
              'community-energy->administrator->prosumer_battery->areaChartSelfConsumption',
          },
          {
            type: 'horizontalChartDouble',
            gradientBorder: false,
            subtype: 'horizontalChartDouble',
            id: 'horizontalChartDouble',
            context: 'energy',
            rankingKpi: 'consumption',
            kpiNameLabels: ['consumption', 'production'],
            rankingKpiName: [
              'widgets.horizontalChart.energy.consumption',
              'widgets.horizontalChart.energy.production',
            ],
            graphKeys: [
              [
                'self_consumption_direct',
                'self_consumption_battery',
                'from_shared',
                'from_grid',
              ],
              [
                'self_consumption_direct',
                'production_to_battery',
                'to_shared',
                'to_grid',
              ],
            ],
            combinedKeys: [
              ['from_shared', 'from_grid'],
              ['to_shared', 'to_grid'],
            ],
            buttons: [
              'widgets.horizontalChart.energy.self_consumption_direct',
              'widgets.horizontalChart.energy.self_consumption_battery',
              'widgets.horizontalChart.energy.from_shared',
              'widgets.horizontalChart.energy.from_grid',
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.horizontalChart.energy.title',
            devID: 'zany-rhinoceros',
            devPath:
              'community-energy->administrator->prosumer_battery->horizontalChartDouble',
          },
        ],
        consumer_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'spritely-octopus',
            devPath:
              'community-energy->administrator->consumer_short->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalConsumptionCard',
            id: 'totalConsumptionCard',
            totalValue: 'consumption',
            icon: 'house_import',
            descriptionValue: 'consumption',
            descriptionType: 'label',
            description: 'widgets.totalConsumptionCard.description.consumer',
            devID: 'boisterous-quokka',
            devPath:
              'community-energy->administrator->consumer_short->totalConsumptionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'label',
            description: 'widgets.sharedEnergyCard.description.consumer.key_1',
            devID: 'ludicrous-dragon',
            devPath:
              'community-energy->administrator->consumer_short->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.consumer.line_1',
              line_2: 'widgets.pollutionCard.description.consumer.line_2',
            },
            devID: 'happy-xylophone',
            devPath:
              'community-energy->administrator->consumer_short->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'community-energy',
            devID: 'silly-glove',
            devPath: 'community-energy->administrator->consumer_short->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'wild-marshmallow',
            devPath:
              'community-energy->administrator->consumer_short->consumptionStackBar',
          },
        ],
        producer_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'zany-lighthouse',
            devPath:
              'community-energy->administrator->producer_short->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalProductionCard',
            id: 'totalProductionCard',
            totalValue: 'production',
            icon: 'house_export',
            descriptionValue: 'production',
            descriptionType: 'label',
            description: 'widgets.totalProductionCard.description.producer',
            devID: 'nocturnal-penguin',
            devPath:
              'community-energy->administrator->producer_short->totalProductionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'keyValue',
            description: [
              {
                key: 'widgets.sharedEnergyCard.description.producer.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.sharedEnergyCard.description.producer.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'glowing-pajamas',
            devPath:
              'community-energy->administrator->producer_short->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.producer.line_1',
              line_2: 'widgets.pollutionCard.description.producer.line_2',
            },
            devID: 'zealous-ferret',
            devPath:
              'community-energy->administrator->producer_short->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'community-energy',
            devID: 'vivacious-cactus',
            devPath: 'community-energy->administrator->producer_short->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'total_shared',
                color: 'production',
                combined: false,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: false,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'spritely-ukulele',
            devPath:
              'community-energy->administrator->producer_short->productionStackBar',
          },
        ],
        consumer_producer_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'hysterical-giraffe',
            devPath:
              'community-energy->administrator->consumer_producer_short->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalConsumptionCard',
            id: 'totalConsumptionCard',
            totalValue: 'consumption',
            icon: 'house_import',
            descriptionValue: 'consumption',
            descriptionType: 'label',
            description: 'widgets.totalConsumptionCard.description.consumer',
            devID: 'unruly-tangerine',
            devPath:
              'community-energy->administrator->consumer_producer_short->totalConsumptionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalProductionCard',
            id: 'totalProductionCard',
            totalValue: 'production',
            icon: 'house_export',
            descriptionValue: 'production',
            descriptionType: 'label',
            description: 'widgets.totalProductionCard.description.producer',
            devID: 'unruly-zeppelin',
            devPath:
              'community-energy->administrator->consumer_producer_short->totalProductionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'label',
            description:
              'widgets.communitySharedEnergyCard.description.consumer.key_1',
            devID: 'impish-vortex',
            devPath:
              'community-energy->administrator->consumer_producer_short->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.producer.line_1',
              line_2: 'widgets.pollutionCard.description.producer.line_2',
            },
            devID: 'unruly-xylophone',
            devPath:
              'community-energy->administrator->consumer_producer_short->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'community-energy',
            devID: 'rampaging-jigsaw',
            devPath:
              'community-energy->administrator->consumer_producer_short->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumptionShared',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'mystical-lighthouse',
            devPath:
              'community-energy->administrator->consumer_producer_short->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'productionShared',
                colorShade: '',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'quirksome-nacho',
            devPath:
              'community-energy->administrator->consumer_producer_short->productionStackBar',
          },
        ],
        prosumer_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'zesty-avocado',
            devPath:
              'community-energy->administrator->prosumer_short->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'communityStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'community',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.communityStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.communityStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'consumption'],
            title: 'widgets.communityStatsOverviewConsumption.title',
            devID: 'zippy-xylophone',
            devPath:
              'community-energy->administrator->prosumer_short->communityStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'communityStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'community',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.communityStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.communityStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'production'],
            title: 'widgets.communityStatsOverviewProduction.title',
            devID: 'luminous-shoe',
            devPath:
              'community-energy->administrator->prosumer_short->communityStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'selfSufficiencyCard',
            id: 'selfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            icon: 'self_sufficiency',
            descriptionType: 'label',
            description: 'widgets.selfSufficiencyCard.description.prosumer',
            devID: 'playful-octopus',
            devPath:
              'community-energy->administrator->prosumer_short->selfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'web:label+value|label+value',
            description: [
              {
                key: 'widgets.sharedEnergyCard.description.prosumer.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.sharedEnergyCard.description.prosumer.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'nocturnal-saxophone',
            devPath:
              'community-energy->administrator->prosumer_short->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.prosumer.line_1',
              line_2: 'widgets.pollutionCard.description.prosumer.line_2',
            },
            devID: 'rowdy-hedgehog',
            devPath:
              'community-energy->administrator->prosumer_short->pollutionCard',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'luminous-dragon',
            devPath:
              'community-energy->administrator->prosumer_short->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'giddy-avocado',
            devPath:
              'community-energy->administrator->prosumer_short->productionStackBar',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumptionSite',
            title: 'widgets.areaChartSelfConsumptionSite.title',
            type: 'areachart',
            subtype: 'areachart',
            gradientBorder: false,
            colors: ['consumption', 'production', 'self-consumption'],
            graphKeys: ['consumption', 'production', 'self_consumption'],
            devID: 'hysterical-narwhal',
            devPath:
              'community-energy->administrator->prosumer_short->areaChartSelfConsumptionSite',
          },
        ],
        prosumage_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'playful-igloo',
            devPath:
              'community-energy->administrator->prosumage_short->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'communityStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'community',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'self_consumption_battery',
                precentage: 'consumption_self_consumption_battery_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_battery',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.memberStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'consumption'],
            title: 'widgets.communityStatsOverviewConsumption.title',
            devID: 'rampaging-igloo',
            devPath:
              'community-energy->administrator->prosumage_short->communityStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'communityStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'community-energy',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'production_to_battery',
                percentage: 'production_to_battery_rate',
                label:
                  'widgets.memberStatsOverviewProduction.production_to_battery',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.memberStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'production'],
            title: 'widgets.communityStatsOverviewProduction.title',
            devID: 'nocturnal-xylophone',
            devPath:
              'community-energy->administrator->prosumage_short->communityStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            devID: 'silly-espresso',
            devPath: 'community-energy->administrator->prosumage_short->map',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'communitySelfSufficiencyCard',
            id: 'communitySelfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            icon: 'self_sufficiency',
            descriptionType: 'label',
            description:
              'widgets.communitySelfSufficiencyCard.description.default',
            devID: 'waggish-quokka',
            devPath:
              'community-energy->administrator->prosumage_short->communitySelfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'communitySharedEnergyCard',
            id: 'communitySharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'web:label+value|label+value',
            description: [
              {
                key: 'widgets.communitySharedEnergyCard.description.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.communitySharedEnergyCard.description.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'cannibal-firecracker',
            devPath:
              'community-energy->administrator->prosumage_short->communitySharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'communityPollutionCard',
            id: 'communityPollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1:
                'widgets.communityPollutionCard.description.prosumer.line_1',
              line_2:
                'widgets.communityPollutionCard.description.prosumer.line_2',
            },
            devID: 'ferocious-flamingo',
            devPath:
              'community-energy->administrator->prosumage_short->communityPollutionCard',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'self_consumption_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'golden-lemonade',
            devPath:
              'community-energy->administrator->prosumage_short->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'production_to_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'productionShared',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'nocturnal-quokka',
            devPath:
              'community-energy->administrator->prosumage_short->productionStackBar',
          },
          {
            id: 'batteryChart',
            title: 'widgets.batteryChart.title',
            type: 'batteryChart',
            gradientBorder: false,
            context: 'community',
            buttons: ['widgets.batteryChart.self_consumption'],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
            ],
            db_fields: ['battery_net_flow'],
            devID: 'mystical-kangaroo',
            devPath:
              'community-energy->administrator->prosumage_short->batteryChart',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumption',
            title: 'widgets.areaChartSelfConsumptionCommunity.title',
            type: 'areaChart',
            subtype: 'areaChart',
            gradientBorder: false,
            view: 'community',
            graphKeys: ['consumption', 'production', 'self_consumption'],
            colors: ['consumption', 'production', 'shared'],
            devID: 'fantastic-shoe',
            devPath:
              'community-energy->administrator->prosumage_short->areaChartSelfConsumption',
          },
        ],
        prosumer_battery_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'waggish-espresso',
            devPath:
              'community-energy->administrator->prosumer_battery_short->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'self_consumption_battery',
                percentage: 'consumption_self_consumption_battery_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_battery',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.memberStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'consumption'],
            title: 'widgets.memberStatsOverviewConsumption.title',
            devID: 'daring-iceberg',
            devPath:
              'community-energy->administrator->prosumer_battery_short->memberStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'production_to_battery',
                precentage: 'production_to_battery_rate',
                label:
                  'widgets.memberStatsOverviewProduction.production_to_battery',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.memberStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'production'],
            title: 'widgets.memberStatsOverviewProduction.title',
            devID: 'playful-ukulele',
            devPath:
              'community-energy->administrator->prosumer_battery_short->memberStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            devID: 'clumsy-lighthouse',
            devPath:
              'community-energy->administrator->prosumer_battery_short->map',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'communitySelfSufficiencyCard',
            id: 'communitySelfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            icon: 'self_sufficiency',
            descriptionType: 'label',
            description:
              'widgets.communitySelfSufficiencyCard.description.default',
            devID: 'witty-vortex',
            devPath:
              'community-energy->administrator->prosumer_battery_short->communitySelfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberSharedEnergyCard',
            id: 'memberSharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'web:label+value|label+value',
            description: [
              {
                key: 'widgets.memberSharedEnergyCard.description.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.memberSharedEnergyCard.description.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'giddy-enchilada',
            devPath:
              'community-energy->administrator->prosumer_battery_short->memberSharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberPollutionCard',
            id: 'memberPollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.memberPollutionCard.description.prosumer.line_1',
              line_2: 'widgets.memberPollutionCard.description.prosumer.line_2',
            },
            devID: 'ludicrous-spoon',
            devPath:
              'community-energy->administrator->prosumer_battery_short->memberPollutionCard',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'self_consumption_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'giddy-ferret',
            devPath:
              'community-energy->administrator->prosumer_battery_short->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'production_to_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'productionShared',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'daring-nacho',
            devPath:
              'community-energy->administrator->prosumer_battery_short->productionStackBar',
          },
          {
            id: 'batteryChart',
            title: 'widgets.batteryChart.title',
            type: 'batteryChart',
            gradientBorder: false,
            context: 'member',
            buttons: ['widgets.batteryChart.self_consumption'],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
            ],
            db_fields: ['battery_net_flow'],
            devID: 'frisky-butterfly',
            devPath:
              'community-energy->administrator->prosumer_battery_short->batteryChart',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumption',
            title: 'widgets.areaChartSelfConsumptionCommunity.title',
            type: 'areaChart',
            subtype: 'areaChart',
            gradientBorder: false,
            view: 'community',
            graphKeys: ['consumption', 'production', 'self_consumption'],
            colors: ['consumption', 'production', 'shared'],
            devID: 'jazzy-cactus',
            devPath:
              'community-energy->administrator->prosumer_battery_short->areaChartSelfConsumption',
          },
        ],
      },
    },
  },
];
