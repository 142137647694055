/*eslint no-sequences: "off"*/
/*eslint no-empty-pattern: "off"*/

import { createSlice } from "@reduxjs/toolkit";
import { ICalendar } from './interfaces';
import { initState } from "./init.state";

export const CalendarSlice = createSlice({
  name: "calendar",
  initialState: initState,
  reducers: {
    SetCalendarDisplay: (state: ICalendar, action: any) => {
      return {
        ...state,
        calendarDisplay: action.payload,
      };
    },
    SetSelectedDate: (state: ICalendar, action: any) => {
      return {
        ...state,
        selectedDate: action.payload,
      };
    },
    SetSelectedWeek: (state: ICalendar, action: any) => {
      return {
        ...state,
        selectedWeek: {
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
        },
      };
    },
    SetShowCalendar: (state: ICalendar, action: any) => {
      return {
        ...state,
        showCalendar: action.payload,
      };
    },
    SetCustomInteraction: (state: ICalendar, action: any) => {
      return {
        ...state,
        customInteraction: action.payload,
      };
    },
    SetPreviousCalendarDisplay: (state: ICalendar, action: any) => {
      return {
        ...state,
        previousCalendarDisplay: action.payload,
      };
    },
    SetCurrentlySelectedDateType: (state: ICalendar, action: any) => {
      return {
        ...state,
        currentlySelectedDateType: action.payload,
      };
    },
    SetShowCustomInteraction: (state: ICalendar, action: any) => {
      return {
        ...state,
        showCustomInteraction: action.payload,
      };
    },
    SetPrevSelectedDate: (state: ICalendar, action: any) => {
      return {
        ...state,
        prevSelectedDate: action.payload,
      };
    },
    SetButtonSwitch: (state: ICalendar, action: any) => {
      return {
        ...state,
        buttonSwitch: action.payload,
      };
    },
    ResetCalendarStore: (state: ICalendar, action: any) => {
      return initState;
    },

    // new calendar stuff

    setHiveDate: (state: any, action: any) => {
      return {
        ...state,
        hiveCalendar: {
          selectedDate:    action.payload.date,
          selectedTimeSet: action.payload.timeSet,
          
          calendarTimeSet:  null,
          calendarDate: null,  
          
          calendarNavigationActive: false,
          navigationStart: null,
          
        },
      };
    },

    setHiveCalendarTimeSet: (state: any, action: any) => {
      return {
        ...state,
        hiveCalendar: {
          ...state.hiveCalendar,
          calendarTimeSet: action.payload,
        }
      }
    },

    setHiveCalendarNavigationActive: (state: any) => {
      return {
        ...state,
        hiveCalendar: {
          ...state.hiveCalendar,
          calendarNavigationActive: true,
        }
      }
    },

    setHiveCalendarNavigationInactive: (state: any) => {
      return {
        ...state,
        hiveCalendar: {
          ...state.hiveCalendar,
          calendarNavigationActive: false,
          navigationStart: null,
        }
      }
    },

    setHiveCalendarNavigationStart: (state: any, action: any) => {
      return {
        ...state,
        hiveCalendar: {
          ...state.hiveCalendar,
          navigationStart: action.payload,
        }
      }
    },

    setHiveCalendarDate: (state: any, action: any) => {
      return {
        ...state,
        hiveCalendar: {
          ...state.hiveCalendar,
          calendarDate: action.payload,
        }
      }
    },

  }
});

// Action creators are generated for each case reducer function
export const { 
  SetSelectedDate,
  SetSelectedWeek,
  SetShowCalendar,
  SetCustomInteraction,
  SetPreviousCalendarDisplay,
  SetCurrentlySelectedDateType,
  SetShowCustomInteraction,
  SetPrevSelectedDate,
  SetCalendarDisplay,
  SetButtonSwitch,
  ResetCalendarStore,

  setHiveDate,
  setHiveCalendarTimeSet,
  setHiveCalendarDate,
  setHiveCalendarNavigationActive,
  setHiveCalendarNavigationInactive,
  setHiveCalendarNavigationStart,

} = CalendarSlice.actions;

export default CalendarSlice.reducer;
