import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import CustomHeader from './custom-header/custom-header';
import DatePicker, {
  CalendarContainer,
  registerLocale,
} from 'react-datepicker';
import CustomInput from './custom-input/custom-input';
import 'react-datepicker/dist/react-datepicker.css';
import { DateTime } from 'luxon';
import './calendar.module.scss';
import {
  CalendarContext,
  CalendarTypes,
  DataContext,
  SiteViewContext,
} from '@flexo/providers';
import { useOnClickOutside } from '@flexo/hooks';
import { useTranslation } from 'react-i18next';
import { es, it } from 'date-fns/locale';
import { useDispatch, useSelector } from 'react-redux';
import { CALENDAR } from '@flexo/reducers';

registerLocale('es', es);
registerLocale('it', it);

const ENV = (import.meta as any).env;

export function Calendar() {

  const { dataStore } = useContext(DataContext);
  const dispatch = useDispatch();
  const calendar = useSelector((state: any) => state.calendar);
  

  const setDate = (dateType: string) => {

    switch (dateType) {
      case 'day':
        dispatch(CALENDAR.SetSelectedDate(new Date() as any)); 
        break;
      case 'month':
        dispatch(CALENDAR.SetSelectedDate(new Date() as any));
    }
  };

  const MyContainer = ({ className, children }: any) => {
    const { t } = useTranslation();
    const getTagContent = () => {
      switch (calendarDisplay) {
        case 'day':
          return 'widgets.calendar.last.day';
        default:
          return '';
      }
    };

    return (
      <div>
        <CalendarContainer className={className}>
          <div style={{ position: 'relative' }}>{children}</div>
        </CalendarContainer>
      </div>
    );
  };
  
  const {
    calendarDisplay,
    selectedDate,
    selectedWeek,
    showCalendar,
    customInteraction,
    previousCalendarDisplay,
    currentlySelectedDateType,
    showCustomInteraction,
    prevSelectedDate,
  } = calendar;

  const { day, week, month } = showCustomInteraction;
  const { siteViewStore } = useContext(SiteViewContext);

  const calendarContainerRef = useRef(null);
  const calendarRef = useRef(null);
  const [locale, setLocale] = useState(ENV?.VITE_APP_LANGUAGE || 'en');
  const [shownDate, setShownDate] = useState(selectedDate);
  const { i18n } = useTranslation();

  const handleDateChange = (date: any) => {
    dispatch(CALENDAR.SetPreviousCalendarDisplay(calendarDisplay as any));

    switch (customInteraction) {
      case 'day':
        if (
          DateTime.fromJSDate(date).month === 1 &&
          DateTime.fromJSDate(date).day === 1
        ) {
          dispatch(
            CALENDAR.SetShowCustomInteraction({
              ...showCustomInteraction,
              month: true,
            } as any)
          );
        }
        if (month && DateTime.fromJSDate(date).day === 1) {
          dispatch(
            CALENDAR.SetShowCustomInteraction({
              ...showCustomInteraction,
              month: false,
              day: true,
            } as any)
          );
        }
        if (day) {
          dispatch(CALENDAR.SetCustomInteraction(undefined as any));
          dispatch(CALENDAR.SetShowCalendar(true as any));
          dispatch(
            CALENDAR.SetShowCustomInteraction({
              ...showCustomInteraction,
              day: false,
            } as any)
          );
          dispatch(CALENDAR.SetShowCalendar(false as any));
          dispatch(CALENDAR.SetCurrentlySelectedDateType('day' as any));
        }
        break;

      case 'week':
        dispatch(
          CALENDAR.SetShowCustomInteraction({
            day: showCustomInteraction.day,
            week: showCustomInteraction.week,
            month: true,
          } as any)
        );

        if (month) {
          dispatch(
            CALENDAR.SetShowCustomInteraction({
              month: false,
              week: true,
              day: false,
            } as any)
          );
        }
        if (week) {
          dispatch(CALENDAR.SetCustomInteraction(undefined as any));
          dispatch(
            CALENDAR.SetShowCustomInteraction({
              ...showCustomInteraction,
              day: false,
              week: false,
            } as any)
          );
          dispatch(CALENDAR.SetCurrentlySelectedDateType('week' as any));
        }

        break;

      case 'month':
        if (
          DateTime.fromJSDate(date).month === 1 &&
          DateTime.fromJSDate(date).day === 1
        ) {
          dispatch(
            CALENDAR.SetShowCustomInteraction({
              ...showCustomInteraction,
              month: true,
            } as any)
          );
        }
        if (month && DateTime.fromJSDate(date).day === 1) {
          dispatch(
            CALENDAR.SetShowCustomInteraction({
              ...showCustomInteraction,
              month: false,
            } as any)
          );
          dispatch(CALENDAR.SetCustomInteraction(undefined as any));
          dispatch(CALENDAR.SetShowCalendar(false as any));
          dispatch(CALENDAR.SetCurrentlySelectedDateType('month' as any));
        }
        break;
    }
    if (calendarDisplay === 'week' && customInteraction !== 'week') {
      if (currentlySelectedDateType === 'day') {
        dispatch(CALENDAR.SetSelectedDate(date as any));
      }

      const luxonDate = DateTime.fromJSDate(date);
      const startDate = luxonDate.startOf('week').toJSDate();
      const endDate = luxonDate.endOf('week').toJSDate();

      dispatch(
        CALENDAR.SetSelectedWeek({
          startDate: startDate,
          endDate: endDate,
        } as any)
      );

      dispatch(CALENDAR.SetSelectedDate(luxonDate.startOf('week').toJSDate() as any));
      dispatch(CALENDAR.SetCurrentlySelectedDateType('week' as any));
      dispatch(CALENDAR.SetShowCalendar(false as any));
    } else if (week) {
      const luxonDate = DateTime.fromJSDate(date[0]);
      const startDate = luxonDate.startOf('week').toJSDate();
      const endDate = luxonDate.endOf('week').toJSDate();

      dispatch(
        CALENDAR.SetSelectedWeek({
          startDate: startDate,
          endDate: endDate,
        } as any)
      );

      dispatch(
        CALENDAR.SetShowCalendar({
          value: false,
        } as any)
      );
    } else if (
      calendarDisplay === 'week' &&
      customInteraction === 'week' &&
      (month || !day)
    ) {
      const luxonDate = DateTime.fromJSDate(date[0]);

      dispatch(CALENDAR.SetSelectedDate(luxonDate.startOf('month').toJSDate() as any));
    } else {
      dispatch(CALENDAR.SetSelectedDate(date as any));

      if (prevSelectedDate === selectedDate && !customInteraction) {
        dispatch(CALENDAR.SetPrevSelectedDate(date as any));
      }
    }
  };

  const updateLocale = useCallback(async () => {
    const lang = ENV?.VITE_APP_LANGUAGE || 'en';
    setLocale(lang);
  }, []);

  const getShowCalendar = () => {
    if (showCalendar) {
      dispatch(CALENDAR.SetShowCalendar(false as any));
      dispatch(CALENDAR.SetCalendarDisplay(currentlySelectedDateType as any));
    } else return;
  };

  useOnClickOutside(calendarContainerRef, () => getShowCalendar());

  function getCalendarClassNames() {
    return `Calendar CalendarInput__SelectedStates--${
      previousCalendarDisplay !== calendarDisplay && previousCalendarDisplay
    } CalendarInput__CurrentlySelected--${currentlySelectedDateType} CalendarInput__CalendarDisplay--${calendarDisplay} ${
      (customInteraction === 'day' ||
        customInteraction === 'week' ||
        customInteraction === 'month') &&
      !month &&
      !day &&
      `CalendarInput__CustomInteraction--year`
    } ${month && `CalendarInput__CustomInteraction--month`} ${
      day && `CalendarInput__CustomInteraction--day`
    } ${
      customInteraction === 'week' && `CalendarInput__CustomInteraction--week`
    }`;
  }

  useEffect(() => {
    registerLocale('es', es);
    updateLocale();
  }, [updateLocale]);

  const getMinDate = () => {
    let minDate = new Date().toISOString().slice(0, 10);

    try {
      minDate = dataStore?.data?.selectedSite?.site_users?.find(
        (el) => el.changelog_end_date === '2099-12-31'
      )?.changelog_start_date;
    } catch (err) {
      // Error handling
    }

    return new Date(minDate);
  };

  const getMaxDate = () => {
    const currentDate = new Date();
    const currentHour = currentDate.getHours();
    const currentMinutes = currentDate.getMinutes();
    if (currentHour < 10 && currentMinutes < 30) {
      currentDate.setDate(currentDate.getDate() - 2);
    } else {
      currentDate.setDate(currentDate.getDate() - 1);
    }
    return currentDate;
  };

  const getSelectedDate = () => {
    const _minimumDate =
      getMinDate() >= selectedDate ? getMinDate() : selectedDate;
    return _minimumDate;
  };

  return (
    <div className={getCalendarClassNames()} ref={calendarContainerRef}>
      <DatePicker
        ref={calendarRef}
        locale={locale}
        onYearChange={(date: Date) => setShownDate(date)}
        onMonthChange={(date: Date) => setShownDate(date)}
        onDayChange={(date: Date) => setShownDate(date)}
        customInput={<CustomInput minDate={getMinDate()} />}
        calendarClassName={`CalendarInput ${calendarDisplay}`}
        selected={getSelectedDate()}
        onChange={(selectedDate: Date) => {
          handleDateChange(selectedDate as Date);
        }}
        showMonthYearPicker={calendarDisplay === 'month' || month}
        showYearPicker={
          ((customInteraction === 'day' ||
            customInteraction === 'week' ||
            customInteraction === 'month') &&
            !month &&
            !day &&
            !week) ||
          calendarDisplay === 'year'
        }
        allowSameDay={true}
        open={showCalendar}
        className={`CalendarInput__SelectedStates`}
        showFourColumnMonthYearPicker={month || calendarDisplay === 'month'}
        popperClassName={
          ENV.VITE_APP_NAME === 'admin'
            ? 'CalendarInput__Popper__Web'
            : 'CalendarInput__Popper'
        }
        calendarContainer={MyContainer}
        showWeekNumbers={false}
        selectsRange={calendarDisplay === 'week'}
        calendarStartDay={1}
        maxDate={getMaxDate()}
        minDate={getMinDate()}
        renderCustomHeader={({
          changeMonth,
          changeYear,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
          increaseYear,
          decreaseYear,
        }) => (
          <CustomHeader
            shownDate={shownDate}
            setShownDate={setShownDate}
            changeMonth={changeMonth}
            changeYear={changeYear}
            decreaseMonth={decreaseMonth}
            increaseMonth={increaseMonth}
            prevMonthButtonDisabled={prevMonthButtonDisabled}
            nextMonthButtonDisabled={nextMonthButtonDisabled}
            increaseYear={increaseYear}
            decreaseYear={decreaseYear}
            customInteraction={customInteraction}
            selectedDate={getSelectedDate()}
            minDate={getMinDate()}
            getSelectedDate={getSelectedDate()}
          />
        )}
      />
    </div>
  );
}

export default Calendar;
