import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';
import { Autocomplete, ListItem, Popper, TextField } from '@mui/material';
import { IconWrapper } from '@flexo/general';

import { navigateToSite } from '../resolvers/site-overview-resolver';
import { navigateToCommunity } from '../resolvers/community-resolver';
import { navigateToMember } from '../resolvers/member-resolver';
import styles from './breadcrumbs-dropdown.module.scss';
import { KPI } from '@flexo/reducers';

export function CommunitiesDropDown() {
  const { t } = useTranslation();
  const params = useParams();
  const metadata = useSelector((state: any) => state.metadata);
  const location = useLocation();
  const pathSegment = location.pathname.split('/')[1];

  const communities = (metadata?.entities as any)?.community || [];

  const [searchTerm, setSearchTerm] = useState<string>('');

  return (
    <Autocomplete
      disablePortal
      id="dropdown-communities"
      clearOnBlur={false}
      className={`${styles.BreadCrumb}`}
      options={communities || []}
      getOptionLabel={(option: any) => option.communityName}
      noOptionsText={
        <span className={`${styles.Autocomplete__List__Item}`}>
          {t('widgets.search.no_results')}
        </span>
      }
      popupIcon={
        <IconWrapper
          iconName={'chevron-right'}
          className={`${styles.BreadCrumb__Icon}`}
        />
      }
      renderOption={(props, option, { selected }) => (
        <ListItem
          {...props}
          key={uuidv4()}
          className={`${
            option?.communityId === params.id
              ? styles.Autocomplete__List__Item__Active
              : styles.Autocomplete__List__Item
          }`}
          value={option.value}
        >
          <span
            style={{
              paddingLeft: 24,
              cursor: params.id !== option.communityId ? 'pointer' : 'default',
            }}
            onClick={(ev: any) => {
              return params?.id !== option.communityId
                ? navigateToCommunity(`community`, option.communityId)
                : null;
            }}
          >
            {option?.communityName}
          </span>
        </ListItem>
      )}
      PopperComponent={({ children, ...props }) => {
        return (
          <Popper
            {...props}
            placement="bottom-start"
            id="breadcrumbs-popper"
            style={{
              width: 300,
              alignSelf: 'flex-start',
              borderRadius: 8,
              paddingTop: 36,
            }}
          >
            {children}
          </Popper>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          style={{
            width:
              (searchTerm?.length ||
                (metadata?.selectedEntity as any)?.communityName?.length) *
                10 +
                10 || 0,
            minWidth: 100,
            maxWidth: 300,
          }}
          className={styles.BreadCrumb__Input}
          onChange={(ev: any) => setSearchTerm(ev.target.value)}
          placeholder={
            (metadata?.selectedEntity as any)?.communityName ||
            (t('dropdowns.communities') as string)
          }
          label={
            (metadata?.selectedEntity as any)?.communityName ||
            (t('dropdowns.communities') as string)
          }
        ></TextField>
      )}
    />
  );
}

export function MembersDropDown() {
  const { t } = useTranslation();
  const metadata = useSelector((state: any) => state.metadata);
  const params = useParams();
  const [membersList, setMembersList] = useState<any>(
    metadata?.members || null
  );

  const [searchTerm, setSearchTerm] = useState<string>('');

  function getMemberName(datum: any) {
    if (!datum) {
      datum = metadata?.entities?.member?.find(
        (member: any) => member?.memberId === params.id
      );
    }
    return `${datum?.memberFirstName} ${datum?.memberLastName}` || null;
  }

  const memberCommunityID = metadata?.entities?.member?.find(
    (member: any) => member?.memberId === params.id
  )?.communityId;

  const parsedList =
    metadata?.entities?.member?.filter(
      (member: any) => member?.communityId === memberCommunityID
    ) || [];

  return (
    <Autocomplete
      disablePortal
      id="autocomplete-members"
      clearOnBlur={false}
      className={`${styles.BreadCrumb}`}
      options={parsedList || []}
      // groupBy={(option: any) => option.timeSpan}

      sx={{
        width: 'auto',
        '& .MuiAutocomplete-input': {
          width: 'auto',
          minWidth: '200px', // Imposta una larghezza minima se necessario
        },
      }}
      getOptionLabel={(option: any) => getMemberName(option) as string}
      popupIcon={
        <IconWrapper
          iconName={'chevron-right'}
          className={`${styles.BreadCrumb__Icon}`}
        />
      }
      PopperComponent={({ children, ...props }) => {
        return (
          <Popper
            {...props}
            placement="bottom-start"
            id="breadcrumbs-popper"
            style={{
              width: 300,
              alignSelf: 'flex-start',
              borderRadius: 8,
              paddingTop: 36,
            }}
          >
            {children}
          </Popper>
        );
      }}
      renderInput={(params) => {
        const memberName = getMemberName(null);

        return (
          <TextField
            {...params}
            variant="standard"
            onChange={(ev: any) => setSearchTerm(ev.target.value)}
            style={{
              width:
                (searchTerm?.length ||
                  (memberName || (t('widgets.dropdowns.members') as string))
                    ?.length) *
                  10 +
                  10 || 0,
              minWidth: 100,
              maxWidth: 300,
            }}
            className={styles.BreadCrumb__Input}
            label={memberName || (t('widgets.dropdowns.members') as string)}
            placeholder={
              memberName || (t('widgets.dropdowns.members') as string)
            }
          ></TextField>
        );
      }}
      renderOption={(props, option, { selected }) => {
        return (
          <ListItem
            {...props}
            className={`${
              option?.memberId === params.id
                ? styles.Autocomplete__List__Item__Active
                : styles.Autocomplete__List__Item
            }`}
            value={option.value}
          >
            <span
              style={{ paddingLeft: 24, cursor: 'pointer' }}
              onClick={(ev: any) => navigateToMember('member', option.memberId)}
            >
              {getMemberName(option)}
            </span>
          </ListItem>
        );
      }}
    />
  );
}

export function SitesDropDown() {
  const { t } = useTranslation();

  const params = useParams();
  const location = useLocation();
  const pathSegment = location.pathname.split('/')[1];
  const metadata = useSelector((state: any) => state.metadata);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const membersList = [
    ...((metadata?.sortedEntities as any)?.communities || []).reduce(
      (prev: any, next: any) => [...prev, ...(next?.members || [])],
      []
    ),
    ...((metadata as any)?.unassignedMembers || []),
  ]?.filter((member: any) =>
    member?.sites?.some((site: any) => site?.siteId === params.id)
  );

  const sitesList = membersList?.map((member: any) => member?.sites).flat();

  return (
    <Autocomplete
      disablePortal
      id="autocomplete-sites"
      clearOnBlur={false}
      className={`${styles.BreadCrumb}`}
      options={sitesList || []}
      // groupBy={(option: any) => option.timeSpan}
      sx={{
        width: 'auto',
        '& .MuiAutocomplete-input': {
          width: 'auto',
          minWidth: '300px', // Imposta una larghezza minima se necessario
        },
      }}
      disabled={sitesList.length <= 1}
      getOptionLabel={(option: any) => option.pod}
      popupIcon={
        sitesList.length <= 1 ? null : (
          <IconWrapper
            iconName={'chevron-right'}
            className={`${styles.BreadCrumb__Icon}`}
          />
        )
      }
      PopperComponent={({ children, ...props }) => {
        return (
          <Popper
            {...props}
            placement="bottom-start"
            id="breadcrumbs-popper"
            style={{
              width: 300,
              alignSelf: 'flex-start',
              borderRadius: 8,
              paddingTop: 36,
            }}
          >
            {children}
          </Popper>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          onChange={(ev: any) => setSearchTerm(ev.target.value)}
          style={{
            width:
              (searchTerm?.length ||
                (
                  (metadata?.selectedEntity as any)?.name ||
                  (metadata?.selectedEntity as any)?.siteName
                )?.length) *
                10 +
                10 || 0,
            minWidth: 150,
            maxWidth: 300,
          }}
          className={styles.BreadCrumb__Input}
          label={
            (metadata?.selectedEntity as any)?.pod ||
            (t('widgets.dropdown.sites') as string)
          }
          placeholder={
            (metadata?.selectedEntity as any)?.pod ||
            (t('widgets.dropdown.sites') as string)
          }
        ></TextField>
      )}
      renderOption={(props, option, { selected }) => (
        <ListItem
          {...props}
          className={`${
            option?.siteId === params.id
              ? styles.Autocomplete__List__Item__Active
              : styles.Autocomplete__List__Item
          }`}
          value={option.value}
        >
          <span
            style={{ paddingLeft: 24, cursor: 'pointer' }}
            onClick={(ev: any) => navigateToSite(pathSegment, option.siteId)}
          >
            {option?.pod}
          </span>
        </ListItem>
      )}
    />
  );
}

export function SitesDropDownMobile() {
  const { t } = useTranslation();
  const metadata = useSelector((state: any) => state.metadata);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [inputFocused, setInputFocused] = useState<boolean>(false);
  const params = useParams();

  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState<any>(
    (metadata as any)?.entities?.site?.find(
      (entity) =>
        entity.siteId === params.id || metadata?.selectedEntity?.siteId
    ) || null
  );
  const [textWidth, setTextWidth] = useState(0);
  const canvasRef: any = useRef(null);

  useEffect(() => {
    if (canvasRef.current && selectedOption?.siteName) {
      const context = canvasRef.current.getContext('2d');

      // Match input font styles
      context.font = 'bold 16px Arial'; // Adjust as per your input field font
      const fullTextWidth = context.measureText(selectedOption.siteName).width;

      setTextWidth(fullTextWidth + 35);
    }
  }, [selectedOption?.siteName]);

  const inputRef = useRef<HTMLInputElement>(null);

  const mobileSitesList = (metadata as any)?.entities.sites || [];
  const filteredSitesList = mobileSitesList.filter((site: any) =>
    site.siteName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleOptionClick = (option: any) => {
    setSelectedOption(option); // Update the selected option
    setSearchTerm(''); // Update the input value
    navigateToSite('household', option.siteId);
    setInputFocused(false);

    if (inputRef.current) {
      inputRef.current.blur(); // Remove focus from input
    }

    dispatch(KPI.ResetKpiApiStore());
  };

  //empty store when new site is selected

  return (
    <Autocomplete
      disablePortal
      id="autocomplete-sites-mobile"
      clearOnBlur={false}
      className={`${styles.BreadCrumb}`}
      options={filteredSitesList || []}
      sx={{
        width: 'auto',
        '& .MuiAutocomplete-input': {
          minWidth: '300px',
          width: '100%',
        },
      }}
      value={selectedOption} // Bind to selected option
      onChange={(event, newValue) => {
        setSelectedOption(newValue); // Update the selected option
        setSearchTerm(newValue ? newValue.siteName : ''); // Update input value
      }}
      disabled={mobileSitesList.length <= 1}
      getOptionLabel={(option: any) => option.siteName}
      PopperComponent={({ children, ...props }) => {
        return (
          <Popper
            {...props}
            placement="bottom-start"
            id="breadcrumbs-popper"
            style={{
              width: 300,
              alignSelf: 'flex-start',
              borderRadius: 8,
              paddingTop: 36,
              marginTop: 50,
            }}
          >
            {children}
          </Popper>
        );
      }}
      renderInput={(params) => (
        <>
          {' '}
          <TextField
            {...params}
            variant="standard"
            inputRef={inputRef}
            value={selectedOption?.siteName || ''}
            onChange={(ev) => setSearchTerm(ev.target.value)}
            placeholder={
              selectedOption?.siteName ||
              (metadata?.selectedEntity as any)?.siteName
            }
            className="heading1"
            // label={
            //   <div style={{ display: 'flex' }}>
            //     {selectedOption?.siteName ||
            //       (metaDataApiStore?.selectedEntity as any)?.siteName ||
            //       'placeholder'}
            //     {/* {mobileSitesList.length > 1 && (
            //       <IconWrapper
            //         iconName="chevron-down"
            //         className={styles.BreadCrumb__Icon__Mobile}
            //       />
            //     )} */}
            //   </div>
            // }
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              style: {
                display: 'flex',
                alignItems: 'center',
                paddingRight: '20px', // Reserve space for the icon
                fontWeight: 'bold',
                color: '#009E62',
              },
              endAdornment: (
                <div
                  style={{
                    position: 'absolute',
                    right: 0, // Keeps the icon visually aligned after the text
                    pointerEvents: 'none',
                    display: mobileSitesList.length < 1 ? 'none' : 'flex',
                    alignItems: 'center',
                    left: textWidth,
                  }}
                >
                  <IconWrapper
                    iconName="chevron-down"
                    style={{
                      fontSize: '16px',
                      color: 'green',
                      marginTop: '8px',
                    }}
                    className={styles.BreadCrumb__Icon__Mobile}
                  />
                </div>
              ),
            }}
            sx={{
              width: 'fit-content', // Dynamically adjusts to content length
              minWidth: '150px',
              position: 'relative',
            }}
            onFocus={() => setInputFocused(true)}
            onBlur={() => setInputFocused(false)}
            focused={inputFocused}
          />
          <canvas ref={canvasRef} style={{ display: 'none' }} />
        </>
      )}
      renderOption={(props, option) => (
        <ListItem
          {...props}
          className={`heading2 ${
            option?.siteName === (metadata?.selectedEntity as any)?.siteName
              ? styles.Autocomplete__List__Item__Active__Mobile
              : styles.Autocomplete__List__Item__Mobile
          }`}
          style={{
            color:
              option?.siteName === (metadata?.selectedEntity as any)?.siteName
                ? '#009E62'
                : undefined,
            textTransform: 'capitalize',
          }}
          value={option.siteName}
          onClick={() => handleOptionClick(option)} // Call handleOptionClick
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>{option?.siteName}</span>
            <span className="detail" style={{ color: '#687078' }}>
              {option?.pod}
            </span>
          </div>
        </ListItem>
      )}
    />
  );
}

export function CommunityDropdownMobile() {
  const { t } = useTranslation();
  const metadata = useSelector((state: any) => state.metadata);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [inputFocused, setInputFocused] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const params = useParams();

  const dispatch = useDispatch();
  const [textWidth, setTextWidth] = useState(0);
  const canvasRef: any = useRef(null);

  useEffect(() => {
    if (canvasRef.current && selectedOption?.siteName) {
      const context = canvasRef.current.getContext('2d');

      // Match input font styles
      context.font = 'bold 16px Arial'; // Adjust as per your input field font
      const fullTextWidth = context.measureText(selectedOption.siteName).width;

      setTextWidth(fullTextWidth + 60);
    }
  }, [selectedOption?.siteName]);

  const mobileCommunityList = (metadata as any)?.entities.community || [];
  const filteredSitesList = mobileCommunityList.filter((community: any) =>
    community.communityName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  //.filter.count
  const handleOptionClick = (option: any) => {
    setSelectedOption(option); // Update the selected option
    setSearchTerm(''); // Update the input value
    navigateToCommunity('community', option.communityId);
    setInputFocused(false);

    dispatch(KPI.ResetKpiApiStore());

    if (inputRef.current) {
      inputRef.current.blur(); // Remove focus from input
    }
  };

  const getSitesAumount = (communityId) => {
    return (metadata as any)?.entities.sites.filter(
      (site) => site.communityId === communityId
    ).length;
  };

  return (
    <Autocomplete
      disablePortal
      id="autocomplete-sites-mobile"
      clearOnBlur={false}
      className={`${styles.BreadCrumb}`}
      options={filteredSitesList || []}
      sx={{
        width: 'auto',
        '& .MuiAutocomplete-input': {
          minWidth: '300px',
          width: '100%',
        },
      }}
      // open={true}
      value={selectedOption} // Bind to selected option
      onChange={(event, newValue) => {
        setSelectedOption(newValue); // Update the selected option
        setSearchTerm(newValue ? newValue.communityName : ''); // Update input value
      }}
      disabled={mobileCommunityList.length <= 1}
      getOptionLabel={(option: any) => option.communityName}
      PopperComponent={({ children, ...props }) => {
        return (
          <Popper
            {...props}
            placement="bottom-start"
            id="breadcrumbs-popper"
            style={{
              width: 300,
              alignSelf: 'flex-start',
              borderRadius: 8,
              paddingTop: 36,
              marginTop: 50,
            }}
          >
            {children}
          </Popper>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          inputRef={inputRef} // Attach ref to input
          onChange={(ev: any) => setSearchTerm(ev.target.value)}
          style={{
            width:
              (searchTerm?.length ||
                (
                  (metadata?.selectedEntity as any)?.communityName ||
                  (metadata?.selectedEntity as any)?.communityName
                )?.length) *
                10 +
                10 || 0,
            minWidth: 150,
          }}

          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            style: {
              display: 'flex',
              alignItems: 'center',
              paddingRight: '20px', // Reserve space for the icon
              fontWeight: 'bold',
              color: '#009E62',
            },
            endAdornment: (
              <div
                style={{
                  position: 'absolute',
                  right: 0, // Keeps the icon visually aligned after the text
                  pointerEvents: 'none',
                  display: mobileCommunityList.length < 1 ? 'none' : 'flex',
                  alignItems: 'center',
                  left: textWidth,
                }}
              >
                <IconWrapper
                  iconName="chevron-down"
                  style={{
                    fontSize: '16px',
                    color: 'green',
                    marginTop: '8px',
                  }}
                  className={styles.BreadCrumb__Icon__Mobile}
                />
              </div>
            ),
          }}
          sx={{
            width: 'fit-content', // Dynamically adjusts to content length
            minWidth: '150px',
            position: 'relative',
          }}
          className={`heading1 ${styles.BreadCrumb__Input__Mobile}`}
         
          placeholder={
            selectedOption?.communityName ||
            (metadata?.selectedEntity as any)?.communityName ||
            (metadata.entities as any)?.sites?.[0]?.communityName ||
            'sites'
          }
          onFocus={() => setInputFocused(true)}
          focused={inputFocused}
          onBlur={() => setInputFocused(false)}
        ></TextField>
      )}
      renderOption={(props, option) => (
        <ListItem
          {...props}
          className={`heading2 ${
            option?.communityName ===
            (metadata?.selectedEntity as any)?.communityName
              ? styles.Autocomplete__List__Item__Active__Mobile
              : styles.Autocomplete__List__Item__Mobile
          }`}
          value={option.communityName}
          onClick={() => handleOptionClick(option)} // Call handleOptionClick
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>{option?.communityName}</span>
            <span className="detail" style={{ color: '#687078' }}>
              {getSitesAumount(option.communityId)}{' '}
              {/* {t('widgets.dropdown.sites')} */}
              sites
            </span>
          </div>
        </ListItem>
      )}
    />
  );
}
