import { Dispatch } from 'react';

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export interface ICommunityViewProvider {
  notifications: INotification[];
  sites: ISite[];
  communities: any[];
  energyData: IEnergyData[];
  // energyDataOverview: IEnergyDataOverview[];
  energyDataComparision: any;
  energyDataOverview: any;
  selectedSite: any;
  selectedCommunity: any;
  loading: boolean;
  maxConsumption: any;
  maxProduction: any;
  divider: number;
  overviewUnit: string;
  detailUnit: string;
  maxPollution: any,
  maxSufficiency: any,
  dataInTimeSpan: boolean,
  economics: any,
}

export interface IEnergyDataOverview {
  community_id: string;
  community_money_arera: null | number;
  community_money_mise: null | number;
  community_money_rid: null | number;
  community_money_total: null | number;
  community_name: string;
  community_type: string;
  e_community_export_total: null | number;
  e_community_import_total: null | number;
  e_community_shared_total: null | number;
  e_site_consumption: number;
  e_site_export: number;
  e_site_export_battery: number;
  e_site_export_pv: number;
  e_site_export_shared: null | number;
  e_site_import: number;
  e_site_import_battery: number;
  e_site_import_shared: null | number;
  e_site_net_battery: number;
  e_site_self_consumption: number;
  e_site_self_consumption_direct: number;
  e_site_shared_total: null | number;
  site_id: string;
  site_money_arera: null | number;
  site_money_mise: null | number;
  site_money_rid: null | number;
  site_money_self_consumption: null | number;
  site_money_total: null | number;
  site_name: string;
  site_type: string;
  timestamp: string;
}

export interface INotification {
  acknowledged: boolean;
  anomaly_id: string;
  id: string;
  is_pod_activated: boolean;
  level: string;
  message: {
    en: string;
    it: string;
  };
  notified: boolean;
  pod: string;
  pod_id: string;
  rating: number;
  read: boolean;
  status: string;
  time_range: {
    end_ts: string;
    start_ts: string;
  };
  title: {
    en: string;
    it: string;
  };
  type: string;
  user: null | any;
  user_id: null | any;
  values: {
    threshold: number;
  };
}

export interface ISite {
  active: boolean;
  external_id: string;
  flexo_site_id: string;
  pod: string;
  site_address_line: string | null;
  site_communities: {
    active: boolean;
    changelog_end_date: string;
    changelog_start_date: string;
    flexo_community_id: string;
  }[];
  site_latitude: number | null;
  site_longitude: number | null;
  site_name: string;
  site_parameters: any[];
  site_user_parameters: {
    active: boolean;
    flexo_site_type: string;
    flexo_user_type: string;
    parameter_end_date: string;
    parameter_start_date: string;
    properties: any;
    quota: number;
  }[];
}

export interface IEnergyData {
  catalog_env: string | null;
  co2_offset_kgs: number | null;
  co2_offset_shared_kgs: number | null;
  co2_offset_shared_trees: number | null;
  co2_offset_trees: number | null;
  e_site_consumption: number | null;
  e_site_export: number | null;
  e_site_export_battery: number | null;
  e_site_export_pv: number | null;
  e_site_export_shared: number | null;
  e_site_grid_export: number | null;
  e_site_grid_import: number | null;
  e_site_import: number | null;
  e_site_import_battery: number | null;
  e_site_import_shared: number | null;
  e_site_net_battery: number | null;
  e_site_self_consumption: number | null;
  e_site_self_consumption_direct: number | null;
  e_site_shared_total: number | null;
  fk_energy_supplier: string | null;
  fk_organization: string | null;
  fk_site: string | null;
  p_site_max_import: number | null;
  self_consumption_rate: number | null;
  self_sufficiency_rate: number | null;
  soc: number | null;
  timestamp: string | null;
  unix_timestamp: number | null;
}

export type CommunityViewStore = ICommunityViewProvider;

export enum Types {
  SetSrollDisabled = 'SET_SCROLL_DISABLED',
  SetUser = 'SET_USER',
  SetUserLogOut = 'SET_LOGOUT',
  SetNotifications = 'SET_NOTIFICATIONS',
  SetSites = 'SET_SITES',
  SetEnergyData = 'SET_ENERGY_DATA',
  SetEnergyDataOverview = 'SET_ENERGY_DATA_OVERVIEW',
  SetSelectedSite = 'SET_SELECTED_SITE',
  SetLoading = 'SET_LOADING',
  SetMaxConsumption = 'SET_MAX_CONSUMPTION',
  SetMaxProduction = 'SET_MAX_PRODUCTION',
  SetDivider = 'SET_DIVIDER',
  setOverviewUnit = 'SET_OVERVIEW_UNIT',
  setDetailUnit = 'SET_DETAIL_UNIT',
  SetEnergyDataComparision = 'SET_ENERGY_DATA_COMPARISION',
  SetMaxPollution = 'SET_MAX_POLLUTION',
  SetMaxSufficiency = 'SET_MAX_SUFFICIENCY',
  ResetCommunity = 'RESET_COMMUNITY',
  SetSelectedCommunity = 'SET_SELECTED_COMMUNITY',
  SetCommunities = 'SET_COMMUNITIES',
  SetEconomics = 'SET_ECONOMICS',
}

export interface Action {
  type: string;
  payload?: StoragePayload;
}

export type StoragePayload = {
  [Types.SetSrollDisabled]: {
    value: boolean;
  };
  [Types.SetUser]: {
    value: any;
  };
  [Types.SetNotifications]: {
    value: any;
  };
  [Types.SetSites]: { value: ISite[] };
  [Types.SetCommunities]: { value: any[] };
  [Types.SetEnergyData]: { value: IEnergyData[] };
  [Types.SetEnergyDataOverview]: { value: IEnergyData[] };

  [Types.SetSelectedSite]: {
    value: {
      id: string;
      label: string;
    };
  };

  [Types.SetSelectedCommunity]: {
    value: {
      id: string;
      label: string;
    };
  };

  [Types.SetLoading]: { value: boolean };
  [Types.SetMaxConsumption]: { value: any };
  [Types.SetMaxProduction]: { value: any };
  [Types.SetDivider]: { value: number };
  [Types.setOverviewUnit]: { value: string };
  [Types.setDetailUnit]: { value: string };
  [Types.SetEnergyDataComparision]: { value: any };
  [Types.SetMaxPollution]: { value: any };
  [Types.SetMaxSufficiency]: { value: any };
  [Types.ResetCommunity]: { value: any };
  UPDATE_COMMUNITY_VIEW: { value: any };
  [Types.SetEconomics]: { value: any };
  [Types.SetUserLogOut];
  

};

export interface ContextValues {
  communityViewStore: CommunityViewStore;
  setCommunityViewStore: Dispatch<StorageActions>;
}

export interface Props {
  children: React.ReactNode;
}

export type StorageActions =
  ActionMap<StoragePayload>[keyof ActionMap<StoragePayload>];
