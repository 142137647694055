
import styles from './breadcrumbs-dropdown.module.scss';
import { useTranslation } from 'react-i18next';
import { DataContext, ModulesContext } from '@flexo/providers';
import { useContext } from 'react';
import { CommunitiesDropDown, MembersDropDown, SitesDropDown } from './breadcrumbs-dropdown.utils';
import { useLocation } from 'react-router-dom';

/* eslint-disable-next-line */

function getBreadCrumbComponent(props: any) {

  switch (props?.context) {
    case 'community':
      return <CommunitiesDropDown />;
      break;
    case 'member':
      return <MembersDropDown />;
      break;
    case 'site-energy':
    case 'site-economics':
      return <MembersDropDown />;
      break;
    case '':
    case 'communities':
      return <></>;
    case 'site':
      return <SitesDropDown />;
      break;
    default:
      return <></>;
    break;
  }

}

export function BreadCrumbsDropDown() {
  
  const location = useLocation();
  const pathSegment = location.pathname.split('/')[1]; 

  return (
    <div className={styles.SearchWrapper}>
      <div className={styles.Search}>
        {getBreadCrumbComponent({
          context: pathSegment
        })}
         
      </div>
      </div>
  );
}

export default BreadCrumbsDropDown;
