import { createAsyncThunk } from '@reduxjs/toolkit';
import { initState } from './init.state';

export const SetKpiDataCommunityAsync = createAsyncThunk(
  'kpi/SetKpiDataCommunityAsync',
  async function (data: any) {
    return {
      ...data,
    };
  }
);
