
import { useDispatch, useSelector } from "react-redux";

import * as luxon from 'luxon';

import styles from './hive-calendar.module.scss';
import { CALENDAR } from "@flexo/reducers";
import { formatDuoDecade, formatMonth, formatYear, generateYearArrays, getStartDate } from "./helpers";
import { time } from "console";
import { IconWrapper } from "@flexo/general";



export function HiveCalendarInnerInput() {

  const dispatch = useDispatch();
    const calendar = useSelector((state: any) => state.calendar);
    const hiveCalendar = calendar.hiveCalendar;
    const {
      selectedTimeSet,
      calendarTimeSet,
      selectedDate,
      calendarDate,
      navigationStart,
    } = hiveCalendar;
  
  const _selectedDate = calendarDate || selectedDate;
  const timeSet = calendarTimeSet || selectedTimeSet;
  const startDate = getStartDate()

  function getCalendarInputText() {

    const timeSet = calendarTimeSet || selectedTimeSet;
    const _selectedDate = calendarDate || selectedDate;

    switch (timeSet) {
      case 'day':
      case 'week':
      return formatMonth(_selectedDate)

      case 'month':
        return formatYear(_selectedDate)
      
      case 'year':
        // eslint-disable-next-line no-case-declarations
        const years = generateYearArrays(_selectedDate).flat();
        return formatDuoDecade(years);

      default:
        return _selectedDate?.toDateString();
    }
  }

  function activateCalendarNavigation() {
    

    if (timeSet === 'year') {
      return;
    }
    
    dispatch(CALENDAR.setHiveCalendarNavigationActive())
    dispatch(CALENDAR.setHiveCalendarNavigationStart( navigationStart || timeSet ))
    dispatch(CALENDAR.setHiveCalendarTimeSet('year' as any))
    
  }

  function getFurtherOptions() {
  
    let options: any = { days: 1 };
    let movement: any = 'month'
      switch (timeSet) {
        case 'day':
          options = { months: 1 };
        break
        case 'week':
          options = { months: 1 };
        break
        case 'month':
          options = { years: 1 };
        break
        case 'year':
          options = { years: 12 };
          movement = 'year'
        break;
        default:
        break;
      }
    
      const _nextFirstDate = luxon.DateTime.fromJSDate(_selectedDate[0]).plus(options).startOf(movement);
      const _nextSecondDate = luxon.DateTime.fromJSDate(_selectedDate[1]).plus(options).startOf(movement);
      const _today = luxon.DateTime.fromJSDate(new Date()).startOf(movement);
  
      return {options, _nextFirstDate, _nextSecondDate, _today}
    }
    
    function canGoFurther() {
      
      const {
        _nextFirstDate,
        _nextSecondDate,
        _today,
      } = getFurtherOptions();

      if (timeSet === 'month') {
        return  _nextSecondDate.year <= _today.year;
      }


      if (_nextFirstDate > _today) {
        return false;
      } else {
        return true;
        
      }
  
    }  
  
    function getPreviuosOptions() {
  
      let options: any = { days: 1 };
      let movement: any = 'month'
      switch (timeSet) {
        case 'day':
          options = { months: 1 };
        break
        case 'week':
          options = { months: 1 };
        break
        case 'month':
          options = { years: 1 };
        break
        case 'year':
          options = { years: 12 };
          movement = 'year'
        break;
        default:
        break;
      }
  
      const _previousFirstDate = luxon.DateTime.fromJSDate(_selectedDate[0]).minus(options).startOf(movement);
      const _previousSecondDate = luxon.DateTime.fromJSDate(_selectedDate[1]).minus(options).startOf(movement);
      const _today = luxon.DateTime.fromJSDate(new Date()).startOf('day');
  
      return {options, _previousFirstDate, _previousSecondDate, _today}
    }
  
  function canGoBackward() {
    if (!startDate) { return true }

    const {
      _previousFirstDate,
    } = getPreviuosOptions();

    const luxonStartDate = luxon.DateTime.fromJSDate(new Date( startDate )).startOf('day');

    if (timeSet === 'month') {
      return  _previousFirstDate.year >= luxonStartDate.year;
    }


    if (_previousFirstDate < luxonStartDate) {
      return false;
    } else {
      return true;
      
    }

  }
  
    function goToNextDate() {
      const {
        _nextFirstDate,
        _nextSecondDate
      } = getFurtherOptions();

      dispatch(CALENDAR.setHiveCalendarDate( [_nextFirstDate.toJSDate(), _nextSecondDate.toJSDate()]as any) )
    }
  
    function goToPrevDate() {
      const {
        _previousFirstDate,
        _previousSecondDate
      } = getPreviuosOptions();
      dispatch(CALENDAR.setHiveCalendarDate([_previousFirstDate.toJSDate(), _previousSecondDate.toJSDate()] as any))
      
    }

  return (
    <div className={styles.HiveCalendar__Calendar__Inner__Input}>

      <div
        className={`
          ${styles.HiveCalendar__Calendar__Inner__Input__Arrow}
          ${ canGoBackward() === false ? styles.HiveCalendar__Calendar__Inner__Input__Arrow__Disabled : '' }  

          `}
        onClick={ () => canGoBackward() ? goToPrevDate() : null }
      >
        <IconWrapper iconName="chevron-left" />
        </div>
      <div
        className={`${styles.HiveCalendar__Calendar__Inner__Input__Content} ${timeSet === 'year' ? styles.HiveCalendar__Calendar__Inner__Input__Content__Disabled: ''}`}
        onClick={() => activateCalendarNavigation()}
      >
        { getCalendarInputText() }
              
            </div>

      <div
        className={`
          ${styles.HiveCalendar__Calendar__Inner__Input__Arrow}  
          ${ canGoFurther() === false ? styles.HiveCalendar__Calendar__Inner__Input__Arrow__Disabled : '' }  
        `}
        onClick={ () => canGoFurther() ? goToNextDate() : null }
      >
        <IconWrapper iconName="chevron-right" />
      </div>

        </div>
  )
}