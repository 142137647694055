import { configureStore, createDynamicMiddleware } from '@reduxjs/toolkit';

import {Logger} from './lib/logger';

import { default as MetaDataReducer } from './lib/metadata-reducer/metatada.reducer';
import {  default as KpiReducer } from './lib/kpi-reducer/kpi.reducer';
import {  default as ModulesReducer } from './lib/modules-reducer/modules.reducer';
import {  default as CalendarReducer } from './lib/calendar-reducer/calendar.reducer';
import {  default as UserReducer } from './lib/user-reducer/user.reducer';
import {  default as RightDrawerReducer } from './lib/right-drawer-reducer/right-drawer.reducer';
import {  default as LeftDrawerReducer } from './lib/left-drawer-reducer/left-drawer.reducer';

const dynamicMiddleware = createDynamicMiddleware()

export const store = configureStore({
  // Pass in the root reducer setup as the `reducer` argument
  reducer: {
    // Declare that `state.counter` will be updated by the `counterReducer` function
    metadata: MetaDataReducer,
    kpi: KpiReducer,
    modules: ModulesReducer,
    calendar: CalendarReducer,
    user: UserReducer,
    rightDrawer: RightDrawerReducer,
    leftDrawer: LeftDrawerReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}).concat(dynamicMiddleware.middleware)
})

dynamicMiddleware.addMiddleware(Logger)

// Infer the type of `store`
export type AppStore = typeof store
// Infer the `AppDispatch` type from the store itself
export type AppDispatch = typeof store.dispatch
// Same for the `RootState` type
export type RootState = ReturnType<typeof store.getState>

export * as METADATA from './lib/metadata-reducer/metatada.reducer';
export * as KPI from './lib/kpi-reducer/kpi.reducer';
export * as MODULES from './lib/modules-reducer/modules.reducer';
export * as CALENDAR from './lib/calendar-reducer/calendar.reducer';
export * as USER from './lib/user-reducer/user.reducer';
export * as RIGHT_DRAWER from './lib/right-drawer-reducer/right-drawer.reducer';
export * as LEFT_DRAWER from './lib/left-drawer-reducer/left-drawer.reducer';
export * from './utils'


export const MetaDataStore = () => store.getState().metadata;
export const KpiStore = () => store.getState().kpi;
export const ModulesStore = () => store.getState().modules;
export const CalendarStore = () => store.getState().calendar;
export const UserStore = () => store.getState().user;
export const RightDrawerStore = () => store.getState().rightDrawer;
export const LeftDrawerStore = () => store.getState().leftDrawer;

