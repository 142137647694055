import { DateTime } from 'luxon';

const ENV = (import.meta as any).env;

export const formatDate = (date: string, calendarDisplay: string) => {
  let formattedDate = DateTime.fromISO(date).toFormat('hh:mm');

  switch (calendarDisplay) {
    case 'day':
    case '':
      formattedDate = DateTime.fromISO(date).toFormat('HH:mm');
      break;
    case 'week':
    case 'month':
      formattedDate = DateTime.fromISO(date).toFormat('d/M');
      break;
    // formattedDate = moment(new Date(date)).format("MM");
    // break;
    case 'year':
      formattedDate = DateTime.fromISO(date)
        .setLocale(ENV.VITE_APP_LOCALE?.split('-')[0] || 'en')
        .toFormat('MMM');
      break;
    case 'decade':
      formattedDate = DateTime.fromISO(date).toFormat('MM-YYYY');
      break;
  }

  return (formattedDate.toString().startsWith('0') && formattedDate.length) ===
    2
    ? `${formattedDate[1]}:00`
    : formattedDate;
};

export const formatTimeStamp = (date: string, calendarDisplay: string) => {
  const formattedDate = DateTime.fromISO(date);

  let formattedTimeStamp = formattedDate.toFormat('HH:mm');

  switch (calendarDisplay) {
    case 'day':
      formattedTimeStamp = formattedDate.toFormat('HH:mm');
      break;
    case 'week':
    case 'month':
    case 'year':
      formattedTimeStamp = formattedDate.toFormat('DD-MM');
      break;
    // formattedDate = moment(new Date(date)).format("MM");
    // break;
    case 'decade':
      formattedTimeStamp = formattedDate.toFormat('MM-YYYY');
      break;
  }

  return formattedTimeStamp;
};
