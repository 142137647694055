import { IMetaData } from "./interfaces";

export const initState: IMetaData = {
  selectedEntity: null,
  selectedEntityId: null,
  entityType: null,
  // selectedEntity: {
  //   id: '',
  //   entitytype: 'site',
  //   entity: {
  //     status: '',
  //     siteExternalId: '',
  //     pod: '',
  //     siteAddress: '',
  //     flexoCommunityId: '',
  //     joinDate: '',
  //     leaveDate: '',
  //     siteLatitude: 0,
  //     siteLongitude: 0,
  //     siteName: '',
  //     siteType: [
  //       {
  //         status: '',
  //         flexoSiteType: 'producer',
  //         startDate: '',
  //         endDate: '',
  //       },
  //     ],
  //   },
  // },
  entities: [],
  sortedEntities: null,
  communities: [],
  members: [],
  selectedEntityName: null,
  filteredCommunities: null,
  filteredMembers: null,
  filteredSites: null,
  
};